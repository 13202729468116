export const BASE_URL = window.location.protocol + "//" + window.location.host + "/";
// Api endpoints
export const SITE_STATE_ENDPOINT = `api/v1/site_state/`;
// account system and authentication
export const REFRESH_TOKEN_ENDPOINT = `api/auth/token/`;
export const LOG_OUT = `api/auth/logout/`;
export const USER_GET_PATCH_ENDPOINT = `api/v1/account/me/`;
export const USER_APP_SETTINGS_ENDPOINT = `api/v1/account/settings/app/`;
// account stats
export const VIOLATION_STATS_ENDPOINT = `api/v1/account/stats/violations/`;

// objects
export const TYPE_OF_VIOLATIONS_ENDPOINT = `api/v1/types_of_violation/`;
export const VIOLATIONS_ENDPOINT_FOR_MAP = `api/v1/violations/`;
export const CARPARKS_ENDPOINT_FOR_MAP = `api/v1/car_parks/`;
export const VIOLATIONS_ENDPOINT_FOR_SITEMAP = `api/v1/violations_for_sitemap/`;
export const VIOLATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT = (uuid: string) => `api/v1/violations/${uuid}/`;
export const APPLICANTS_ENDPOINT = `api/v1/applicants/`;

export const GEO_PHOTOS_ENDPOINT = `api/v1/geo_photos/`;
export const GEO_PHOTO_RETRIEVE_DELETE_ENDPOINT = (uuid: string) => `api/v1/geo_photos/${uuid}/`;

export const CAR_PLATES_ENDPOINT = `api/v1/car_plates/`;
export const CAR_PLATES_RETRIEVE_DELETE_ENDPOINT = (uuid: string) => `api/v1/car_plates/${uuid}/`;

export const APPLICATIONS_ENDPOINT = `api/v1/applications/`;
export const APPLICATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT = (uuid: string) => `api/v1/applications/${uuid}/`;

export const VIOLATIONS_MODERATION_ENDPOINT = `api/v1/moderation/violations/`;
export const VIOLATIONS_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT = (uuid: string) => `api/v1/moderation/violations/${uuid}/`;

export const GEO_PHOTO_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT = (uuid: string) => `api/v1/moderation/geo_photos/${uuid}/`;
export const CAR_PLATES_MODERATION_RETRIEVE_DELETE_ENDPOINT = (uuid: string) => `api/v1/moderation/car_plates/${uuid}/`;

export const MAX_HTTP_ERRORS_COUNT = 5;
export const IDEMPOTENCY_KEY_HEADER = "Idempotency-Key";
