<template>
    <div class='camera-wrapper'>
      <MDBDropdown v-model="cameraDropdown" :v-if='availableCameras.length > 1' class="camera-config-dropdown" dropstart>
        <MDBDropdownToggle @click="cameraDropdown = !cameraDropdown" outline="light" size="lg" class="p-2 rounded-pill pe-5" />
        <MDBDropdownMenu class="me-1 p-1">
          <div class="p-0 pt-1  text-secondary text-center">
            <strong>
              {{ $t("hunting.selectCamera") }}
            </strong>
          </div>
          <MDBDropdownItem divider />
          <MDBDropdownItem v-for='cam in availableCameras' @click='changeCamera(cam.deviceId)' href="#" :active="cam.deviceId == currentCamera">
            {{ cam.label }}
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
      <video ref="video" muted autoplay playsinline class='video' :style='videoVisible? {display: "block"} : {display: "none"}'/>
      <img :src='capturedImage' class='captured-image' :style='imageVisible? {display: "block"} : {display: "none"}' alt='capturedImage'/>
      <div class="bottom-buttons mb-3 d-flex justify-content-around">
        <MDBBtn :color="huntingStore.photoCheckingMode? 'danger' : 'secondary'" floating size="lg" @click='cancelCurrentGeoPhotoUpload' :disabled='!huntingStore.photoCheckingMode || geoPhotoUploading'>
          <MDBIcon iconStyle="fas" size="lg" icon="redo"></MDBIcon>
        </MDBBtn>
        <MDBBtn :color="huntingStore.photoCheckingMode? 'secondary' : 'primary'" floating @click='cameraShot' class='shoot-btn' :disabled='huntingStore.photoCheckingMode || !cameraLoaded'>
          <MDBIcon iconStyle="fas" size="2x" icon="camera"></MDBIcon>
        </MDBBtn>
        <MDBBtn :color="huntingStore.photoCheckingMode? 'success' : 'secondary'" floating size="lg" @click='confirm' :disabled='!huntingStore.photoCheckingMode || geoPhotoUploading || geoPhotoRejectedBecauseAlreadyExists || (huntingStore.currentPhoto?.isRecognized && huntingStore.carPlatesOnCurrentPhoto?.length == 0)'>
          <MDBIcon iconStyle="fas" size="lg" icon="check-double"></MDBIcon>
        </MDBBtn>
      </div>
    </div>
</template>
<script lang="ts">
import {defineComponent, onMounted, ref, watch, watchEffect} from 'vue'
import { useHuntingStore } from '@/store/hunting';
import { useDevicesList, useUserMedia } from '@vueuse/core'
import { useCookies } from 'vue3-cookies';
import { useIfaceStore } from '@/store/iface';
import { MDBBtn, MDBIcon, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdb-vue-ui-kit';
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status';
import { useAppStore } from '@/store/app';
import i18n from "@/i18n/vue-i18n";
import {onBeforeRouteLeave} from "vue-router";

const { cookies } = useCookies();


export default defineComponent({
  name: "CameraWrapperContainer",
  components: { MDBIcon, MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle },
  computed: {
    geoPhotoRejectedBecauseAlreadyExists() {
      return this.huntingStore.currentPhoto?.moderationStatus === MODERATION_STATUS.REJECTED_REASON_VIOLATION_ALREADY_EXISTS
    },
    geoPhotoUploading() {
      return this.huntingStore.currentPhoto && !this.huntingStore.currentPhoto?.uploaded
    },
  },
  methods: {
    cameraShot() {
      if(this.video) {
        this.huntingStore.setPhotoCheckingMode(true);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        this.ifaceStore.setHunterSpinnerVisible(true);
        const width = this.video.videoWidth;
        const height = this.video.videoHeight;
        this.video.width = width;
        this.video.height = height;
        canvas.width = width;
        canvas.height = height;
        context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
        this.capturedImage = canvas.toDataURL('image/jpeg');
        this.videoVisible = false;
        this.imageVisible = true;
        this.huntingStore.addGeoPhoto(this.capturedImage);
        this.appStore.addSuccessMessage(this.$t("hunting.photoWasSentCheckItAndContinue"), true, 5000, true);
        this.ifaceStore.setHunterSpinnerVisible(false);
      }
    },
    cancelCurrentGeoPhotoUpload() {
      this.huntingStore.cancelCurrentGeoPhotoUpload();
      this.appStore.addWarningMessage(this.$t("hunting.photoHasBeenDeleted"), true, 4000, true);
    },
    confirm() {
      this.huntingStore.setPhotoCheckingMode(false);
      this.imageVisible = false;
      this.capturedImage = "";
      this.videoVisible = true;
      this.huntingStore.confirmCurrentGeoPhotoUpload();
      this.appStore.addSuccessMessage(this.$t("hunting.photoHasBeenAccepted"), true, 5000, true);
    },
  },
  setup() {
    const appStore = useAppStore();
    const huntingStore = useHuntingStore();
    const ifaceStore = useIfaceStore();

    const capturedImage = ref("");
    const cameraLoaded = ref(false);
    let stopStreamFunc: (() => void) | null = null;
    const videoVisible = ref(true);
    const imageVisible = ref(false);

    const video = ref<HTMLVideoElement>();

    const currentCamera = ref<string>("");
    if(cookies.isKey("camDevID5")) {
      currentCamera.value = cookies.get("camDevID5")
    }
    const availableCameras = ref<MediaDeviceInfo[]>([]);

    const cameraConstraints = new Map<string,  MediaTrackConstraints>();

    watch(() => huntingStore.currentPhoto, (newValue, oldValue) => {
      if (!newValue && oldValue) {
        videoVisible.value = true;
        imageVisible.value = false;
        capturedImage.value = "";
        huntingStore.setPhotoCheckingMode(false);
      }
    });

    function getAllAvailableCameras() {
      const { videoInputs: cameras } = useDevicesList({
        constraints: { video: true },
        requestPermissions: true,
        onUpdated() {
          if(cameras.value.length < 1) {
            ifaceStore.setHunterSpinnerVisible(false);
            appStore.addErrorMessage(i18n.global.t("hunting.cameraNotFound"), true, 10000, true);
            return;
          }
          let filtered = []
          for (let i = 0; i < cameras.value.length; i++) {
            const label = cameras.value[i].label.toLowerCase()
            if (label.includes("back") || label.includes("rear") || label.includes("зад") || label.includes("env")) {
              filtered.unshift(cameras.value[i])
            } else {
              filtered.push(cameras.value[i])
            }
          }
          availableCameras.value = filtered
          if(!currentCamera.value) {
            currentCamera.value = filtered[0].deviceId
          }
          if(stopStreamFunc != null) {
            stopStreamFunc()
          }
          const supports = navigator.mediaDevices.getSupportedConstraints();
          console.log("Supported constraints: ", supports)
          for (let i = 0; i < filtered.length; i++) {
            const capabilities = filtered[i].getCapabilities()
            const minWidth = capabilities?.width?.max > 1920 ? 1920 : capabilities?.width?.max > 1066 ? 1066 : 960;
            const minHeight = capabilities?.height?.max > 1080 ? 1080 : capabilities?.height?.max > 600 ? 600 : 540;
            let constraints = {} as MediaTrackConstraints
            if(capabilities?.facingMode?.includes("environment") && supports.facingMode) {
              constraints.facingMode = { exact: 'environment' }
            }
            if(supports.deviceId) {
              constraints.deviceId = { exact: filtered[i].deviceId }
            }
            if(supports.width) {
              if (supports['resizeMode'] && capabilities.resizeMode?.includes('none')) {
                constraints.width = { exact: minWidth }
              } else {
                constraints.width = { min: minWidth, ideal: capabilities?.width?.max }
              }
            }
            if(supports.height) {
              if (supports['resizeMode'] && capabilities.resizeMode?.includes('none')) {
                constraints.height = { exact: minHeight }
              } else {
                constraints.height = { min: minHeight, ideal: capabilities?.height?.max > capabilities?.width?.max / 16 * 9 ? capabilities?.width?.max / 16 * 9 : capabilities?.height?.max }
              }
            }
            if(supports.aspectRatio && capabilities?.aspectRatio?.max > 16 / 9 && capabilities?.aspectRatio?.min < 16 / 9) {
              constraints.aspectRatio = { ideal: 16 / 9 }
            }
            cameraConstraints.set(filtered[i].deviceId, constraints)

            console.log("Camera capabilities: ", filtered[i].deviceId, capabilities)
            console.log("Camera constraints: ", filtered[i].deviceId, constraints)
          }
          setupStream();
        },
      })
    }

    function setupStream() {
        try {
          let currentConstraints = cameraConstraints.get(currentCamera.value)
          if(currentConstraints===undefined) {
            console.error("Camera constraints not found")
            ifaceStore.setHunterSpinnerVisible(false);
            appStore.addErrorMessage(i18n.global.t("hunting.cameraNotFound"), true, 10000, true);
            return;
          }
          const { stream, enabled } = useUserMedia({
            constraints: {video: currentConstraints},
          });
          stopStreamFunc = () => {
            enabled.value = false;
            cameraLoaded.value = false;
            stopStreamFunc = null;
          }
          watchEffect(() => {
            if (video?.value && stream?.value) {
              video.value.srcObject = stream.value!
              videoVisible.value = true;
            }
          });
          ifaceStore.setHunterSpinnerVisible(false);
          cameraLoaded.value = true;
          cookies.set("camDevID5", currentCamera.value)
          enabled.value = true;
        } catch (error) {
          ifaceStore.setHunterSpinnerVisible(false);
          appStore.addErrorMessage(i18n.global.t("hunting.cameraNotFound"), true, 10000, true);
          console.error('An error occurred in the setupStream:', error);
        }
    }

    onMounted(() => {
      ifaceStore.setHunterSpinnerVisible(true);
      getAllAvailableCameras();
    });

    onBeforeRouteLeave(() => {
      if(stopStreamFunc != null) {
        stopStreamFunc()
      }
    });

    const cameraDropdown = ref(false);

    function changeCamera(deviceId: string) {
      currentCamera.value = deviceId;
      ifaceStore.setHunterSpinnerVisible(true);
      if(stopStreamFunc != null) {
        stopStreamFunc();
      }
      setTimeout(() => {
        setupStream();
      }, 1500);
    }

    return {
      huntingStore,
      ifaceStore,
      appStore,
      capturedImage,
      cameraLoaded,
      videoVisible,
      imageVisible,
      video,
      availableCameras,
      cameraDropdown,
      changeCamera,
      currentCamera,
    }
  },
});
</script>
<style scoped lang="scss">
.camera-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-image: url("@/ui/assets/images/hunting-wrapper-bgr.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.captured-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.bottom-buttons {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: row;
  bottom: 0;
  width: 100%;
  .shoot-btn {
    width: 55px;
    height: 55px;
    margin-bottom: -10px;
    bottom: 10px;
  }
}

.camera-config-dropdown {
  position: fixed;
  z-index: 100;
  top: 50%;
  right: -40px;
}

</style>
