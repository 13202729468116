<script setup lang="ts">
import Header from "@/ui/layouts/Header.vue";
import SideNavContainer from "@/ui/containers/SideNavContainer.vue";
import ToastContainer from "@/ui/containers/ToastContainer.vue";
import InformationCardModalContainer from '@/ui/containers/InformationCardModalContainer.vue'
import ViolationTypeSelectorModal from '@/ui/containers/ViolationTypeSelectorModalContainer.vue'
import { useRouter } from 'vue-router';
import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth'
import { useViolationTypesStore } from '@/store/violation-types'
import { watch } from 'vue'
import {
  HOME_ROUTE_NAME,
  HUNT_ROUTE_NAME,
  HUNTING_ROUTE_NAME,
  MODERATOR_ROUTE_NAME,
  SIGN_IN_ROUTE_NAME
} from '@/helpers/constants/routes'

const router = useRouter();
const appStore = useAppStore();
const authStore = useAuthStore();

appStore.waitForSiteLoaded().then(() => {
  appStore.updateSiteState();
  authStore.initStore().then(() => {
    const violationTypesStore = useViolationTypesStore();
    violationTypesStore.initStore();
  });
});

const authOnlyTabNames = [HUNT_ROUTE_NAME, HUNTING_ROUTE_NAME, MODERATOR_ROUTE_NAME]
watch(() => authStore.isInitialized, () => {
  const routeName = router.currentRoute.value.name;
  if (authStore.isAuthenticated && (routeName === SIGN_IN_ROUTE_NAME)) {
    if (authStore.user?.isBanned) {
      router.push({ name: HOME_ROUTE_NAME })
    } else {
      router.push({ name: HUNTING_ROUTE_NAME })
    }
  } else if (!authStore.isAuthenticated && routeName && authOnlyTabNames.includes(String(routeName))) {
    router.push({ name: SIGN_IN_ROUTE_NAME })
  }
});

</script>
<template>
  <Header />
  <div class="main-wrapper">
    <router-view />
  </div>
  <ToastContainer />
  <InformationCardModalContainer />
  <ViolationTypeSelectorModal />
  <SideNavContainer />
</template>
