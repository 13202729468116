<template>
  <MDBStepperStep :dynamic='true'>
    <MDBStepperHead>
      <template #icon>
        <MDBIcon fas icon="digital-tachograph" />
      </template>
      {{ $t('moderatorView.carPlate') }}
    </MDBStepperHead>
    <MDBStepperContent>
      <div class='vstack gap-1 mt-0 pt-0'>
        <MDBCard class='console-card car-plate-magnifier-card'>
          <MDBCardHeader class='m-0 p-0 rounded-top-2 border-0'>
            <VueMagnifier class='rounded-top-5 object-cover console-card-img-top' :src="moderationStore.currentViolationForModeration?.carPlate.photo.srcPhoto" zoomFactor='1.5' mgWidth='250' mgHeight='250'/>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBCardText>
              <MDBInput
                :label="$t('moderatorView.checkAndConfirmCarPlate')"
                v-model="plateNumber"
              />
            </MDBCardText>
            <div class='hstack gap-2'>
              <MDBBtn color="secondary" size="sm" :disabled='plateNumber==moderationStore.currentViolationForModeration?.carPlate.plateNumber' @click='plateNumber=moderationStore.currentViolationForModeration?.carPlate.plateNumber'>
                <MDBIcon iconStyle="fas" icon="redo"></MDBIcon>
                {{ $t('moderatorView.reset') }}
              </MDBBtn>
              <MDBBtn color="primary" size="sm" class='ms-auto' :disabled='plateNumber==moderationStore.currentViolationForModeration?.carPlate.plateNumber' @click='saveCarPlateChanges'>
                <MDBIcon iconStyle="fas" icon="save"></MDBIcon>
                {{ $t('moderatorView.save') }}
              </MDBBtn>
            </div>
          </MDBCardBody>
          <MDBCardFooter class='p-2'>
            <div class='hstack gap-2'>
              <MDBBtn color="danger" @click="ifaceStore.showRejectReasonSelectorModal" class='me-auto'>
                <i class="fas fa-ban me-2" />
                {{ $t('moderatorView.reject') }}
              </MDBBtn>
              <!--
                Warning about the use of a non-free component. Attention! The MDBPopconfirm component is provided
                under the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as
                part of open-source software. To run the "frontend" app of the OinkOinkCar system, you must either
                purchase the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing)
                or make changes to this source code to eliminate the use of non-free components.
              -->
              <MDBPopconfirm
                class="btn-success ms-auto"
                position="top left"
                :message="$t('moderatorView.confirmCarPlateConfirm')"
                :cancelText="$t('moderatorView.cancel')"
                :confirmText="$t('moderatorView.confirm')"
                :disabled='plateNumber!=moderationStore.currentViolationForModeration?.carPlate.plateNumber'
                @confirm='confirmCarPlate'
              >
                <i class="fas fa-check-square me-2"></i>
                {{ $t('moderatorView.confirm') }}
              </MDBPopconfirm>
            </div>
          </MDBCardFooter>
        </MDBCard>
      </div>
    </MDBStepperContent>
  </MDBStepperStep>
</template>
<script  lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import {
  MDBCard,
  MDBBtn,
  MDBCardBody,
  MDBStepperContent,
  MDBIcon,
  MDBStepperHead,
  MDBCardFooter,
  MDBStepperStep,
  MDBCardHeader,
  MDBCardText,
  MDBPopconfirm,
  MDBInput,
} from 'mdb-vue-ui-kit';
import { useModerationStore } from '@/store/moderation';
import { useIfaceStore } from '@/store/iface';
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status';
import i18n from '@/i18n/vue-i18n';
import { MODERATION_STEP } from '@/helpers/enums/moderation-step';
import { useAppStore } from '@/store/app'
import VueMagnifier from '@websitebeaver/vue-magnifier';
import '@websitebeaver/vue-magnifier/styles.css';

export default  defineComponent({
  name: "ModerationStepPlateNumberCheckContainer",
  components: {
    MDBPopconfirm,
    VueMagnifier,
    MDBCardText,
    MDBCardHeader,
    MDBStepperStep,
    MDBCardFooter,
    MDBStepperHead,
    MDBIcon,
    MDBStepperContent,
    MDBCardBody,
    MDBCard,
    MDBBtn,
    MDBInput,
  },
  methods: {
    saveCarPlateChanges() {
      this.moderationStore.patchCarPlateEditPlateNumberDuringModeration(this.plateNumber).then(() => {
        this.appStore.addSuccessMessage(this.$t('moderatorView.plateNumberChanged'), true, 2000);
      });
    },
    confirmCarPlate() {
      this.moderationStore.patchCarPlateConfirmPlateNumberDuringModeration().then(() => {
        this.appStore.addSuccessMessage(this.$t('moderatorView.plateNumberConfirmed'), true, 2000);
      });
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const appStore = useAppStore();
    const moderationStore = useModerationStore();
    const plateNumber = ref("");


    function rejectionCallback(reason: MODERATION_STATUS) {
      moderationStore.rejectCarPlate(reason);
      appStore.addSuccessMessage(i18n.global.t('moderatorView.plateNumberRejected'), true, 2000);
    }

    function configureStep() {
      if (moderationStore.currentModerationStep === MODERATION_STEP.PLATE_NUMBER_CHECK) {
        ifaceStore.setupRejectReasonSelectorModal(
          [
            MODERATION_STATUS.REJECTED_REASON_BLURRED,
            MODERATION_STATUS.REJECTED_INTERNATIONAL_CAR_PLATE,
            MODERATION_STATUS.REJECTED_REASON_CAR_PLATE_NOT_READABLE,
            MODERATION_STATUS.REJECTED_REASON_FLOOD,
            MODERATION_STATUS.REJECTED_REASON_ADULT,
            MODERATION_STATUS.REJECTED_REASON_OTHER,
          ],
          i18n.global.t('moderatorView.rejectCarPlateModalTitle'),
          i18n.global.t('moderatorView.rejectCarPlateModalText'),
          rejectionCallback,
        );
      }
    }

    watch(() => moderationStore.currentModerationStep, (value) => {
      if (value === MODERATION_STEP.PLATE_NUMBER_CHECK) {
        configureStep();
      }
    });
    onMounted(() => {
      configureStep();
    });

    watch(() => moderationStore.currentViolationForModeration, (value) => {
      if (value) {
        plateNumber.value = value.carPlate.plateNumber;
      } else {
        plateNumber.value = "";
      }
    });

    return {
      ifaceStore,
      moderationStore,
      plateNumber,
      appStore,
    }
  }
});
</script>
<style scoped lang="scss">

.car-plate-magnifier-card {
  width: 21rem;
}

</style>