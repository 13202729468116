<template>
  <div class='maintenance-wrapper align-items-center justify-content-center text-center mt-3 mb-3 ms-auto me-auto'>
    <MDBCard class='ms-3 me-3 mt-3'>
      <MDBCardImg
        src="/oink-oink-man.png"
        top
        :alt="$t('application.oinkOinkMan')"
      />
      <MDBCardBody>
        <MDBCardTitle>{{$t('application.oops')}}</MDBCardTitle>
        <MDBCardText>
          {{$t('application.maintenanceMessage')}}
        </MDBCardText>
        <MDBCardText v-if='message'>
          <p class="note note-info">
            {{ message }}
          </p>
        </MDBCardText>
      </MDBCardBody>
      <MDBCardFooter class='hstack p-2'>
        <MDBBtn class='me-auto ms-0 ps-3 pe-3' color="secondary" @click='goHome' v-if='$route.name !== "map"'>
          <MDBIcon iconStyle="fas" icon="home" class="me-2" />
          {{ $t("application.goHome") }}
        </MDBBtn>
        <MDBBtn class='ms-auto me-0 ps-3 pe-3' color="primary" tag='a' :href='URL_OF_TELEGRAM' target='_blank'>
          <MDBIcon iconStyle="fab" icon="telegram-plane" class="me-2" />
          {{ $t("application.telegram") }}
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImg, MDBBtn, MDBIcon, MDBCardFooter } from 'mdb-vue-ui-kit';
import { HOME_ROUTE_NAME, URL_OF_TELEGRAM } from '@/helpers/constants/routes'

export default defineComponent({
  name: "MaintenanceComponent",
  data() {
    return {
      URL_OF_TELEGRAM,
    };
  },
  methods: {
    goHome() {
      return this.$router.push({ name: HOME_ROUTE_NAME });
    },
  },
  components: { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImg, MDBBtn, MDBIcon, MDBCardFooter },
  props: {
    message: {
      type: String,
      default: '',
    }
  },
});
</script>
<style scoped lang="scss">
.maintenance-wrapper {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  max-width: 512px;
}
</style>
