const auth = {
  // FIXME: hardcoded email
  errorDuringTokenExchange: "Ошибка при обмене токена. При повторении ошибки обратитесь к по адресу admin%40xxkap.app или попробуйте очистить кэш браузера и войти заново.",
  errorLoadingUserData: "Ошибка при загрузке данных пользователя. Попробуйте обновить страницу (нажмите Ctrl + F5).",
  vkButtonText: "ВКонтакте",
  googleButtonText: "Войти через Google",
  userLoggedOut: "Вы вышли из системы",
  userLoaded: "Привет %{name}! Вы успешно авторизованы в системе.",
};

export default auth;
