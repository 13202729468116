const profile = {
  header: "Профиль",
  hunter: "Охотник",
  exit: "Выйти",
  oops: "Упс...",
  underConstructionText: "Этот раздел все ещё в разработке... Но мы это вот-вот исправим!",
  blocked: "Вы были заблокированы. Чтобы уточнить причину блокировки, обратитесь к администратору.",
  youHaveModeratorRights: "У вас есть права модератора, вы можете открыть консоль модератора, нажав на кнопку ниже.",
  write: "Написать",
  console: "Консоль",
  hello: "Привет",
};

export default profile;
