// src/store/applications.ts
// Module for applications store.
import { defineStore } from 'pinia';
import { objectsModule } from '@/api/objects';
import { Applicant } from '@/store//models/applicant.model'
import { Application } from '@/store/models/application.model'
import { Application as ApiApplication } from '@/api/models/application.model'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import {
  mapApiApplicationToStoreApplication,
  mapStoreApplicationToCreateApiApplication, mapStoreApplicationToUpdateApiApplicationDuringModeration
} from '@/helpers/mappers/application.mapper'
import { mapApiApplicantToStoreApplicant } from '@/helpers/mappers/applicant.mapper'
import { useModerationStore } from '@/store/moderation'
import { APPLICATION_REJECT_REASON } from '@/helpers/enums/application-reject-reason'


// TODO: all actions which use unsafe methods must use idempotency keys
export const useApplicationsStore = defineStore('applications', {
  state: () => ({
    // TODO: retrieve from backend only one Applicant by user id
    applicants: [] as Applicant[],
    applicationGenerationInProgress: false as boolean,
    currentApplication: null as Application | null,
    isInitialized: false as boolean,
  }),

  actions: {

    async resetState() {
      this.applicants = [];
      this.applicationGenerationInProgress = false;
      this.currentApplication = null;
    },

    async loadApplicants() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      try {
        const response = await objectsModule.getApplicants();
        if(response?.status == 200) {
          this.applicants = response.data.map(mapApiApplicantToStoreApplicant);
          appStore.addSuccessMessage("Заявители успешно загружены");
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке заявителей: ${response?.status} ${response?.statusText}`);
        }
      } catch (error) {
        if(error.response?.status >= 500 || error.response?.status === 408) {
          appStore.addErrorMessage(`Ошибка при загрузке заявителей, повторная попытка через 10 секунд`);
          appStore.addDebugMessage(`Ошибка при загрузке заявителей: ${error}`);
          setTimeout(() => {this.loadApplicants()}, 10000);
        } else {
          appStore.addDebugMessage(`Ошибка при загрузке заявителей: ${error}`);
        }
      } finally {
        this.isInitialized = true;
      }
    },

    async waitInitialization() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (this.isInitialized) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 250);
      });
    },

    async createApplication(violationId: string, applicantId: string) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      this.applicationGenerationInProgress = true;
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const requestBody: Application = {
        applicant: applicantId,
        violation: violationId,
      }
      try {
        const response = await objectsModule.createApplication(mapStoreApplicationToCreateApiApplication(requestBody));
        if(response?.status == 201) {
          this.currentApplication = mapApiApplicationToStoreApplication(response.data);
          setTimeout(() => { this.retrieveApplication() }, 500);
        } else {
          appStore.addErrorMessage(`Ошибка при создании заявления: ${response?.status}, ${response?.statusText}`);
          this.applicationGenerationInProgress = false;
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при создании заявления,: ${error}`);
        this.applicationGenerationInProgress = false;
      }
    },

    async rejectApplication(uid: string, reason: APPLICATION_REJECT_REASON){
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      const moderationStore = useModerationStore();
      await moderationStore.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const data: Application = {
        rejectedReason: reason,
        isConfirmed: false,
      }
      try {
        const response = await objectsModule.patchApplication(uid, mapStoreApplicationToUpdateApiApplicationDuringModeration(data));
        if(response?.status == 200) {
          await moderationStore.dropCurrentViolationForModeration(moderationStore.currentViolationForModeration?.id);
          await moderationStore.selectNextViolationForModeration();
        } else {
          appStore.addErrorMessage(`Ошибка при отклонении заявления (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при отклонении заявления: ${error}`);
      } finally {
        const moderationStore = useModerationStore();
        await moderationStore.setInProgress(false);
      }
    },

    async confirmApplication(uid: string) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      const moderationStore = useModerationStore();
      await moderationStore.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const data: Application = {
        isConfirmed: true,
      }
      try {
        const response = await objectsModule.patchApplication(uid, mapStoreApplicationToUpdateApiApplicationDuringModeration(data));
        if(response?.status == 200) {
          await moderationStore.dropCurrentViolationForModeration(moderationStore.currentViolationForModeration?.id);
          await moderationStore.selectNextViolationForModeration();
        } else {
          appStore.addErrorMessage(`Ошибка при подтверждении заявления (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при подтверждении заявления: ${error}`);
      } finally {
        const moderationStore = useModerationStore();
        await moderationStore.setInProgress(false);
      }
    },

    async searchCurrentApplicationByViolationId(){
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const moderationStore = useModerationStore();
      try {
        const response = await objectsModule.getApplicationByViolationId(moderationStore.currentViolationForModeration?.id);
        if(response?.status == 200) {
          const responseApplications = response.data.map((application: ApiApplication) => mapApiApplicationToStoreApplication(application))
          if(responseApplications.length < 1) {
            appStore.addDebugMessage("Заявление не найдено, создаем новое");
            this.applicationGenerationInProgress = true;
            await this.createApplication(moderationStore.currentViolationForModeration?.id, this.applicants[0].id);
          } else {
            this.currentApplication = responseApplications[0] //  FIXME: backend must return only one object, not slice
          }
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке заявления, сервер вернул код ${response?.status}`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке заявления: ${error}`);
        if(error.response?.status >= 500 || error.response?.status == 408) {
          setTimeout(() => {this.searchCurrentApplicationByViolationId()}, Math.max(5000 * Math.random(), 2000));
        }
      }
    },

    async retrieveApplication() {
      if(!this.currentApplication) {
        return;
      }
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const response = await objectsModule.retrieveApplication(this.currentApplication.id);
        if(response?.status == 200) {
          this.currentApplication = mapApiApplicationToStoreApplication(response.data)
          if(this.currentApplication.pdfFile) {
            this.applicationGenerationInProgress = false;
            window.open(this.currentApplication.pdfFile, '_blank');
          } else {
            setTimeout(() => {this.retrieveApplication()}, 1000);
          }
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке заявления, сервер вернул код ${response?.status}`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке заявления: ${error}`);
        if(error.response?.status >= 500 || error.response?.status == 408) {
          setTimeout(() => {this.retrieveApplication()}, Math.max(5000 * Math.random(), 2000));
        }
      }
    },
  }
});
