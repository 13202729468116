<template>
  <MDBModal v-model="visible" centered scrollable>
    <MDBModalHeader>
      <MDBModalTitle>
        {{ $t('moderatorView.selectViolationType')}}
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <MDBListGroup light>
        <MDBListGroupItem class="d-flex justify-content-between align-items-center" v-for='vt in ifaceStore.availableTypesOfViolationsForSelector' :key='vt.id' :id='vt.id'>
          <div class="d-flex align-items-center">
            <img :src="vt.icon" style="width: 45px; height: 45px"/>
            <div class="ms-3">
              <p class="fw-bold mb-1">{{ vt.name }}</p>
              <MDBBtn color="link" size="sm" class='ms-auto' @click='ifaceStore.hideViolationTypeSelectorModal();ifaceStore.showInformationCardModal(vt.description, vt.name, vt.image, ifaceStore.showViolationTypeSelectorModal)'>
                {{ $t('moderatorView.moreInfo')}}
              </MDBBtn>
            </div>
          </div>
          <MDBBtn outline="success" size="sm" class='ms-auto' rounded v-if='ifaceStore.selectedTypeOfViolationId == vt.id' disabled>
            {{ $t('moderatorView.current')}}
          </MDBBtn>
          <MDBBtn color="primary" size="sm" class='ms-auto' rounded v-else @click='ifaceStore.violationTypeSelectorModalSelectTypeId(vt.id)'>
            {{ $t('moderatorView.select') }}
          </MDBBtn>
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBModalBody>
    <MDBModalFooter class='p-2'>
      <MDBBtn class='me-auto' color="primary" @click="ifaceStore.hideViolationTypeSelectorModal">
        {{ $t('content.close') }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>
<script  lang="ts">
import { defineComponent } from 'vue';
import { MDBModal, MDBBtn, MDBModalBody, MDBModalHeader, MDBModalTitle, MDBListGroup, MDBModalFooter, MDBListGroupItem } from 'mdb-vue-ui-kit'
import { useIfaceStore } from '@/store/iface';

export default  defineComponent({
  name: "ViolationTypeSelectorModal",
  components: {
    MDBListGroupItem,
    MDBModalFooter,
    MDBListGroup,
    MDBModalTitle,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBBtn
  },
  computed: {
    visible: {
      get(): boolean {
        return this.ifaceStore.violationTypeSelectorModalVisible;
      },
      set(value: boolean) {
        if (!value) {
          this.ifaceStore.hideViolationTypeSelectorModal();
        }
      },
    }
  },
  setup() {
    const ifaceStore = useIfaceStore();
    return {
      ifaceStore,
    }
  }
});
</script>
