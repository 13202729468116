<template>
  <MDBModal v-model="visible" centered scrollable>
    <MDBCard>
      <MDBCardImg v-if="ifaceStore.informationCardModalImage" top :src="ifaceStore.informationCardModalImage" />
      <MDBCardBody>
        <MDBCardTitle v-if="ifaceStore.informationCardModalTitle">{{ ifaceStore.informationCardModalTitle }}</MDBCardTitle>
        <MDBCardText>{{ ifaceStore.informationCardModalText }}</MDBCardText>
        <MDBBtn class='me-auto' color="primary" @click="visible = false">
          {{ $t('content.close') }}
        </MDBBtn>
      </MDBCardBody>
    </MDBCard>
  </MDBModal>
</template>
<script  lang="ts">
import { defineComponent } from 'vue';
import { MDBModal, MDBCard, MDBCardImg, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from 'mdb-vue-ui-kit';
import { useIfaceStore } from '@/store/iface';

export default  defineComponent({
  name: "InformationCardModalContainer",
  components: { MDBModal, MDBCard, MDBCardImg, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn },
  computed: {
    visible: {
      get(): boolean {
        return this.ifaceStore.informationCardModalVisible;
      },
      set(value: boolean) {
        if (!value) {
          this.ifaceStore.closeInformationCardModal();
        }
      },
    }
  },
  setup() {
    const ifaceStore = useIfaceStore();
    return {
      ifaceStore,
    }
  }
});
</script>
