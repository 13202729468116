// src/store/cache.ts
// Module for caching data in IndexedDB.
import { defineStore } from 'pinia';


export const useCacheStore = defineStore('cache', {
  state: () => ({
    initialized: false,
  }),

  actions: {
    async initializeDatabase(): Promise<void> {
      return new Promise((resolve, reject) => {
        if (this.initialized) {
          resolve();
          return;
        }
        const request = window.indexedDB.open("oinkoinkdb", 1);
        request.onupgradeneeded = (event) => {
          // @ts-ignore
          const db = event.target.result;

          db.onerror = (event) => {
            reject(db.error);
          };
          const objectStore = db.createObjectStore("images", {
            keyPath: "key",
          })
          this.initialized = true;
          resolve();
        };
        request.onsuccess = (event) => {
          // @ts-ignore
          const db = event.target.result;

          db.onerror = (event) => {
            reject(db.error);
          };
          this.initialized = true;
          resolve();
        };
      });
    },

    async getCachedBase64(key: string): Promise<string | null> {
      if (!this.initialized) {
        await this.initializeDatabase();
      }
      const self = this;
      return new Promise((resolve, reject) => {
        const request = indexedDB.open('oinkoinkdb', 1);

        request.onerror = (event) => {
          reject(request.error);
        };

        request.onsuccess = (event) => {
          const db = request.result;

          db.onversionchange = () => {
            db.close();
            window.location.reload();
          };

          const transaction = db.transaction('images', 'readonly');
          const store = transaction.objectStore('images');
          const getRequest = store.get(key);

          getRequest.onsuccess = (event) => {
            if (getRequest.result) {
              resolve(getRequest.result.base64);
            } else {
              resolve(null);
            }
          };

          getRequest.onerror = (event) => {
            reject(getRequest.error);
          };
        };
      });
    },

    async saveToIndexedDB(key: string, base64: string): Promise<void> {
      if (!this.initialized) {
        await this.initializeDatabase();
      }
      return new Promise((resolve, reject) => {
        const request = indexedDB.open('oinkoinkdb', 1);

        request.onupgradeneeded = (event) => {
          const db = request.result;
          if (!db.objectStoreNames.contains('images')) {
            db.createObjectStore('images', { keyPath: 'key' });
          }
        };

        request.onsuccess = (event) => {
          const db = request.result;
          const transaction = db.transaction('images', 'readwrite');
          const store = transaction.objectStore('images');
          const putRequest = store.put({ key, base64 });

          putRequest.onsuccess = (event) => {
            resolve();
          };

          putRequest.onerror = (event) => {
            reject(putRequest.error);
          };
        };

        request.onerror = (event) => {
          reject(request.error);
        };
      });
    },
  },
});
