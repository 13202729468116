<template>
  <div class="hunting-wrapper">
    <HuntingStatsContainer />
  </div>
</template>
<script lang="ts">
import { defineComponent, watch } from 'vue'
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';
import { HOME_ROUTE_NAME } from '@/helpers/constants/routes';
import HuntingStatsContainer from '@/ui/containers/HuntingStatsContainer.vue';
import i18n from '@/i18n/vue-i18n';


export default defineComponent({
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const router = useRouter();

    watch(() => authStore.user, (user) => {
      if (user && user.isBanned) {
        appStore.addErrorMessage(i18n.global.t('profile.blocked'), true, 5000);
      }
    });

    watch(() => authStore.isInitialized, () => {
      if (!authStore.isAuthenticated) {
        router.push({ name: HOME_ROUTE_NAME });
      }
    });

    return {
      appStore,
    }
  },
  name: "HuntingContainer",
  components: {
    HuntingStatsContainer,
  },
});
</script>
<style scoped lang="scss">
.hunting-wrapper {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}
</style>
