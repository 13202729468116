<template>
  <div class="console-wrapper m-0 p-0">
    <MDBNavbar light bg="light" position="sticky" container class="mb-2">
      <h4 class='mt-1 mb-1 ms-2 me-auto'>
        <MDBIcon iconStyle="fas" icon="tachometer-alt" class='me-2'/>
        {{ $t('moderatorView.title')}}
      </h4>
      <MDBSwitch v-model="orderByDistance" :label="moderationStore.orderByDistance ? $t('moderatorView.orderByDistance') : $t('moderatorView.orderByDate')" />
      <MDBBtnClose @click='closeConsole' class="ms-3"/>
    </MDBNavbar>
    <MaintenanceComponent v-if='!moderationPartAvailable' :message='appStore.siteState.messageForModerators'/>
    <div class='card-wrapper align-items-center justify-content-center text-center mt-3 mb-3 ms-auto me-auto' v-else-if='!moderationStore.currentViolationForModeration && moderationStore.isInitialized && !loading'>
      <MDBCard class='ms-2 me-2'>
        <MDBCardImg
          src="/oinoinkcar_splash.png"
          top
          alt="Pig on a car"
        />
        <MDBCardBody>
          <MDBCardTitle>{{ $t('moderatorView.noViolationsToModerate') }}</MDBCardTitle>
          <MDBBtn color="dark" @click='closeConsole'>
            <MDBIcon iconStyle="fas" icon="times" class='me-2'/>
            {{ $t("moderatorView.close") }}
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </div>
    <!--
      Warning about the use of a non-free component. Attention! The MDBStepper component is provided under the
      "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source
      software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license
      from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code to
      eliminate the use of non-free components.
    -->
    <MDBStepper v-else :verticalBreakpoint="1900" ref='stepper' disableStepperHeadClick class='mb-10 console-stepper' :style="!moderationStore.currentViolationForModeration? 'display:none;':''">
      <ModerationStepOverviewContainer />
      <ModerationStepLocationCheckContainer />
      <ModerationStepPlateNumberCheckContainer />
      <ModerationStepViolationTypeCheckContainer />
      <ModerationStepApplicationReviewContainer />
    </MDBStepper>
    <!--
      Warning about the use of a non-free component. Attention! The MDBLoading component is provided under the
      "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source software.
      To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license from the
      vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code to eliminate
      the use of non-free components.
    -->
    <MDBLoading
      v-if='moderationPartAvailable'
      v-model="loading"
      :fullScreen='true'
      :loading-text="$t('moderatorView.loading')"
      spinnerClasses="text-primary"
      textClasses="text-primary text-center mt-2"
    />
    <RejectReasonSelectorModalContainer />
  </div>
</template>
<script lang="ts">
import { defineComponent, watch, ref, onBeforeMount, onUpdated, onMounted, onUnmounted } from 'vue';
import { OPERATION_MODE } from '@/helpers/enums/operation-mode';
import {HOME_ROUTE_NAME} from '@/helpers/constants/routes';
import { useAppStore } from '@/store/app';
import { useModerationStore } from '@/store/moderation';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';
import { MDBBtnClose, MDBNavbar, MDBLoading, MDBIcon, MDBCard, MDBBtn, MDBCardBody, MDBCardTitle, MDBCardImg, MDBStepper, MDBSwitch } from 'mdb-vue-ui-kit'
import RejectReasonSelectorModalContainer from '@/ui/containers/RejectReasonSelectorModalContainer.vue'
import MaintenanceComponent from '@/ui/components/MaintenanceComponent.vue'
import ModerationStepOverviewContainer from '@/ui/containers/ModerationStepOverviewContainer.vue'
import ModerationStepLocationCheckContainer from '@/ui/containers/ModerationStepLocationCheckContainer.vue'
import ModerationStepPlateNumberCheckContainer from '@/ui/containers/ModerationStepPlateNumberCheckContainer.vue'
import ModerationStepViolationTypeCheckContainer from '@/ui/containers/ModerationStepViolationTypeCheckContainer.vue'
import ModerationStepApplicationReviewContainer from '@/ui/containers/ModerationStepApplicationReviewContainer.vue'


export default defineComponent({
  name: "ModeratorView",
  components: {
    MDBStepper,
    MDBCardImg,
    MDBCardTitle,
    MDBCardBody,
    MDBBtn,
    MDBCard,
    MDBLoading,
    MDBNavbar,
    MDBBtnClose,
    MDBIcon,
    RejectReasonSelectorModalContainer,
    MaintenanceComponent,
    ModerationStepOverviewContainer,
    ModerationStepLocationCheckContainer,
    ModerationStepPlateNumberCheckContainer,
    ModerationStepViolationTypeCheckContainer,
    ModerationStepApplicationReviewContainer,
    MDBSwitch,
  },
  methods: {
    closeConsole() {
      this.$router.push({ name: HOME_ROUTE_NAME });
    },
  },
  computed: {
    orderByDistance: {
      get() {
        return this.moderationStore.orderByDistance;
      },
      set(value) {
        this.moderationStore.setOrderByDistance(value);
      }
    },
  },
  setup() {
    const appStore = useAppStore();
    const moderationStore = useModerationStore();
    const authStore = useAuthStore();
    const router = useRouter();
    const stepper = ref(null);
    const loading = ref(moderationStore.violationModerationQueueInProgress || !moderationStore.isInitialized);

    watch(() => moderationStore.violationModerationQueueInProgress, (newValue) => {
      loading.value = newValue;
    });

    function checkLayout() {
      moderationStore.setHorizontalLayout(window.innerWidth > 770);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 2000);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 5000);
    }

    watch(() => moderationStore.currentModerationStep, (newValue) => {
      stepper.value.changeStep(newValue);
      // Trigger a resize event to fix the layout
      checkLayout();
    });

    onBeforeMount(() => {
      checkLayout();
      window.addEventListener('load', checkLayout);
      if (moderationStore.isInitialized) {
        moderationStore.loadViolationModerationQueue();
      } else {
        moderationStore.initialize();
      }
    });
    onMounted(() => {
      checkLayout();
      stepper?.value?.changeStep(moderationStore.currentModerationStep);
    });
    onUpdated(() => {
      checkLayout();
    });
    onUnmounted(() => {
      window.removeEventListener('load', checkLayout);
      moderationStore.resetState();
    });

    // Check if moderation part is available
    const moderationPartAvailable = ref(false);
    onBeforeMount(() => {
      moderationPartAvailable.value = appStore.siteState.moderationMode === OPERATION_MODE.MODE_NORMAL && appStore.siteState.publicMode === OPERATION_MODE.MODE_NORMAL;
    });
    watch(() => appStore.siteState, (newValue) => {
      moderationPartAvailable.value = newValue.moderationMode === OPERATION_MODE.MODE_NORMAL && newValue.publicMode === OPERATION_MODE.MODE_NORMAL;
    });

    // Redirect to the map if the user is not authenticated
    watch(() => authStore.isInitialized, () => {
      if (!authStore.isAuthenticated) {
        router.push({ name: "map" });
      }
    });
    // Redirect to the profile if the user is banned or not a moderator
    watch(() => authStore.user, (user) => {
      if (user && (user.isBanned || !user.isModerator) && !user.isSuperuser) {
        router.push({ name: "profile" });
      }
    });

    watch(() => moderationStore.currentViolationForModeration, (newValue) => {
      if (newValue) {
        checkLayout();
      }
    });

    return {
      moderationStore,
      moderationPartAvailable,
      appStore,
      stepper,
      loading,
    };
  },
});
</script>
<style scoped lang="scss">
.console-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.console-wrapper::-webkit-scrollbar {
  width: 0.1rem;
}

.console-wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.console-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.card-wrapper {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  max-width: 512px;
}

</style>
