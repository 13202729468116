import {
  GeoPhoto as StoreGeoPhoto,
  GeoPhotoModerationPatch as StoreGeoPhotoModerationPatch,
  GeoPhotoForMap as StoreGeoPhotoForMap
} from '@/store/models/geophoto.model';
import {
  GeoPhoto as ApiGeoPhoto,
  GeoPhotoModerationPatch as ApiGeoPhotoModerationPatch,
  GeoPhotoForMap as ApiGeoPhotoForMap
} from '@/api/models/geophoto.model';
import { convertCoordinates } from '@/helpers/functions'

export function mapApiGeoPhotoToStoreGeoPhoto(photo: ApiGeoPhoto): StoreGeoPhoto {
  return {
    id: photo.id,
    modifiedAt: photo.modified_at? new Date(photo.modified_at) : null,
    createdAt: new Date(photo.created_at),
    geom: photo.geom,
    srcPhoto: photo.src_photo,
    compressedPhoto: photo.compressed_photo ?? null,
    address: photo.address ?? null,
    capturedAt: photo.captured_at? new Date(photo.captured_at) : null,
    addressConfirmed: photo.address_confirmed ?? null,
    initialTypeOfViolation: photo.initial_type_of_violation ?? null,
    uploaded: true,
    isRecognized: photo.is_recognized ?? false,
    isViolationSelected: photo.is_violation_selected ?? null,
    isGeocoded: photo.is_geocoded ?? false,
    moderationStatus: photo.moderation_status,
    panoramaShot: photo.panorama_shot ?? undefined,
  }
}

export function mapStoreGeoPhotoToApiGeoPhotoForCreate(photo: StoreGeoPhoto): ApiGeoPhoto {
  return {
    geom: photo.geom,
    src_photo: photo.srcPhoto,
    captured_at: photo.capturedAt?.toISOString() ?? null,
    initial_type_of_violation: photo.initialTypeOfViolation ?? null,
    accuracy_horizontal: photo.accuracyHorizontal ?? null,
    compass_angle: photo.compassAngle ?? null,
  }
}

export function mapStoreGeoPhotoModerationPatchToApiGeoPhotoModerationPatch(photo: StoreGeoPhotoModerationPatch): ApiGeoPhotoModerationPatch {
  return {
    address: photo.address ?? undefined,
    address_confirmed: photo.addressConfirmed ?? undefined,
    is_rejected: photo.isRejected ?? undefined,
    panorama_shot: photo.panoramaShot,
    moderation_status: photo.moderationStatus ?? undefined,
    geom: photo.geom ?? undefined,
  }
}

export function mapApiGeoPhotoForMapToStoreGeoPhotoForMap(photo: ApiGeoPhotoForMap): StoreGeoPhotoForMap {
  return {
    id: photo.id,
    modifiedAt: photo.modified_at? new Date(photo.modified_at) : null,
    createdAt: new Date(photo.created_at),
    capturedAt: new Date(photo.captured_at),
    geom: convertCoordinates(photo.geom.coordinates[0], photo.geom.coordinates[1]),
    srcPhoto: photo.src_photo,
    compressedPhoto: photo.compressed_photo ?? null,
    address: photo.address ?? null,
    moderationStatus: photo.moderation_status,
    panoramaShot: photo.panorama_shot ?? undefined,
  }
}
