<template>
  <MDBStepperStep :dynamic="true">
    <MDBStepperHead>
      <template #icon>
        <MDBIcon fas icon="car-alt" />
      </template>
      {{ $t('moderatorView.overview') }}
    </MDBStepperHead>
    <MDBStepperContent>
      <div class='vstack gap-1 mt-0 pt-0'>
        <MDBCard class='console-card'>
          <MDBCardHeader class='m-0 p-0 rounded-top-2 border-0'>
            <!--
              Warning about the use of a non-free component. Attention! The MDBLightbox component is provided under
              the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
              open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase
              the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or
              make changes to this source code to eliminate the use of non-free components.
            -->
            <MDBLightbox v-if='currentViolationSrcPhoto && !moderationStore.violationModerationQueueInProgress'>
              <MDBLightboxItem
                 v-if="currentViolationSrcPhoto"
                :src="currentViolationSrcPhoto"
                :fullScreenSrc="currentViolationSrcPhoto"
                :alt="$t('moderatorView.photoOfViolation')"
                className='rounded-top-5 object-cover w-100 console-card-img-top'
              />
            </MDBLightbox>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>
              {{ moderationStore.currentViolationForModeration?.carPlate.plateNumber }}
            </MDBCardTitle>
            <MDBCardText>
              <p class="lead">{{ moderationStore.currentViolationForModeration?.carPlate.photo.address }}</p>
              <div class='hstack gap-2'>
                <strong>{{ moderationStore.currentViolationForModeration?.violationType.name }}</strong>
                <a class="m-1" role="button" @click='showViolationTypeDescription'>
                  <MDBIcon iconStyle="fas" icon="info-circle" size="sm"></MDBIcon>
                </a>
              </div>
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter class='p-2'>
            <div class='hstack gap-2'>
              <MDBBtn color="danger" @click="ifaceStore.showRejectReasonSelectorModal" class='me-auto'>
                <i class="fas fa-ban me-2" />
                {{ $t('moderatorView.reject') }}
              </MDBBtn>
              <MDBBtn color="secondary" @click="moderationStore.secondStep" class='ms-auto'>
                <i class="fas fa-eye me-2" />
                {{ $t('moderatorView.verify') }}
              </MDBBtn>
            </div>
          </MDBCardFooter>
        </MDBCard>
      </div>
    </MDBStepperContent>
  </MDBStepperStep>
</template>
<script  lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue'
import {
  MDBCard,
  MDBBtn,
  MDBCardBody,
  MDBStepperContent,
  MDBIcon,
  MDBStepperHead,
  MDBCardTitle,
  MDBCardFooter,
  MDBLightbox,
  MDBStepperStep,
  MDBCardHeader,
  MDBLightboxItem,
  MDBCardText
} from 'mdb-vue-ui-kit'
import { useModerationStore } from '@/store/moderation'
import { useIfaceStore } from '@/store/iface'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import i18n from '@/i18n/vue-i18n'
import { MODERATION_STEP } from '@/helpers/enums/moderation-step'
import { useAppStore } from '@/store/app'


export default  defineComponent({
  name: "ModerationStepOverviewContainer",
  components: {
    MDBCardText,
    MDBLightboxItem,
    MDBCardHeader,
    MDBStepperStep,
    MDBLightbox,
    MDBCardFooter,
    MDBCardTitle,
    MDBStepperHead,
    MDBIcon,
    MDBStepperContent,
    MDBCardBody,
    MDBCard,
    MDBBtn
  },
  methods: {
    showViolationTypeDescription() {
      this.ifaceStore.showInformationCardModal(
        this.moderationStore.currentViolationForModeration?.violationType.description,
        this.moderationStore.currentViolationForModeration?.violationType.name,
        this.moderationStore.currentViolationForModeration?.violationType.image
      );
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const appStore = useAppStore();
    const moderationStore = useModerationStore();
    const currentViolationSrcPhoto = ref("");

    function rejectionCallback(reason: MODERATION_STATUS) {
      moderationStore.rejectGeoPhoto(reason);
      appStore.addSuccessMessage(i18n.global.t('moderatorView.photoRejected'), true, 2000);
    }

    function configureStep() {
      if (moderationStore.currentModerationStep === MODERATION_STEP.OVERVIEW) {
        ifaceStore.setupRejectReasonSelectorModal(
          [
            MODERATION_STATUS.REJECTED_REASON_BLURRED,
            MODERATION_STATUS.REJECTED_REASON_FACES,
            MODERATION_STATUS.REJECTED_REASON_NO_CAR,
            MODERATION_STATUS.REJECTED_REASON_CAR_NOT_FIT,
            MODERATION_STATUS.REJECTED_REASON_FLOOD,
            MODERATION_STATUS.REJECTED_REASON_ADULT,
            MODERATION_STATUS.REJECTED_REASON_OTHER,
          ],
          i18n.global.t('moderatorView.rejectGeoPhotoModalTitle'),
          i18n.global.t('moderatorView.rejectGeoPhotoModalText'),
          rejectionCallback,
        );
      }
    }

    watch(() => moderationStore.currentModerationStep, (value) => {
      if (value === MODERATION_STEP.OVERVIEW) {
        configureStep();
      }
    });
    watch(() => moderationStore.currentViolationForModeration, (value, oldValue) => {
      if(value && value?.id !== oldValue?.id) {
        currentViolationSrcPhoto.value = value.carPlate.photo.srcPhoto;
      }
    });
    onMounted(() => {
      currentViolationSrcPhoto.value = moderationStore.currentViolationForModeration?.carPlate.photo.srcPhoto;
      configureStep();
    });

    return {
      ifaceStore,
      moderationStore,
      currentViolationSrcPhoto,
    }
  }
});
</script>
