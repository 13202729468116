import type { ViolationStats as ApiViolationStats } from "@/api/models/stats.model";
import type { ViolationStats as StoreViolationStats} from "@/store/models/stats.model";

export function mapApiViolationStatsToStoreViolationStats(apiStats: ApiViolationStats): StoreViolationStats {
  return {
    totalViolationsLast30Days: apiStats.total_violations_last_30_days,
    percentageTotalViolationsChange: apiStats.percentage_total_violations_change,
    totalTypeConfirmedLast30Days: apiStats.total_type_confirmed_last_30_days,
    percentageTypeConfirmedChange: apiStats.percentage_type_confirmed_change,
    totalNotConfirmedLast30Days: apiStats.total_not_confirmed_last_30_days,
    percentageNotConfirmedChange: apiStats.percentage_not_confirmed_change,
  };
}

export function mapStoreViolationStatsToApiViolationStats(storeStats: StoreViolationStats): ApiViolationStats {
  return {
    total_violations_last_30_days: storeStats.totalViolationsLast30Days,
    percentage_total_violations_change: storeStats.percentageTotalViolationsChange,
    total_type_confirmed_last_30_days: storeStats.totalTypeConfirmedLast30Days,
    percentage_type_confirmed_change: storeStats.percentageTypeConfirmedChange,
    total_not_confirmed_last_30_days: storeStats.totalNotConfirmedLast30Days,
    percentage_not_confirmed_change: storeStats.percentageNotConfirmedChange,
  };
}