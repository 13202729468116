// src/store/hunting.ts
// Module for hunting store.
import { defineStore } from 'pinia';
import { objectsModule } from '@/api/objects';
import { ViolationStats } from '@/store/models/stats.model'
import { GeoPhoto } from '@/store/models/geophoto.model'
import { CarPlate } from '@/store/models/car-plate.model'
import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth'
import { mapApiViolationStatsToStoreViolationStats } from '@/helpers/mappers/stats.mapper'
import { v4 as uuidv4 } from 'uuid'
import { mapApiGeoPhotoToStoreGeoPhoto, mapStoreGeoPhotoToApiGeoPhotoForCreate } from '@/helpers/mappers/geophoto.mapper'
import { mapApiCarPlatesToStoreCarPlates } from '@/helpers/mappers/car-plate.mapper'
import { useViolationTypesStore } from '@/store/violation-types'

const defaultViolationStats: ViolationStats = {
  totalViolationsLast30Days: 0,
  percentageTotalViolationsChange: 0,
  totalTypeConfirmedLast30Days: 0,
  percentageTypeConfirmedChange: 0,
  totalNotConfirmedLast30Days: 0,
  percentageNotConfirmedChange: 0,
}


export const useHuntingStore = defineStore('hunting', {
  state: () => ({
    violationStats: defaultViolationStats,
    statsLoaded: false,
    currentPhoto: null as GeoPhoto | null,
    currentPhotoUpdateAttempt: 0 as number,
    myPhotosInProcessing: [] as GeoPhoto[],
    carPlatesOnCurrentPhoto: [] as CarPlate[],
    carPlatesLoadingInProgress: false as boolean,
    photoCheckingMode: false as boolean,
  }),

  actions: {

    async resetState() {
      this.violationStats = defaultViolationStats;
      this.statsLoaded = false;
      this.currentPhoto = null;
      this.currentPhotoUpdateAttempt = 0;
      this.myPhotosInProcessing = [];
      this.carPlatesOnCurrentPhoto = [];
      this.carPlatesLoadingInProgress = false;
    },

    async updateViolationStatsLoop() {
      await this.updateViolationStats();
      setTimeout(() => {
        this.updateViolationStatsLoop();
      }, 60000);
    },

    async updateViolationStats() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if (await authStore.notAuthenticated()) {
        return;
      }
      try {
        const response = await objectsModule.getViolationStats();
        if (response?.status === 200) {
          this.violationStats = mapApiViolationStatsToStoreViolationStats(response.data);
          this.statsLoaded = true;
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке статистики нарушений: ${response?.status} ${response?.statusText}`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке статистики нарушений: ${error}`);
      }
    },

    async uploadGeoPhoto(newPhoto: GeoPhoto) {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notAuthenticatedOrBanned()) {
        return;
      }
      try {
        const response = await objectsModule.postGeoPhoto(mapStoreGeoPhotoToApiGeoPhotoForCreate(newPhoto))
        const responsePhoto = mapApiGeoPhotoToStoreGeoPhoto(response.data)
        if(this.currentPhoto?.localId == newPhoto.localId) {
          this.currentPhoto = responsePhoto;
        }
        this.myPhotosInProcessing.forEach((photo: GeoPhoto, index: number) => {
          if(photo.localId == newPhoto.localId) {
            this.myPhotosInProcessing[index] = responsePhoto;
          }
        });
        appStore.addSuccessMessage("Фотография успешно загружена");
        await this.updateCurrentGeoPhoto();
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке фотографии: ${error}`, true);
      }
    },

    async addGeoPhoto(photo_base64: string) {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notAuthenticatedOrBanned()) {
        return;
      }

      const location = await appStore.getCurrentLocation();
      if(!location) {
        appStore.addErrorMessage("Ошибка при загрузке фотографии (координаты не определены)", true);
        return;
      }

      let newPhoto = {
        geom: { type: "Point", coordinates: [location.coords.longitude, location.coords.latitude] },
        srcPhoto: photo_base64,
        capturedAt: new Date(),
        localId: uuidv4().toString(),
        uploaded: false,
        isGeocoded: false,
        isRecognized: false,
      } as GeoPhoto;
      if(location.coords.accuracy) {
        newPhoto.accuracyHorizontal = location.coords.accuracy;
      }
      if(location.coords.heading) {
        newPhoto.compassAngle = location.coords.heading;
      }
      const violationTypesStore = useViolationTypesStore();
      if(violationTypesStore.selectedTypeOfViolation) {
        newPhoto.initialTypeOfViolation = violationTypesStore.selectedTypeOfViolation.id;
      }
      this.currentPhoto = newPhoto;
      this.currentPhoto.uploaded = false;
      this.myPhotosInProcessing.push(newPhoto);
      await this.uploadGeoPhoto(newPhoto);
    },

    async cancelCurrentGeoPhotoUpload() {
      const currentPhoto = this.currentPhoto;
      if(currentPhoto) {
        if(this.carPlatesOnCurrentPhoto.length > 0) {
          this.carPlatesOnCurrentPhoto = []
        }
        const timeout = currentPhoto.id? 0 : 1000;
        setTimeout(() => {
          if(currentPhoto.id) {
            this.deleteGeoPhoto(currentPhoto);
          } else {
            this.cancelCurrentGeoPhotoUpload();
            return;
          }
          if(currentPhoto.localId == this.currentPhoto?.localId) {
            this.currentPhoto = null;
            this.carPlatesOnCurrentPhoto = [];
          }
        }, timeout);
      }
    },

    async confirmCurrentGeoPhotoUpload() {
      this.currentPhoto = null;
      this.carPlatesOnCurrentPhoto = [];
    },

    async updateCurrentGeoPhoto() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      if(!this.currentPhoto) {
        appStore.addDebugMessage("updateCurrentGeoPhoto: currentPhoto is null");
        return;
      }
      const authStore = useAuthStore();
      if(await authStore.notAuthenticated()) {
        return;
      }
      if (!this.currentPhoto?.id) {
        setTimeout(() => {
          this.updateCurrentGeoPhoto();
        }, 500);
        return;
      } else {
        try {
          const response = await objectsModule.retrieveGeoPhoto(this.currentPhoto.id);
          if (response?.status === 200) {
            this.currentPhoto = mapApiGeoPhotoToStoreGeoPhoto(response.data);
            if (this.currentPhoto.isRecognized && this.currentPhoto.isGeocoded) {
              this.loadCurrentCarPlates(this.currentPhoto.id);
            } else if (!this.currentPhoto.isGeocoded || !this.currentPhoto.isRecognized) {
              this.currentPhotoUpdateAttempt++;
              if (this.currentPhotoUpdateAttempt < 10) {
                setTimeout(() => {
                  this.updateCurrentGeoPhoto();
                }, 1000);
              } else {
                this.currentPhotoUpdateAttempt = 0;
              }
            }
          } else {
            appStore.addErrorMessage(`Ошибка при обновлении фотографии (сервер вернул код ${response?.status})`, true);
          }
        } catch (error) {
          appStore.addErrorMessage(`Ошибка при обновлении фотографии: ${error})`);
          if (error.response?.status >= 500 || error.response?.status === 408) {
            setTimeout(() => {
              this.updateCurrentGeoPhoto()
            }, Math.max(3000 * Math.random(), 1000));
          }
        }
      }
    },

    async deleteGeoPhoto(photo: GeoPhoto) {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notAuthenticatedOrBanned() || !photo.id) {
        return;
      }
      const index = this.myPhotosInProcessing.findIndex((photoInProcessing: GeoPhoto) => photoInProcessing.id === photo.id);
      if (index !== -1) {
        this.myPhotosInProcessing.splice(index, 1);
      }
      try {
        const response = await objectsModule.deleteGeoPhoto(photo.id);
        if (response?.status == 204) {
          appStore.addSuccessMessage("Фотография успешно удалена");
        }
      } catch (error) {
        appStore.addDebugMessage(`Ошибка при удалении фотографии: ${error}`);
        if (error.response?.status >= 500 || error.response?.status === 408) {
          setTimeout(() => {
            this.deleteGeoPhoto(photo);
          }, Math.max(3000 * Math.random(), 1000));
        }
      }
    },

    async deleteCarPlate(uid: string) {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notAuthenticatedOrBanned()) {
        return;
      }
      try {
        const response = await objectsModule.deleteCarPlate(uid);
        if(response?.status == 204) {
          const platesCount = this.carPlatesOnCurrentPhoto?.length
          if(platesCount > 0) {
            if(platesCount == 1) {
              this.cancelCurrentGeoPhotoUpload()
              appStore.addWarningMessage(`Все автомобильные номера удалены, фотография тоже будет удалена с сервера.`)
            }
            this.carPlatesOnCurrentPhoto = this.carPlatesOnCurrentPhoto.filter(obj => obj.id !== uid);
          }
        } else {
          appStore.addErrorMessage(`Ошибка при удалении автомобильных номеров (сервер вернул код ${response?.status})`, true);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при удалении автомобильных номеров: ${error}`, true);
      }
    },

    async setCarPlatesOnCurrentPhoto(plates: CarPlate[]) {
      this.carPlatesOnCurrentPhoto = plates;
    },

    async loadCurrentCarPlates(uid: string) {
      const authStore = useAuthStore();
      if(await authStore.notAuthenticated()) {
        return;
      }
      if(this.currentPhoto?.id != uid) {
        return;
      }
      this.carPlatesLoadingInProgress = true;
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const response = await objectsModule.getCarPlatesOnPhoto(uid)
        if (response?.status == 200) {
          let responseCarPlates = mapApiCarPlatesToStoreCarPlates(response.data);
          if (this.currentPhoto?.id == uid) {
            await this.setCarPlatesOnCurrentPhoto(responseCarPlates);
          }
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке автомобильных номеров (сервер вернул код ${response?.status})`, true);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке автомобильных номеров: ${error})`, true);
        if (error?.response?.status >= 500 || error?.response?.status == 408) {
          setTimeout(() => {this.loadCurrentCarPlates(uid)}, Math.max(3000 * Math.random(), 1000));
        }
      } finally {
        this.carPlatesLoadingInProgress = false;
      }
    },

    async setPhotoCheckingMode(value: boolean) {
      this.photoCheckingMode = value;
    },
  }
});
