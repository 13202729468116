<template>
  <MDBModal v-model="visible" centered scrollable class='p-0'>
    <MDBModalHeader>
      <MDBModalTitle>
        {{ ifaceStore.rejectReasonSelectorModalHeader }}
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <p class='text-muted'>{{ ifaceStore.rejectReasonSelectorModalText }}</p>
      <MDBListGroup light>
        <MDBListGroupItem class="d-flex justify-content-between align-items-center" v-for='rr in getRejectReasonsArrayForList(ifaceStore.availableRejectReasonsForSelector)' :key='rr.reason' :id='rr.reason'>
          <MDBIcon :icon="rr.icon" size="lg"/>
          <div class="ms-3 me-auto">
            <p class="m-0 p-0">{{ rr.text }}</p>
          </div>
          <MDBBtn outline="danger" size="sm" rounded @click='ifaceStore.rejectReasonSelectorSelectedCallback(rr.reason); ifaceStore.hideRejectReasonSelectorModal()'>
            {{ $t('moderatorView.reject') }}
          </MDBBtn>
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBModalBody>
    <MDBModalFooter class='p-2'>
      <MDBBtn class='me-auto' color="primary" @click="ifaceStore.hideRejectReasonSelectorModal">
        {{ $t('moderatorView.cancel') }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>
<script  lang="ts">
import { defineComponent } from 'vue';
import { MDBModal, MDBBtn, MDBModalBody, MDBModalHeader, MDBModalTitle, MDBListGroup, MDBModalFooter, MDBListGroupItem, MDBIcon } from 'mdb-vue-ui-kit'
import { useIfaceStore } from '@/store/iface';
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import { APPLICATION_REJECT_REASON } from '@/helpers/enums/application-reject-reason'

export default  defineComponent({
  name: "RejectReasonSelectorModalContainer",
  components: {
    MDBIcon,
    MDBListGroupItem,
    MDBModalFooter,
    MDBListGroup,
    MDBModalTitle,
    MDBModalHeader,
    MDBModalBody,
    MDBModal,
    MDBBtn
  },
  computed: {
    visible: {
      get(): boolean {
        return this.ifaceStore.rejectReasonSelectorModalVisible;
      },
      set(value: boolean) {
        if (!value) {
          this.ifaceStore.hideRejectReasonSelectorModal();
        }
      },
    }
  },
  methods: {
    getRejectReasonsArrayForList(statuses: MODERATION_STATUS[] | APPLICATION_REJECT_REASON[]) {
      let result = [
        { reason: MODERATION_STATUS.REJECTED_REASON_BLURRED, icon: "eye-slash", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_BLURRED}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_FACES, icon: "angry", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_FACES}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_NO_CAR, icon: "search", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_NO_CAR}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_CAR_NOT_FIT, icon: "border-style", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_CAR_NOT_FIT}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_GEOLOCATION, icon: "map-marker-alt", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_GEOLOCATION}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_CAR_PLATE_NOT_READABLE, icon: "glasses", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_CAR_PLATE_NOT_READABLE}`) },
        { reason: MODERATION_STATUS.REJECTED_INTERNATIONAL_CAR_PLATE, icon: "globe-europe", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_INTERNATIONAL_CAR_PLATE}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED, icon: "exclamation-triangle", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED}`) },
        { reason: APPLICATION_REJECT_REASON.REJECTED_REASON_AUTHORITY_ERROR, icon: "user-tie", text: this.$t(`moderatorView.rejectReasonText_${APPLICATION_REJECT_REASON.REJECTED_REASON_AUTHORITY_ERROR}`) },
        { reason: APPLICATION_REJECT_REASON.REJECTED_REASON_APPLICANT_ERROR, icon: "user-secret", text: this.$t(`moderatorView.rejectReasonText_${APPLICATION_REJECT_REASON.REJECTED_REASON_APPLICANT_ERROR}`) },
        { reason: APPLICATION_REJECT_REASON.REJECTED_REASON_LAYOUT_ERROR, icon: "code", text: this.$t(`moderatorView.rejectReasonText_${APPLICATION_REJECT_REASON.REJECTED_REASON_LAYOUT_ERROR}`) },
        { reason: APPLICATION_REJECT_REASON.REJECTED_REASON_MODERATION_ERROR, icon: "user-shield", text: this.$t(`moderatorView.rejectReasonText_${APPLICATION_REJECT_REASON.REJECTED_REASON_MODERATION_ERROR}`) },
        { reason: APPLICATION_REJECT_REASON.REJECTED_REASON_ENCODING_ERROR, icon: "exclamation-triangle", text: this.$t(`moderatorView.rejectReasonText_${APPLICATION_REJECT_REASON.REJECTED_REASON_ENCODING_ERROR}`) },
        { reason: APPLICATION_REJECT_REASON.REJECTED_REASON_TIMEOUT, icon: "calendar-times", text: this.$t(`moderatorView.rejectReasonText_${APPLICATION_REJECT_REASON.REJECTED_REASON_TIMEOUT}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_FLOOD, icon: "robot", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_FLOOD}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_ADULT, icon: "book-dead", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_ADULT}`) },
        { reason: MODERATION_STATUS.REJECTED_REASON_OTHER, icon: "bug", text: this.$t(`moderatorView.rejectReasonText_${MODERATION_STATUS.REJECTED_REASON_OTHER}`) },
      ];
      // @ts-ignore
      return result.filter((rr) => statuses.includes(rr.reason));
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    return {
      ifaceStore,
    }
  }
});
</script>
