import { CarPlate as StoreCarPlate } from '@/store/models/car-plate.model';
import { CarPlateForModeration as StoreCarPlateForModeration } from '@/store/models/car-plate.model';
import { CarPlate as ApiCarPlate } from '@/api/models/car-plate.model';
import { CarPlateForModeration as ApiCarPlateForModeration } from '@/api/models/car-plate.model';
import { CarPlateForMap as ApiCarPlateForMap } from '@/api/models/car-plate.model';
import { CarPlateForMap as StoreCarPlateForMap } from '@/store/models/car-plate.model';
import { mapApiGeoPhotoToStoreGeoPhoto, mapApiGeoPhotoForMapToStoreGeoPhotoForMap } from '@/helpers/mappers/geophoto.mapper'

export function mapApiCarPlateToStoreCarPlate(carPlate: ApiCarPlate): StoreCarPlate {
  return {
    id: carPlate.id,
    modifiedAt: carPlate.modified_at? new Date(carPlate.modified_at) : null,
    createdAt: new Date(carPlate.created_at),
    plateNumber: carPlate.plate_number,
    numberConfirmed: carPlate.number_confirmed,
    bBox: carPlate.b_box,
  }
}

export function mapApiCarPlatesToStoreCarPlates(carPlates: ApiCarPlate[]): StoreCarPlate[] {
  return carPlates.map(mapApiCarPlateToStoreCarPlate)
}

export function mapStoreCarPlateToApiCarPlateForEdit(carPlate: StoreCarPlate): ApiCarPlate {
  return {
    plate_number: carPlate.plateNumber,
  }
}

export function mapApiCarPlateForModerationToStoreCarPlateForModeration(carPlate: ApiCarPlateForModeration): StoreCarPlateForModeration {
  return {
    id: carPlate.id,
    modifiedAt: carPlate.modified_at? new Date(carPlate.modified_at) : null,
    createdAt: new Date(carPlate.created_at),
    plateNumber: carPlate.plate_number,
    numberConfirmed: carPlate.number_confirmed,
    bBox: carPlate.b_box,
    photo: mapApiGeoPhotoToStoreGeoPhoto(carPlate.photo),
    moderationStatus: carPlate.moderation_status,
  }
}

export function mapStoreCarPlateForModerationToApiCarPlateForModeration(carPlate: StoreCarPlateForModeration): ApiCarPlateForModeration {
  return {
    plate_number: carPlate.plateNumber ? carPlate.plateNumber : undefined,
    number_confirmed: carPlate.numberConfirmed != null ? carPlate.numberConfirmed : undefined,
    moderation_status: carPlate.moderationStatus ? carPlate.moderationStatus : undefined,
  }
}

export function mapApiCarPlateForMapToStoreCarPlateForMap(carPlate: ApiCarPlateForMap): StoreCarPlateForMap {
  return {
    id: carPlate.id,
    modifiedAt: carPlate.modified_at? new Date(carPlate.modified_at) : null,
    createdAt: new Date(carPlate.created_at),
    plateNumber: carPlate.plate_number,
    moderationStatus: carPlate.moderation_status,
    photo: mapApiGeoPhotoForMapToStoreGeoPhotoForMap(carPlate.photo),
  }
}
