// src/store/iface.ts
// Module for application-wide interface state.
import { defineStore } from 'pinia';
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status';
import { TypeOfViolation } from '@/store/models/type-of-violation.model';
import { APPLICATION_REJECT_REASON } from '@/helpers/enums/application-reject-reason';

export const useIfaceStore = defineStore('iface', {
  state: () => ({
    isLocaleDropdownOpen: false as boolean,
    isMapFilterModalOpen: false as boolean,
    isSideNavOpen: false as boolean,
    isFooterVisible: true as boolean,
    isViolationDetailsModalOpen: false as boolean,
    huntingSpinnerVisible: false as boolean,

    // Information card modal
    informationCardModalVisible: false as boolean,
    informationCardModalImage: '' as string,
    informationCardModalTitle: '' as string,
    informationCardModalText: '' as string,
    informationCardModalClosedCallback: null as Function | null,

    // Violation type selector
    violationTypeSelectorModalVisible: false as boolean,
    availableTypesOfViolationsForSelector: [] as TypeOfViolation[],
    violationTypeChangeCallback: null as Function | null,
    selectedTypeOfViolationId: '' as string,

    // Reject reason selector
    rejectReasonSelectorModalVisible: false as boolean,
    rejectReasonSelectorModalHeader: '' as string,
    rejectReasonSelectorModalText: '' as string,
    availableRejectReasonsForSelector: [] as MODERATION_STATUS[] | APPLICATION_REJECT_REASON[],
    rejectReasonSelectorSelectedCallback: null as Function | null,
  }),

  actions: {
    async setupRejectReasonSelectorModal(availableRejectReasonsForSelector: MODERATION_STATUS[] | APPLICATION_REJECT_REASON[], header: string, text: string, selectedCallback: Function | null) {
      this.availableRejectReasonsForSelector = availableRejectReasonsForSelector;
      this.rejectReasonSelectorModalHeader = header;
      this.rejectReasonSelectorModalText = text;
      this.rejectReasonSelectorSelectedCallback = selectedCallback;
    },
    async showRejectReasonSelectorModal() {
      this.rejectReasonSelectorModalVisible = true;
    },
    async hideRejectReasonSelectorModal() {
      this.rejectReasonSelectorModalVisible = false;
    },
    async setupViolationTypeSelectorModal(availableTypesOfViolationsForSelector: TypeOfViolation[], selectedTypeOfViolationId: string, violationTypeChangeCallback: Function | null) {
      this.selectedTypeOfViolationId = selectedTypeOfViolationId;
      this.availableTypesOfViolationsForSelector = availableTypesOfViolationsForSelector;
      this.violationTypeChangeCallback = violationTypeChangeCallback;
    },
    async showViolationTypeSelectorModal() {
      this.violationTypeSelectorModalVisible = true;
    },
    async hideViolationTypeSelectorModal() {
      this.violationTypeSelectorModalVisible = false;
    },
    async violationTypeSelectorModalSelectTypeId(typeId: string) {
      this.violationTypeChangeCallback(typeId);
      this.violationTypeSelectorModalVisible = false;
    },
    async showInformationCardModal(text: string, title: string = '', image: string = '', closedCallback: Function | null = null) {
      this.informationCardModalImage = image;
      this.informationCardModalTitle = title;
      this.informationCardModalText = text;
      this.informationCardModalVisible = true;
      this.informationCardModalClosedCallback = closedCallback;
    },
    async closeInformationCardModal() {
      this.informationCardModalVisible = false;
      this.informationCardModalImage = '';
      this.informationCardModalText = '';
      this.informationCardModalTitle = '';
      if (this.informationCardModalClosedCallback) {
        this.informationCardModalClosedCallback();
        this.informationCardModalClosedCallback = null;
      }
    },
    async toggleLocaleDropdown() {
      this.isLocaleDropdownOpen = !this.isLocaleDropdownOpen;
    },
    async setLocaleDropdown(value: boolean) {
      this.isLocaleDropdownOpen = value;
    },
    async toggleMapFilterModal() {
      this.isMapFilterModalOpen = !this.isMapFilterModalOpen;
    },
    async setMapFilterModal(value: boolean) {
      this.isMapFilterModalOpen = value;
    },
    async toggleSideNav() {
      this.isSideNavOpen = !this.isSideNavOpen;
    },
    async setSideNavOpen(value: boolean) {
      this.isSideNavOpen = value;
    },
    async setIsFooterVisible(value: boolean) {
      this.isFooterVisible = value;
    },
    async setHunterSpinnerVisible(value: boolean) {
      this.huntingSpinnerVisible = value;
    }
  },
});
