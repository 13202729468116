<template>
  <a class="google" :href="OAUTH2_GOOGLE_LOGIN_PATH">
    <div class="img">
      <img :src='img'  alt='Sign-in with Google'/>
    </div>
    <div class="prompt">{{$t('auth.googleButtonText')}}</div>
  </a>
</template>

<script>
import { defineComponent } from "vue";
import img from '@/ui/assets/images/btn_google_light_normal.svg';
import { OAUTH2_GOOGLE_LOGIN_PATH } from '@/helpers/constants/routes'
export default defineComponent({
  name: "SignInGoogleButton",
  data() {
    return {
      img: img,
      OAUTH2_GOOGLE_LOGIN_PATH
    };
  }
});
</script>

<style lang="scss">
a.google {
  height: 40px;
  width: 200px;
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 1px 1px 4px 1px #00000020;
  &:active {
    background-color: #4285F4;
    .img {
        background-image: url('../assets/images/btn_google_light_pressed.svg');
        img {
            opacity: 0;
        }
    }
    .prompt {
        color: #FFFFFF;
    }
  }
  .img {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 34pt;
      margin: 0 0;
    }
  }
  .prompt {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8pt 8pt 8pt 8pt;
    white-space: nowrap;
  }
}
</style>
