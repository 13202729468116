<template>
  <div class='filter-wrapper hstack gap-2'>
    <MDBModal
        v-model="mapFilterModalOpen"
        direction="left"
        position="bottom-left"
        side
        tabindex="-1"
    >
      <MDBModalHeader>
        <MDBModalTitle>
          <div class='hstack gap-2'>
            {{ $t('filter.violationFilter') }}
            <MDBSpinner v-if='loadingInProcess' grow/>
          </div>
        </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class='vstack gap-3'>
        <MDBInput
            v-model="searchFilter"
            :label="$t('filter.searchByCarPlate')"
        />
        <!--
          Warning about the use of a non-free component. Attention! The MDBDatepicker component is provided under the
          "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source
          software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license
          from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code
          to eliminate the use of non-free components.
        -->
        <MDBDatepicker
            v-model='startDate'
            :cancelBtnLabel="$t('mdb.datePicker.cancelBtnLabel')"
            :cancelBtnText="$t('mdb.datePicker.cancelBtnText')"
            :clearBtnLabel="$t('mdb.datePicker.clearBtnLabel')"
            :clearBtnText="$t('mdb.datePicker.clearBtnText')"
            :format="$t('mdb.datePicker.format')"
            :label="$t('filter.startDate')"
            :monthsFull="[$t('mdb.datePicker.monthsFull.0'), $t('mdb.datePicker.monthsFull.1'), $t('mdb.datePicker.monthsFull.2'), $t('mdb.datePicker.monthsFull.3'), $t('mdb.datePicker.monthsFull.4'), $t('mdb.datePicker.monthsFull.5'), $t('mdb.datePicker.monthsFull.6'), $t('mdb.datePicker.monthsFull.7'), $t('mdb.datePicker.monthsFull.8'), $t('mdb.datePicker.monthsFull.9'), $t('mdb.datePicker.monthsFull.10'), $t('mdb.datePicker.monthsFull.11')]"
            :monthsShort="[$t('mdb.datePicker.monthsShort.0'), $t('mdb.datePicker.monthsShort.1'), $t('mdb.datePicker.monthsShort.2'), $t('mdb.datePicker.monthsShort.3'), $t('mdb.datePicker.monthsShort.4'), $t('mdb.datePicker.monthsShort.5'), $t('mdb.datePicker.monthsShort.6'), $t('mdb.datePicker.monthsShort.7'), $t('mdb.datePicker.monthsShort.8'), $t('mdb.datePicker.monthsShort.9'), $t('mdb.datePicker.monthsShort.10'), $t('mdb.datePicker.monthsShort.11')]"
            :startDay="Number($t('mdb.datePicker.startDay'))"
            :title="$t('mdb.datePicker.title')"
            :weekdaysFull="[$t('mdb.datePicker.weekdaysFull.0'), $t('mdb.datePicker.weekdaysFull.1'), $t('mdb.datePicker.weekdaysFull.2'), $t('mdb.datePicker.weekdaysFull.3'), $t('mdb.datePicker.weekdaysFull.4'), $t('mdb.datePicker.weekdaysFull.5'), $t('mdb.datePicker.weekdaysFull.6')]"
            :weekdaysNarrow="[$t('mdb.datePicker.weekdaysNarrow.0'), $t('mdb.datePicker.weekdaysNarrow.1'), $t('mdb.datePicker.weekdaysNarrow.2'), $t('mdb.datePicker.weekdaysNarrow.3'), $t('mdb.datePicker.weekdaysNarrow.4'), $t('mdb.datePicker.weekdaysNarrow.5'), $t('mdb.datePicker.weekdaysNarrow.6')]"
            :weekdaysShort="[$t('mdb.datePicker.weekdaysShort.0'), $t('mdb.datePicker.weekdaysShort.1'), $t('mdb.datePicker.weekdaysShort.2'), $t('mdb.datePicker.weekdaysShort.3'), $t('mdb.datePicker.weekdaysShort.4'), $t('mdb.datePicker.weekdaysShort.5'), $t('mdb.datePicker.weekdaysShort.6')]"
            disableFuture
            inputToggle
        />
        <!--
          Warning about the use of a non-free component. Attention! The MDBDatepicker component is provided under the
          "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source
          software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license
          from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code
          to eliminate the use of non-free components.
        -->
        <MDBDatepicker
            v-model='endDate'
            :cancelBtnLabel="$t('mdb.datePicker.cancelBtnLabel')"
            :cancelBtnText="$t('mdb.datePicker.cancelBtnText')"
            :clearBtnLabel="$t('mdb.datePicker.clearBtnLabel')"
            :clearBtnText="$t('mdb.datePicker.clearBtnText')"
            :format="$t('mdb.datePicker.format')"
            :label="$t('filter.endDate')"
            :monthsFull="[$t('mdb.datePicker.monthsFull.0'), $t('mdb.datePicker.monthsFull.1'), $t('mdb.datePicker.monthsFull.2'), $t('mdb.datePicker.monthsFull.3'), $t('mdb.datePicker.monthsFull.4'), $t('mdb.datePicker.monthsFull.5'), $t('mdb.datePicker.monthsFull.6'), $t('mdb.datePicker.monthsFull.7'), $t('mdb.datePicker.monthsFull.8'), $t('mdb.datePicker.monthsFull.9'), $t('mdb.datePicker.monthsFull.10'), $t('mdb.datePicker.monthsFull.11')]"
            :monthsShort="[$t('mdb.datePicker.monthsShort.0'), $t('mdb.datePicker.monthsShort.1'), $t('mdb.datePicker.monthsShort.2'), $t('mdb.datePicker.monthsShort.3'), $t('mdb.datePicker.monthsShort.4'), $t('mdb.datePicker.monthsShort.5'), $t('mdb.datePicker.monthsShort.6'), $t('mdb.datePicker.monthsShort.7'), $t('mdb.datePicker.monthsShort.8'), $t('mdb.datePicker.monthsShort.9'), $t('mdb.datePicker.monthsShort.10'), $t('mdb.datePicker.monthsShort.11')]"
            :startDay="Number($t('mdb.datePicker.startDay'))"
            :title="$t('mdb.datePicker.title')"
            :weekdaysFull="[$t('mdb.datePicker.weekdaysFull.0'), $t('mdb.datePicker.weekdaysFull.1'), $t('mdb.datePicker.weekdaysFull.2'), $t('mdb.datePicker.weekdaysFull.3'), $t('mdb.datePicker.weekdaysFull.4'), $t('mdb.datePicker.weekdaysFull.5'), $t('mdb.datePicker.weekdaysFull.6')]"
            :weekdaysNarrow="[$t('mdb.datePicker.weekdaysNarrow.0'), $t('mdb.datePicker.weekdaysNarrow.1'), $t('mdb.datePicker.weekdaysNarrow.2'), $t('mdb.datePicker.weekdaysNarrow.3'), $t('mdb.datePicker.weekdaysNarrow.4'), $t('mdb.datePicker.weekdaysNarrow.5'), $t('mdb.datePicker.weekdaysNarrow.6')]"
            :weekdaysShort="[$t('mdb.datePicker.weekdaysShort.0'), $t('mdb.datePicker.weekdaysShort.1'), $t('mdb.datePicker.weekdaysShort.2'), $t('mdb.datePicker.weekdaysShort.3'), $t('mdb.datePicker.weekdaysShort.4'), $t('mdb.datePicker.weekdaysShort.5'), $t('mdb.datePicker.weekdaysShort.6')]"
            disableFuture
            inputToggle
        />
        <!--
          Warning about the use of a non-free component. Attention! The MDBSelect component is provided under the
          "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source
          software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license
          from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code
          to eliminate the use of non-free components.
        -->
        <MDBSelect
            ref="typeOfViolationSelect"
            v-model:options="allTypesOfViolationsForSelect"
            v-model:selected="violationTypesSelected"
            :displayedLabels="1"
            :filter="allTypesOfViolationsForSelect.length > 6"
            :label="$t('filter.violationTypes')"
            :noResultsText="$t('filter.noResultsText')"
            :optionsSelectedLabel="$t('filter.optionsSelectedLabel')"
            :placeholder='$t("filter.selectViolationTypes")'
            :searchPlaceholder="$t('filter.searchPlaceholder')"
            :selectAllLabel="$t('filter.selectAllLabel')"
            :visibleOptions="6"
            multiple
        />
        <MDBSwitch
            v-if='authStore.isAuthenticated'
            v-model="onlyMyCatchFilter"
            :label="$t('filter.onlyMyCatch')"
        />
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn class='me-auto' color="danger" @click='reset'>
          {{ $t('filter.reset') }}
        </MDBBtn>
        <MDBBtn color="primary" @click="mapFilterModalOpen = false">
          {{ $t('filter.close') }}
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <div>
      <MDBBtn
          class="btn btn-secondary btn-lg btn-floating"
          type="button"
          @click="ifaceStore.toggleMapFilterModal"
      >
        <MDBSpinner v-if='loadingInProcess' grow size="sm" tag="span"/>
        <MDBIcon v-else class="fas fa-search"/>
      </MDBBtn>
      <MDBBadge
          v-if='filterCount > 0'
          class="translate-middle p-1 ps-2 pe-2"
          color="danger"
          notification
          pill
      >
        {{ filterCount }}
      </MDBBadge>
    </div>
  </div>
</template>
<script lang="ts">
import { useIfaceStore } from '@/store/iface';
import { useMapStore } from '@/store/map';
import {
  MDBBadge,
  MDBBtn,
  MDBDatepicker,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSelect,
  MDBSpinner,
  MDBSwitch
} from 'mdb-vue-ui-kit';
import { TypeOfViolationForSelect } from '@/store/models/type-of-violation.model';
import { useAuthStore } from '@/store/auth';
import { debounce } from 'lodash';
import { useViolationTypesStore } from '@/store/violation-types'
import { defineComponent, ref, watch } from 'vue'


export default defineComponent({
  name: "MapFilterContainer",
  components: {
    MDBBadge,
    MDBSpinner,
    MDBIcon,
    MDBBtn,
    MDBModal,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBDatepicker,
    MDBSelect,
    MDBInput,
    MDBSwitch
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const mapStore = useMapStore();
    const violationTypesStore = useViolationTypesStore();
    const authStore = useAuthStore();
    const temporarySearchFilter = ref('');
    const typeOfViolationSelect = ref(null);
    const loadingInProcess = ref(mapStore.loadingInProcess);

    watch(() => mapStore.searchFilter, (value) => {
      temporarySearchFilter.value = value;
    });

    watch(() => mapStore.loadingInProcess, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        if (newVal) {
          loadingInProcess.value = true;
        } else {
          setTimeout(() => {
            if (!mapStore.loadingInProcess) {
              loadingInProcess.value = false;
            }
          }, 1500);
        }
      }
    });

    watch(() => mapStore.filterViolationTypeIds, (newVal, oldVal) => {
      if (newVal.length === 0 && oldVal.length > 0) {
        typeOfViolationSelect.value.clear();
      }
    });

    const delayedSetSearchFilter = debounce(function (value: string) {
      mapStore.setSearchFilter(value);
    }, 500);

    return {
      ifaceStore,
      mapStore,
      violationTypesStore,
      authStore,
      temporarySearchFilter,
      typeOfViolationSelect,
      loadingInProcess,
      delayedSetSearchFilter,
    }
  },
  methods: {
    reset() {
      this.mapStore.resetFilters();
      this.violationTypesSelected = "";
      this.typeOfViolationSelect.clear();
      this.mapFilterModalOpen = false;
    },
  },
  computed: {
    filterCount(): number {
      let count = 0;
      if (this.mapStore.filterStartDate) {
        count++;
      }
      if (this.mapStore.filterEndDate) {
        count++;
      }
      if (this.mapStore.filterViolationTypeIds.length) {
        count++;
      }
      if (this.mapStore.onlyMyCatchFilter) {
        count++;
      }
      if (this.mapStore.searchFilter) {
        count++;
      }
      return count;
    },
    mapFilterModalOpen: {
      get(): boolean {
        return this.ifaceStore.isMapFilterModalOpen;
      },
      set(value: boolean) {
        this.ifaceStore.setMapFilterModal(value);
      },
    },
    onlyMyCatchFilter: {
      get(): boolean {
        return this.mapStore.onlyMyCatchFilter;
      },
      set(value: boolean) {
        this.mapStore.setOnlyMyCatchFilter(value);
      },
    },
    searchFilter: {
      get(): string {
        return this.temporarySearchFilter;
      },
      set(value: string) {
        this.temporarySearchFilter = value;
        this.delayedSetSearchFilter(value);
      },
    },
    startDate: {
      get(): string | null {
        return this.mapStore.filterStartDate;
      },
      set(value: string | null) {
        this.mapStore.setFilterStartDate(value);
      },
    },
    endDate: {
      get(): string | null {
        return this.mapStore.filterEndDate;
      },
      set(value: string | null) {
        this.mapStore.setFilterEndDate(value);
      },
    },
    violationTypesSelected: {
      get(): string {
        return this.mapStore.filterViolationTypeIds.join(',');
      },
      set(value: string) {
        const idsArr = value ? value.split(',') : [];
        this.mapStore.setFilterViolationTypeIds(idsArr);
      },
    },
    allTypesOfViolationsForSelect: {
      get(): TypeOfViolationForSelect[] {
        return this.violationTypesStore.allTypesOfViolationsForSelect;
      },
      set(_: TypeOfViolationForSelect[]) {
        return
      },
    },
  }
});
</script>
<style lang="scss" scoped>
.filter-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 210;
}
</style>
