<template>
  <MDBBtn class="vk-button d-flex align-items-center justify-content-center" tag="a" color="primary" :href="OAUTH2_VK_LOGIN_PATH">
    <MDBIcon iconStyle="fab" icon="vk" size="lg"/>
    <span class="prompt">{{ $t("auth.vkButtonText") }}</span>
  </MDBBtn>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MDBIcon, MDBBtn } from "mdb-vue-ui-kit";
import { OAUTH2_VK_LOGIN_PATH } from '@/helpers/constants/routes'

export default defineComponent({
  name: "SignInVKButton",
  components: { MDBIcon, MDBBtn },
  data() {
    return {
      OAUTH2_VK_LOGIN_PATH,
    };
  },
});
</script>
<style scoped lang="scss">
.vk-button {
  background-color: rgb(76, 117, 163);
  width: 200px;
  height: 40px;
}
.prompt {
  font-size: 14px;
  color: #FFFFFF;
  padding: 8pt 8pt 8pt 8pt;
  white-space: nowrap;
}
</style>
