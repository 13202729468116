// src/store/map.ts
// Module for map state.
import { v4 as uuidv4 } from 'uuid'
import { defineStore } from 'pinia'
import { convertCoordinates } from '@/helpers/functions'
import { ViolationDetails } from '@/store/models/violation.model'
import { mapApiViolationForMapToStoreViolationForMap } from '@/helpers/mappers/violation.mapper'
import { mapModule } from '@/api/map'
import { VIOLATIONS_ENDPOINT_FOR_MAP, CARPARKS_ENDPOINT_FOR_MAP } from '@/helpers/constants/api'
import { PAGE_SIZE_FOR_VIOLATIONS } from '@/helpers/constants/application'
import { useAppStore } from '@/store/app'

export const useMapStore = defineStore('map', {
  state: () => ({
    violationForDetails: null as ViolationDetails | null,
    violationForDetailsLoading: false as boolean,
    loadingInProcess: false as boolean,
    mapUrl: VIOLATIONS_ENDPOINT_FOR_MAP,
    carParksUrl: CARPARKS_ENDPOINT_FOR_MAP,
    filterStartDate: null as string | null,
    filterEndDate: null as string | null,
    filterViolationTypeIds: [] as string[],
    searchFilter: null as string | null,
    onlyMyCatchFilter: false as boolean,
    mapZoom: 12 as number,
    // TODO: get from user location
    mapCenter: convertCoordinates(47.791846, 52.005431) as [number, number], // Use coordinates in EPSG:3857 format or convertCoordinates() function to convert from EPSG:4326 (lat, lng) format
    mapExtent4326: [0, 0, 180, 180] as [number, number, number, number],
    tileLayerUrl: "https://tile3.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1",
  }),

  actions: {
    async setTileLayerUrl(url: string) {
        this.tileLayerUrl = url;
    },
    async resetState() {
      this.violationForDetails = null;
      this.violationForDetailsLoading = false;
      this.loadingInProcess = false;
      await this.resetFilters();
      const url = this.mapUrl
      const carParksUrl = this.carParksUrl
      this.mapUrl = url+`&reset_beacon=${Math.random()}`;
      this.carParksUrl = carParksUrl+`&reset_beacon=${Math.random()}`;
      setTimeout(() => {
        this.mapUrl = url;
        this.carParksUrl = carParksUrl;
      }, 250);
    },

    async updateUrl() {
      let url = VIOLATIONS_ENDPOINT_FOR_MAP
      let carParksUrl = CARPARKS_ENDPOINT_FOR_MAP
      let delimiter = '?';
      let carParksUrlDelimiter = '?';
      if (this.filterStartDate) {
        url += `${delimiter}created_at_range_after=${this.filterStartDate}`;
        delimiter = '&';
      }
      if (this.filterEndDate) {
        url += `${delimiter}created_at_range_before=${this.filterEndDate}`;
        delimiter = '&';
      }
      if (this.searchFilter) {
        url += `${delimiter}search=${this.searchFilter}`;
        delimiter = '&';
      }
      if (this.filterViolationTypeIds) {
        this.filterViolationTypeIds.forEach((type) => {
          url += `${delimiter}violation_type=${type}`;
          delimiter = '&';
        });
      }
      if (this.mapExtent4326) {
        url += `${delimiter}in_bbox=${this.mapExtent4326.slice(0, 2).join(',')},${this.mapExtent4326.slice(2).join(',')}`;
        carParksUrl += `${carParksUrlDelimiter}in_bbox=${this.mapExtent4326.slice(0, 2).join(',')},${this.mapExtent4326.slice(2).join(',')}`;
        delimiter = '&';
        carParksUrlDelimiter = '&';
      }
      if (this.onlyMyCatchFilter === true) {
        url += `${delimiter}is_author=True`;
        delimiter = '&';
      }
      url += `${delimiter}page_size=${PAGE_SIZE_FOR_VIOLATIONS}`;
      this.mapUrl = `${url}&beacon=${Math.random()}`
      this.carParksUrl = `${carParksUrl}&beacon=${Math.random()}`
    },

    async loadingStarted() {
        this.loadingInProcess = true;
    },

    async loadingEnded() {
        this.loadingInProcess = false;
    },

    async loadViolationDetails(id: string) {
      this.violationForDetailsLoading = true;
      const appStore = useAppStore();
      try {
        await appStore.waitForBackendAvailable();
        const response = await mapModule.getViolation(id);
        if (response?.status === 200) {
          this.setViolationDetails({ ...mapApiViolationForMapToStoreViolationForMap(response.data), isNotFound: false });
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке деталей нарушения: ${error}`);
        if (error.response?.status === 404) {
          this.setViolationDetails({ isNotFound: true });
        } else if (error.response?.status >= 500 || error.response?.status === 408) {
          setTimeout(() => {
            this.loadViolationDetails(id);
          }, 3000);
        } else {
          this.setViolationDetails(null);
        }
      } finally {
        this.violationForDetailsLoading = false;
      }
    },

    async setViolationDetails(violation: ViolationDetails | null) {
      this.violationForDetails = violation;
    },

    async setFilterStartDate(date: string | null) {
      this.filterStartDate = date;
      await this.updateUrl();
    },
    async setFilterEndDate(date: string | null) {
      this.filterEndDate = date;
      await this.updateUrl();
    },
    async setFilterViolationTypeIds(typeIds: string[]) {
      this.filterViolationTypeIds = typeIds;
    },
    async setSearchFilter(value: string | null) {
      this.searchFilter = value != "" ? value : null;
      await this.updateUrl();
    },
    async resetFilters() {
      this.filterStartDate = null;
      this.filterEndDate = null;
      this.filterViolationTypeIds = [];
      this.searchFilter = null;
      this.onlyMyCatchFilter = false;
      await this.updateUrl();
    },
    async setOnlyMyCatchFilter(value: boolean) {
      this.onlyMyCatchFilter = value;
      await this.updateUrl();
    },
    async setMapZoom(value: number) {
      this.mapZoom = value;
      await this.updateUrl();
    },
    async setMapCenter(value: [number, number]) {
      this.mapCenter = value;
      await this.updateUrl();
    },
    async setMapExtent4326(value: [number, number, number, number]) {
      this.mapExtent4326 = value;
      await this.updateUrl();
    },
  },
});
