<template>
  <div class="hunt-content">
    <PlaceInformationModalContainer />
    <MaintenanceComponent v-if='!publicPartAvailable' :message='appStore.siteState.messageForPublic'/>
    <MDBRow class="top-buttons m-0 mt-3" v-if='publicPartAvailable'>
      <GeoPhotoProcessingStatusAlertContainer v-if='huntingStore.photoCheckingMode'/>
      <MDBCol class="d-flex flex-row p-0 ms-3 hunting-vt-selector-col" v-if='!huntingStore.photoCheckingMode'>
        <!--
          Warning about the use of a non-free component. Attention! The MDBSelect component is provided under the
          "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source
          software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license
          from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code
          to eliminate the use of non-free components.
        -->
        <MDBSelect
          size="lg"
          v-model:options="violationTypesStore.availableTypesOfViolationsForSelect"
          v-model:selected="selectedTypeOfViolationId"
          :label="huntingStore.photoCheckingMode? '' : $t('filter.violationTypes')"
          :placeholder='$t("filter.selectViolationTypes")'
          :visibleOptions="7"
          :optionHeight="42"
        />
      </MDBCol>
      <MDBCol class="d-flex flex-row-reverse p-0 me-3" v-if='!huntingStore.photoCheckingMode'>
        <MDBBtn color="secondary" floating size="lg" class="close-btn" @click='$router.push({ name: HUNTING_ROUTE_NAME })'>
          <MDBIcon iconStyle="fas" size="lg" icon="times"></MDBIcon>
        </MDBBtn>
      </MDBCol>
    </MDBRow>
    <CameraWrapperContainer v-if='publicPartAvailable'/>
  </div>
  <!--
    Warning about the use of a non-free component. Attention! The MDBLoading component is provided under the
    "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source software.
    To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license from the
    vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code to eliminate
    the use of non-free components.
  -->
  <MDBLoading
    v-if='publicPartAvailable'
    v-model="loading"
    :fullScreen='true'
    :loading-text="$t('hunting.loading')"
    spinnerClasses="text-light"
    textClasses="text-light"
    backdropColor="#7e643d"
    :backdropOpacity="0.33"
  />
</template>
<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { MDBBtn, MDBCol, MDBIcon, MDBLoading, MDBRow, MDBSelect } from 'mdb-vue-ui-kit'
import { HUNTING_ROUTE_NAME } from '@/helpers/constants/routes'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import { OPERATION_MODE } from '@/helpers/enums/operation-mode'
import { useRouter } from 'vue-router'
import { useViolationTypesStore } from '@/store/violation-types'
import { useHuntingStore } from '@/store/hunting'
import MaintenanceComponent from '@/ui/components/MaintenanceComponent.vue'
import PlaceInformationModalContainer from '@/ui/containers/PlaceInformationModalContainer.vue'
import GeoPhotoProcessingStatusAlertContainer from '@/ui/containers/GeoPhotoProcessingStatusAlertContainer.vue'
import CameraWrapperContainer from '@/ui/containers/CameraWrapperContainer.vue'
import { useIfaceStore } from '@/store/iface'


export default defineComponent({
  setup() {
    const huntingStore = useHuntingStore();
    const violationTypesStore = useViolationTypesStore();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const ifaceStore = useIfaceStore();
    const router = useRouter();
    const loading = ref(false);

    watch(() => ifaceStore.huntingSpinnerVisible, (newValue) => {
      loading.value = newValue;
    });

    const publicPartAvailable = ref(appStore.siteState?.publicMode === OPERATION_MODE.MODE_NORMAL || authStore.user?.isSuperuser);
    watch(() => appStore.siteState, (newValue) => {
      if (newValue) {
        publicPartAvailable.value = newValue.publicMode === OPERATION_MODE.MODE_NORMAL || authStore.user?.isSuperuser;
      }
    });
    watch(() => authStore.user, (newValue) => {
      if (newValue) {
        publicPartAvailable.value = appStore.siteState.publicMode === OPERATION_MODE.MODE_NORMAL || newValue?.isSuperuser;
      }
    });

    onMounted(() => {
      appStore.startLocationTracking();
      violationTypesStore.updateAvailableTypesOfViolationsInLocation();
    });

    onUnmounted(() => {
      appStore.stopLocationTracking();
    });

    watch(() => authStore.isInitialized, () => {
      if (!authStore.isAuthenticated) {
        router.push({ name: "map" });
      }
    });
    watch(() => authStore.user, (user) => {
      if (user && user.isBanned) {
        router.push({ name: "profile" });
      }
    });

    return {
      appStore,
      publicPartAvailable,
      loading,
      huntingStore,
      violationTypesStore,
    }
  },
  name: "HuntView",
  components: {
    MDBIcon,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBSelect,
    MDBLoading,
    MaintenanceComponent,
    PlaceInformationModalContainer,
    GeoPhotoProcessingStatusAlertContainer,
    CameraWrapperContainer,
  },
  data() {
    return {
      HUNTING_ROUTE_NAME,
    };
  },
  computed: {
    selectedTypeOfViolationId: {
      get(): string {
        return this.violationTypesStore.selectedTypeOfViolation?.id;
      },
      set(value: string) {
        this.violationTypesStore.setSelectedTypeOfViolationById(value);
      },
    },
  }
});
</script>
<style scoped lang="scss">
.hunt-content {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;

  .top-buttons {
    position: absolute;
    width: 100%;
    right: 0;
    z-index: 100;
  }
}
</style>