<template>
  <MDBCard>
    <MDBCardBody class="d-flex justify-content-start align-items-center">
      <div class="p-3 badge-primary rounded-4">
        <MDBIcon :icon="icon" size="lg" class="fa-fw"/>
      </div>
      <div class="flex-grow-1 ms-4">
        <p class="text-muted mb-1">{{ statsHeaderText }}</p>
        <div class="mb-0 hstack gap-2" v-if='statsLoaded'>
          <h2>{{ statsValue }}</h2>
          <span v-if='percentageChange != 0' :class="percentageChange * factor > 0 ? 'text-success' : 'text-danger' + ' percentage-block'">
            <MDBIcon size="sm" :icon="percentageChange > 0 ? 'arrow-up' : 'arrow-down'"/>
            <span class="percentage-value">
              <span> {{ percentageChange }}%</span>
            </span>
          </span>
        </div>
        <h2 class="mb-0 placeholder-glow" v-else>
          <span class="placeholder col-1"></span>
          <span class="text-success percentage-block">
            <MDBIcon icon="arrow-up" size="sm"/>
            <span class="percentage-value">
              <span class="placeholder col-1"></span>
            </span>
          </span>
        </h2>
      </div>
    </MDBCardBody>
  </MDBCard>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MDBCard, MDBCardBody, MDBIcon } from 'mdb-vue-ui-kit'

export default defineComponent({
  name: "HuntingStatsCardComponent",
  components: { MDBCard, MDBIcon, MDBCardBody },
  computed: {
    factor(): number {
      return this.isPositive ? 1 : -1;
    }
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    statsHeaderText: {
      type: String,
      required: true
    },
    statsLoaded: {
      type: Boolean,
      required: true
    },
    statsValue: {
      type: Number,
      required: true
    },
    percentageChange: {
      type: Number,
      required: true
    },
    isPositive: {
      type: Boolean,
      required: true
    }
  }
});
</script>
<style scoped lang="scss">
.percentage {
  font-size: 0.875rem;
  font-weight: 500;
}
.percentage-block {
  font-size: 0.875rem;
}
</style>
