const profile = {
  header: "Profile",
  hunter: "Hunter",
  exit: "Exit",
  oops: "Oops...",
  underConstructionText: "This section is still under construction... But we will fix it soon!",
  blocked: "You have been blocked. To clarify the reason for the lock, contact the administrator.",
  youHaveModeratorRights: "You have moderator rights, you can open the moderator console by clicking on the button below.",
  write: "Write",
  console: "Console",
  hello: "Hello",
};

export default profile;
