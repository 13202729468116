const mdb = {
  datePicker: {
    startDay: "0", // 0 - Sunday, 1 - Monday
    cancelBtnLabel: 'Cancel selection',
    cancelBtnText: 'Cancel',
    clearBtnLabel: 'Clear selection',
    clearBtnText: 'Clear',
    format: 'DD/MM/YYYY',
    monthsFull: {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    },
    monthsShort: {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    },
    nextMonthLabel: 'Next month',
    nextMultiYearLabel: 'Next 24 years',
    nextYearLabel: 'Next year',
    okBtnLabel: 'Confirm selection',
    okBtnText: 'Ok',
    prevMonthLabel: 'Previous month',
    prevMultiYearLabel: 'Previous 24 years',
    prevYearLabel: 'Previous year',
    switchToDayViewLabel: 'Choose date',
    switchToMultiYearViewLabel: 'Choose month and year',
    title: 'Select date',
    weekdaysFull: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday ',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    },
    weekdaysNarrow: {0: 'S', 1: 'M', 2: 'T', 3: 'W', 4: 'T', 5: 'F', 6: 'S'},
    weekdaysShort: {0: 'Sun', 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thu', 5: 'Fri', 6: 'Sat'},
  }
};

export default mdb;
