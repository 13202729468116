<template>
  <div class="content">
    <div class="stage-container" v-if='stage === APP_STAGE.STAGING'>
      <MDBPopover v-model="stagePopover" dismissible direction="bottom">
        <template #reference>
          <a class="btn btn-dark btn-rounded" tabindex="0" @click="stagePopover = !stagePopover" data-mdb-ripple-init  data-mdb-ripple-color="dark">
            <i class="fas fa-exclamation-triangle me-2 ms-0"/>
            {{ $t(`application.stageButtonText.${stage}`) }}
          </a>
        </template>
        <template #header>
          {{ $t(`application.stageButtonText.${stage}`) }}
        </template>
        <template #body>
          {{ $t(`application.stagePopoverText.${stage}`) }}
        </template>
      </MDBPopover>
    </div>
    <div v-if='$route.name === HOME_ROUTE_NAME' class="content">
      <MaintenanceComponent v-if='!publicPartAvailable' :message='appStore.siteState.messageForPublic'/>
      <MapContainer v-else />
    </div>
    <div v-if='$route.name === HUNTING_ROUTE_NAME' class="content">
      <MaintenanceComponent v-if='!publicPartAvailable' :message='appStore.siteState.messageForPublic'/>
      <HuntingContainer v-else />
    </div>
    <ManifestoContainer v-if='$route.name === MANIFESTO_ROUTE_NAME' />
    <SignInContainer v-if='$route.name === SIGN_IN_ROUTE_NAME' />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth'
import { APP_STAGE } from '@/helpers/enums/app-stage'
import { OPERATION_MODE } from '@/helpers/enums/operation-mode'
import {MDBPopover} from 'mdb-vue-ui-kit'
import SignInContainer from '@/ui/containers/SignInContainer.vue'
import MapContainer from '@/ui/containers/MapContainer.vue'
import HuntingContainer from '@/ui/containers/HuntingContainer.vue'
import ManifestoContainer from '@/ui/containers/ManifestoContainer.vue'
import MaintenanceComponent from '@/ui/components/MaintenanceComponent.vue'
import {HOME_PATH, HOME_ROUTE_NAME, HUNTING_PATH, HUNTING_ROUTE_NAME, MANIFESTO_PATH, MANIFESTO_ROUTE_NAME, SIGN_IN_PATH, SIGN_IN_ROUTE_NAME} from '@/helpers/constants/routes'

export default defineComponent({
  props: {
    violation: {
      type: String,
      required: false,
    },
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    const stagePopover = ref(false);
    const stage = ref(APP_STAGE.PROD);

    const publicPartAvailable = ref(appStore.siteState?.publicMode === OPERATION_MODE.MODE_NORMAL || (authStore.isAuthenticated && authStore.user?.isSuperuser));

    watch(() => appStore.siteState, (newValue) => {
      if (newValue) {
        publicPartAvailable.value = newValue.publicMode === OPERATION_MODE.MODE_NORMAL || authStore.user?.isSuperuser;
        stage.value = newValue.stage;
      }
    });

    watch(() => authStore.user, (newValue) => {
      if (newValue) {
        publicPartAvailable.value = appStore.siteState.publicMode === OPERATION_MODE.MODE_NORMAL || newValue?.isSuperuser;
      }
    });

    return {
      stage,
      appStore,
      publicPartAvailable,
      stagePopover,
    }
  },
  name: "HomeView",
  components: {
    MapContainer,
    SignInContainer,
    HuntingContainer,
    ManifestoContainer,
    MaintenanceComponent,
    MDBPopover
  },
  data() {
    return {
      HOME_PATH,
      HOME_ROUTE_NAME,
      HUNTING_PATH,
      HUNTING_ROUTE_NAME,
      MANIFESTO_PATH,
      MANIFESTO_ROUTE_NAME,
      SIGN_IN_PATH,
      SIGN_IN_ROUTE_NAME,
      APP_STAGE,
    };
  },
});
</script>
<style scoped lang="scss">
.content {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
}
.stage-container {
  position: absolute;
  top: 100px;
  right: 10px;
  z-index: 2000;
  .btn {
    font-size: 0.8rem;
  }
}
</style>
