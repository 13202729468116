import type { FrontendUserSettings as ApiSettings } from "@/api/models/frontend-user-settings.model";
import type { FrontendUserSettings as StoreSettings } from "@/store/models/frontend-user-settings.model";

export function mapApiFrontendUserSettingsToStoreFrontendUserSettings(apiSettings: ApiSettings): StoreSettings {
  return {
    id: apiSettings.id,
    modifiedAt: apiSettings.modified_at ? new Date(apiSettings.modified_at) : null,
    createdAt: new Date(apiSettings.created_at),
    showInfoMessages: apiSettings.show_info_messages,
    showWarningMessages: apiSettings.show_warning_messages,
    showErrorMessages: apiSettings.show_error_messages,
    showDebugMessages: apiSettings.show_debug_messages,
  };
}

export function mapStoreFrontendUserSettingsToApiFrontendUserSettings(storeSettings: StoreSettings): ApiSettings {
  return {
    id: storeSettings.id,
    modified_at: storeSettings.modifiedAt ? storeSettings.modifiedAt.toISOString() : null,
    created_at: storeSettings.createdAt.toISOString(),
    show_info_messages: storeSettings.showInfoMessages,
    show_warning_messages: storeSettings.showWarningMessages,
    show_error_messages: storeSettings.showErrorMessages,
    show_debug_messages: storeSettings.showDebugMessages
  };
}
