// src/api/application.ts is a module that exports functions that call the API endpoints related to application, like user settings.
import api from "./api";
import type { FrontendUserSettings } from "@/api/models/frontend-user-settings.model";
import type { SiteState } from '@/api/models/site-state.model'
import type { AxiosResponse } from "axios";
import { USER_APP_SETTINGS_ENDPOINT, SITE_STATE_ENDPOINT } from "@/helpers/constants/api";

export const applicationModule = {
  async getFrontendUserSettings(): Promise<AxiosResponse<FrontendUserSettings>> {
    return await api.get(USER_APP_SETTINGS_ENDPOINT, {headers: {"Content-Type": "application/json",},});
  },
  async getSiteState(): Promise<AxiosResponse<SiteState>> {
    return await api.get(SITE_STATE_ENDPOINT, {headers: {"Content-Type": "application/json",},});
  },
};
