<template>
  <!-- Dropdown for change interface locale. -->
  <MDBDropdown btnGroup v-model="isMenuOpened">
    <MDBDropdownToggle color="link" @click="toggleLocaleDropdown" size="sm" >
      <MDBIcon :flag="getFlag(currentLocale)" class="m-2" size="sm" />
      {{ getLocalLanguage(currentLocale) }}
    </MDBDropdownToggle>
    <MDBDropdownMenu>
      <MDBDropdownItem v-for="locale in availableLocales" :key="locale" tag="button" @click="setCurrentLocale(locale)">
        <MDBRow class="d-flex">
          <MDBCol col="1" class="p-0 ms-2">
            <MDBIcon :flag="getFlag(locale)" />
          </MDBCol>
          <MDBCol class="p-0 ms-3">{{ getLocalLanguage(locale) }}</MDBCol>
          <MDBCol col="1" class="p-0 ms-auto me-2">
            <MDBIcon v-if="currentLocale == locale" icon="check" class="text-success"></MDBIcon>
            <MDBIcon v-else-if="isTranslatedByMachine(locale)" icon="robot" class="text-secondary"></MDBIcon>
          </MDBCol>
        </MDBRow>
      </MDBDropdownItem>
    </MDBDropdownMenu>
  </MDBDropdown>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useLocaleStore } from '@/store/locale'
import { useIfaceStore } from '@/store/iface'
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBRow, MDBCol } from "mdb-vue-ui-kit";

export default defineComponent({
  setup() {
    const localeStore = useLocaleStore();
    const ifaceStore = useIfaceStore();
    return {
      localeStore,
      ifaceStore,
    };
  },
  name: "LocaleSelectorContainer",
  components: { MDBDropdownItem, MDBIcon, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBRow, MDBCol },
  methods: {
    setCurrentLocale(locale: string) {
      this.localeStore.setCurrentLocale(locale);
    },
    toggleLocaleDropdown() {
      this.ifaceStore.toggleLocaleDropdown();
    },
  },
  computed: {
    getFlag(): (locale: string) => string {
      return this.localeStore.getFlag;
    },
    getLocalLanguage(): (locale: string) => string {
      return this.localeStore.getLocalLanguage;
    },
    currentLocale(): string {
      return this.localeStore.currentLocale;
    },
    availableLocales(): string[] {
      return this.localeStore.availableLocales;
    },
    isTranslatedByMachine(): (locale: string) => boolean {
      return this.localeStore.isTranslatedByMachine;
    },
    isLocaleDropdownOpen(): boolean {
      return this.ifaceStore.isLocaleDropdownOpen;
    },
    isMenuOpened: {
      get(): boolean {
        return this.isLocaleDropdownOpen;
      },
      set(value: boolean) {
        this.ifaceStore.setLocaleDropdown(value);
      },
    },
  },
  watch: {
    currentLocale() {
      this.ifaceStore.setLocaleDropdown(false);
      this.$i18n.locale = this.currentLocale;
    },
  },
});
</script>
<style scoped lang="scss"></style>
