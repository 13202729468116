<template>
  <div v-if='locale === LOCALE_RU' class='static-page-container'>
    <h1>Правила сервиса "ХрюХрюКар"</h1>
    <p>Добро пожаловать на сервис "ХрюХрюКар"! Наша цель - сделать города чище, а автомобилистов - ответственнее.</p>

    <h2>Авторизация и регистрация</h2>
    <p>Чтобы пользоваться сервисом, вы можете авторизоваться или зарегистрироваться, используя аккаунт Google или
      ВКонтакте через Oauth2.</p>

    <h2>Роли пользователей</h2>
    <ul>
      <li><strong>Пользователи:</strong> Могут фотографировать автомобили с нарушениями и отправлять их на модерацию.
      </li>
      <li><strong>Модераторы:</strong> Проверяют фотографии на наличие нарушений. Доверенные модераторы имеют право на
        окончательное решение.
      </li>
      <li><strong>Администраторы:</strong> Назначают модераторов, контролируют процесс модерации и принимают
        окончательные решения в случае проблем.
      </li>
    </ul>

    <h2>Модерация фотографий</h2>
    <p>Модераторы проверяют фотографии следующим образом:</p>
    <ol>
      <li>Проверка и подтверждение местоположения и адреса.</li>
      <li>Проверка и подтверждение автомобильного номера.</li>
      <li>Проверка и подтверждение типа нарушения.</li>
      <li>Отправка заявления (если модератор является заявителем).</li>
    </ol>

    <h2>Причины отклонения фотографий</h2>
    <ul>
      <li>Размытость фотографии.</li>
      <li>Наличие лиц на фотографии.</li>
      <li>Отсутствие автомобиля на фотографии.</li>
      <li>Автомобиль не соответствует нарушению.</li>
      <li>Неразборчивый автомобильный номер.</li>
      <li>Международный автомобильный номер.</li>
      <li>Не подтвержден тип нарушения.</li>
      <li>Нарушение уже было зарегистрировано.</li>
      <li>Ошибка геолокации.</li>
      <li>Флуд - отправка большого количества фотографий.</li>
      <li>Адалт контент на фотографии.</li>
      <li>Другие причины.</li>
      <li>Таймаут - время на редактирование фотографии и номера истекло.</li>
    </ul>

    <h2>Публикация нарушений и запрос на удаление</h2>
    <p>Все нарушения после модерации публикуются на карте с информацией о номере автомобиля, дате и типе нарушения, а
      также фотографиями автомобилей.</p>
    <p>Собственник автомобиля может запросить удаление фотографии, оплатив установленный законом штраф в бюджет
      государства.</p>

    <h2>Обратная связь</h2>
    <p>По всем вопросам вы можете обращаться к администрации сервиса через специальные формы, размещенные в подвале
      раздела <a href="/manifesto">манифеста</a>.</p>

    <p><strong>Помните:</strong> Использование сервиса подразумевает согласие с данными правилами.</p>

    <h2>Удаление аккаунта</h2>
    <p>Вы можете удалить свой аккаунт в любой момент, направив нам запрос через форму <a
        :href="URL_OF_FORM_TO_ASK_EVERYTHING" rel="external nofollow noopener" target="_blank">обратной связи</a>.</p>
    <hr class='hr'>
    <p class="lead">Этот материал и все материалы нарушений (фотография нарушения, адрес, местоположение, дата и тип
      нарушения), размещенные на сайте доступны по лицензии Creative Commons Attribution 4.0 International (CC BY 4.0).
      Любое копирование, распространение, отображение и воспроизводство перечисленных материалов
      <mark>допускается</mark>
      при условии указания авторства.
    </p>
    <br><br>
  </div>
  <div v-else class='static-page-container'>
    <h1>Rules of "OinkOinkCar" Service</h1>
    <p>Welcome to the "OinkOinkCar" service! Our goal is to make cities cleaner and motorists more responsible.</p>

    <h2>Authorization and Registration</h2>
    <p>To use the service, you can either log in or register using your Google or VKontakte account via Oauth2.</p>

    <h2>User Roles</h2>
    <ul>
      <li><strong>Users:</strong> Can take photos of vehicles violating parking rules and submit them for moderation.
      </li>
      <li><strong>Moderators:</strong> Review photos for violations. Trusted moderators have the authority for final
        decisions.
      </li>
      <li><strong>Administrators:</strong> Appoint moderators, oversee the moderation process, and make final decisions
        in case of issues.
      </li>
    </ul>

    <h2>Moderation of Photos</h2>
    <p>Moderators review photos in the following manner:</p>
    <ol>
      <li>Verification and confirmation of location and address.</li>
      <li>Verification and confirmation of the vehicle's license plate.</li>
      <li>Verification and confirmation of the violation type.</li>
      <li>Submission of a statement (if the moderator is the applicant).</li>
    </ol>

    <h2>Reasons for Rejection of Photos</h2>
    <ul>
      <li>Blurry photo.</li>
      <li>Presence of faces in the photo.</li>
      <li>Absence of a vehicle in the photo.</li>
      <li>The vehicle does not match the violation.</li>
      <li>Unreadable license plate.</li>
      <li>International license plate.</li>
      <li>Violation type not confirmed.</li>
      <li>Violation already registered.</li>
      <li>Geolocation error.</li>
      <li>Flooding - sending a large number of photos.</li>
      <li>Adult content in the photo.</li>
      <li>Other reasons.</li>
      <li>Timeout - time for editing the photo and license plate has expired.</li>
    </ul>

    <h2>Publication of Violations and Request for Deletion</h2>
    <p>All violations, after moderation, are published on the map with information about the vehicle's license plate,
      date, and type of violation, as well as photos of the vehicles.</p>
    <p>The vehicle owner can request the deletion of the photo by paying the fine specified by law to the state
      budget.</p>

    <h2>Feedback</h2>
    <p>For any questions, you can contact the service administration through special forms located in the footer of the
      <a href="/manifesto">manifesto</a> section.</p>

    <p><strong>Remember:</strong> Using the service implies agreement with these rules.</p>

    <h2>Account Deletion</h2>
    <p>You can delete your account at any time by sending us a request through the <a
        :href="URL_OF_FORM_TO_ASK_EVERYTHING" rel="external nofollow noopener" target="_blank">feedback form</a>.</p>
    <hr class='hr'>
    <p class="lead">This material and all violation materials (violation photo, address, location, date, and type of
      violation) posted on the site are available under the Creative Commons Attribution 4.0 International (CC BY 4.0)
      license.
      Any copying, distribution, display, and reproduction of the listed materials are
      <mark>permitted</mark>
      provided authorship is indicated.
    </p>
    <br><br>
  </div>
</template>
<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {useLocaleStore} from '@/store/locale'
import {LOCALE_RU} from '@/helpers/constants/locale'
import {URL_OF_FORM_TO_ASK_EVERYTHING} from '@/helpers/constants/routes'

export default defineComponent({
  name: "PrivacyView",
  data() {
    return {
      LOCALE_RU,
      URL_OF_FORM_TO_ASK_EVERYTHING
    }
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = ref(localeStore.currentLocale)
    watch(() => localeStore.currentLocale, (newLocale) => {
      locale.value = newLocale;
    });

    return {
      locale,
    };
  },
});
</script>
<style lang="scss" scoped>
.static-page-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 1100px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media (min-width: 1100px) {
  .static-page-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>
