// src/api/auth.ts is a module that exports functions that call the API endpoints related to authentication.
import api from "./api";
import type { AxiosResponse } from "axios";
import { REFRESH_TOKEN_ENDPOINT, USER_GET_PATCH_ENDPOINT, LOG_OUT } from "@/helpers/constants/api";

interface TokenResponse {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  error?: string;
}

export const authModule = {
  async refreshToken(refresh_token: string): Promise<AxiosResponse<TokenResponse>> {
    return await api.post(REFRESH_TOKEN_ENDPOINT, {refresh_token: refresh_token,});
  },
  async logOut(): Promise<AxiosResponse> {
    return await api.post(LOG_OUT, {});
  },
  async loadUser(): Promise<AxiosResponse> {
    return await api.get(USER_GET_PATCH_ENDPOINT, {headers: {"Content-Type": "application/json",},});
  },
};
