// src/api/api.ts is a module that exports an axios instance with some default settings.
import axios from "axios";
import {BASE_URL, IDEMPOTENCY_KEY_HEADER} from "@/helpers/constants/api";
import {v4 as uuidv4} from "uuid";
import {useAuthStore} from "@/store/auth";

const generateIdempotencyKey = () => {
    return uuidv4();
};


const api = axios.create({
    baseURL: BASE_URL,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
});

export default api;

export const setupInterceptors = () => {
    api.interceptors.request.use((config) => {
        const store = useAuthStore();
        const token = store.authToken;
        const expired = store.expiresAt ? store.expiresAt < new Date() : true;

        if (token && !expired) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (config.method !== "get" && !config.headers[IDEMPOTENCY_KEY_HEADER]) {
            config.headers[IDEMPOTENCY_KEY_HEADER] = generateIdempotencyKey();
        }

        return config;
    }, (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    });
};
