function convertCoordinates(lon: number, lat: number): [number, number] {
  const x = (lon * 20037508.34) / 180
  let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180)
  y = (y * 20037508.34) / 180;
  return [x, y];
}

function convertCoordinatesBack(x: number, y: number): [number, number] {
  const lon = (x / 20037508.34) * 180;
  let lat = (y / 20037508.34) * 180;
  lat = (180 / Math.PI) * (2 * Math.atan(Math.exp((lat * Math.PI) / 180)) - Math.PI / 2);
  return [lon, lat];
}

function convertExtentBack(extent: [number, number, number, number]): [number, number, number, number] {
  const [x1, y1] = convertCoordinatesBack(extent[0], extent[1]);
  const [x2, y2] = convertCoordinatesBack(extent[2], extent[3]);
  return [x1, y1, x2, y2];
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

function getDistanceFromLatLonInM(lat1: number, lon1: number, lat2: number, lon2: number): number {
  return getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) * 1000;
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export {
  convertCoordinates,
  convertCoordinatesBack,
  convertExtentBack,
  getDistanceFromLatLonInKm,
  getDistanceFromLatLonInM,
  arrayBufferToBase64,
}
