<template>
  <div class='manifesto-wrapper m-3'>
    <h1 class="display-5">Привет мир!</h1>
    <img src="/manifesto-1.png" class="float-end">
    <p class='lead'>
      <b>Мы - команда проекта ХрюХрюКар</b>, и мы хотим <mark>сделать мир лучше</mark>.
      Мы не боимся называть вещи своими именами и целенаправленно действовать в интересах <mark>законопослушного общества</mark>.
    </p>
    <p class='lead'>
      <b>ХрюХрюКар</b> - это система, направленная на борьбу с <mark>нарушениями автомобилистов</mark>, связанными со стоянкой или остановкой в неположенных местах.
    </p>
    <img src="/manifesto-2.png" class="float-start">
    <p class='lead'>
      <b>Современное общество</b> в России имеет массу проблем, одна из которых - это искаженная интерпретация понятий <mark>собственность</mark>,
      <mark>право</mark> и <mark>обязанность</mark>. <b>Когда встаёт вопрос поиска места хранения автомобиля</b>, многие водители вспоминают о
      праве общедолевой собственности на участок во дворе и ставят свой автомобиль где им вздумается. Но мест хватает не всем и многие,
      не задумываясь, ставят машину на зелёной зоне, оправдываясь тем, что <mark>другого места им никто не обеспечил</mark>.
    </p>
    <img src="/manifesto-3.png" class="float-end">
    <p class='lead'>
      <b>Но когда встает вопрос ремонта и содержания</b> дворовой территории, большинство начинает винить местные власти, которые и "двор не отремонтировали" и "машиноместа
      в достаточном количестве не построили", и "снег зимой вовремя не убрали". Большая часть автомобилистов искренне <mark>не понимают своей <b>обязанности</b> обеспечить
      свой автомобиль местом постоянного хранения</mark>.
    </p>
    <p class='lead'>
      <b>Мы намерены это изменить!</b> Первым этапом решения проблемы должно стать её признание и понимание. Каждый нарушитель, паркуя автомобиль на зеленой зоне,
      должен понимать, что шансы получить штраф теперь сильно выше, чем прежде. Подобные нарушения должны стать не только неприличными, но и <mark>дико дорогими</mark>.
      <b>Так и только так</b> мы сможем вернуть здоровый спрос на услуги хранения авто. А спрос, как говорится, порождает предложение.
    </p>
    <p class="note note-primary">
      <strong>Проблема роста уровня автомобилизации</strong> населения присуща всем развитым странам мира. Но в отличие от нас, во многих странах,
      с ростом уровня автомобилизации ведется повсеместная борьба. Дороги сужаются, количество парковочных мест уменьшается, а штрафы и налоги растут.
      Услуги общественного транспорта пользуются всё большей популярностью, а водителей, наоборот, стараются отучить от использования автомобиля в городе.
    </p>
    <img src="/manifesto-4.png" class="float-start">
    <p class='lead'>
      <mark><b>Так что делать?</b></mark> Расскажем на примере города Балаково, первого города где стартует проект "ХрюХрюКар".
      <b>В нашем городе Балаково</b> на начало 2024 года по Росстату около <mark>70 тысяч автомобилей</mark>, не меньше половины из них <mark>хранятся</mark>
      во дворах многоквартирных домов. Сколько точно в моменте припарковано на зелёной зоне - не известно, но счёт точно идет на тысячи.
      Если ознакомиться с <a href='https://www.admbal.ru/struktura/glava-balakovskogo-munitsipalnogo-rayona/zamestitel-glavy-administratsii-balakovskogo-munitsipalnogo-rayona-rukovoditel-apparata/administrativnaya-komissiya/' target='_blank'> отчетами</a>
      о работе административной комиссии, то становится известно общее количество привлеченных к административной ответственности лиц за 2023 год - <mark>несколько сотен человек</mark>.
      В эту цифру входят не только автомобилисты, но и иные нарушители, чьи дела уполномочена рассматривать комиссия. Получить штраф за парковку на зелёной зоне в Балаково
      - <mark>шанс минимальный</mark>. И это понятно, ведь никакого штата не хватит, чтобы фиксировать нарушения тысяч автомобилистов в маленьком городе.
    </p>
    <img src="/manifesto-5.png" class="float-end">
    <p class='lead'>
      <mark><b>Как увеличить этот показатель?</b></mark> Любое заявление, содержащее сведения, достаточные для составления протокола об административном правонарушении,
      будет в обязательном порядке рассмотрено уполномоченным органом и <mark>приведет к привлечению нарушителя к административной ответственности</mark>.
      Мы вместе с вами можем принять участие в этом процессе, и тем самым <mark>помочь увеличить количество привлеченных к ответственности лиц</mark>.
    </p>
    <p class='lead'>
      <b>ХрюХрюКар</b> - это система, позволяющая в один клик <mark>зафиксировать факт совершения административного правонарушения</mark>.
      При этом от пользователя больше ничего не требуется: нейросети сами распознают номер автомобиля, а геокодер определит точное местоположение.
      Далее наши модераторы самостоятельно проведут проверку всех материалов, а система управления полномочиями автоматически определит уполномоченный
      орган, сформирует заявление по форме и направит его <mark>от нашего имени</mark> в этот орган на рассмотрение.
    </p>
    <p class='lead'>
      Также мы сами проконтролируем процесс рассмотрения заявления - <mark>вплоть до получения положительного для нас результата</mark>.
    </p>
    <img src="/manifesto-6.png" class="float-start">
    <p class='lead'>
      <mark><b>В каких городах работает ХХК?</b></mark> С учетом разделения полномочий по территориальному принципу и различий в законодательстве субъектов РФ,
      мы реализовали систему распределенных полномочий, позволяющую работать на всей территории нашей страны. Заявления формируются автоматически, в зависимости от
      места совершения правонарушения. Также автоматически выбирается орган, которому будет направлено заявление. Тем не менее, для каждой территории необходим
      определенный перечень правовой информации, которую мы должны внести в базу. На момент запуска проекта мы реализовали работу в городе Балаково и Балаковском
      районе Саратовской области. <mark>Если вы хотите добавить свой город</mark>, заполните, пожалуйста, <a :href='URL_OF_FORM_TO_ADD_PLACE' target='_blank'>эту форму</a>.
    </p>
    <p class='lead'>
      <mark><b>С какими нарушениями работает ХХК?</b></mark> Механизмы ХрюХрюКара позволяют работать с любыми нарушениями автомобилистов,
      которые возможно зафиксировать с помощью камеры мобильного телефона.
    </p>
    <p class="lead"><b>На момент запуска мы добавили следующие виды нарушений:</b></p>
    <ul class="list-unstyled">
      <li class="mb-1"><i class="fas fa-check-circle me-2 text-primary"></i>Стоянка на зеленой зоне;</li>
      <li class="mb-1"><i class="fas fa-check-circle me-2 text-primary"></i>Стоянка на детской/спортивной площадке;</li>
      <li class="mb-1"><i class="fas fa-check-circle me-2 text-primary"></i>Стоянка на пешеходном переходе и ближе 5 метров от него;</li>
      <li class="mb-1"><i class="fas fa-check-circle me-2 text-primary"></i>Стоянка на тротуаре в нарушение ПДД.</li>
    </ul>
    <hr class='hr'>
    <h5 class="display-5">Каким мы видим результат</h5>
    <img src="/manifesto-7.png" class="float-end">
    <p class="lead"><b>Миссия проекта завершится в тот момент, когда выполнятся все условия, перечисленные ниже:</b></p>
    <ul class="list-unstyled">
      <li class="mb-1"><i class="fas fa-long-arrow-alt-right me-2 text-info"></i>Каждый автомобилист будет ежемесячно <mark>нести расходы на хранение</mark> своего автомобиля;</li>
      <li class="mb-1"><i class="fas fa-long-arrow-alt-right me-2 text-info"></i>Каждый, кто хочет <mark>хранить автомобиль</mark> во дворе МКД, будет размещать его только на специализированных парковках в дворах, принося тем самым <mark>доход в фонды своего дома (например в ТСЖ)</mark>;</li>
      <li class="mb-1"><i class="fas fa-long-arrow-alt-right me-2 text-info"></i>Каждый, кто <mark>бросает автомобиль</mark> в неположенных местах (в т.ч. на "гостевых" парковках на ночь), будет <mark>платить за это штраф и подвергаться эвакуации</mark>;</li>
      <li class="mb-1"><i class="fas fa-long-arrow-alt-right me-2 text-info"></i>Количество автомобилей на территории дворов <mark>сократится в разы</mark>.</li>
    </ul>
    <hr class='hr'>
    <h5 class="display-5">Информация для уполномоченных органов, рассматривающих наши заявления:</h5>
    <p class='lead'>
      <mark><b>По каждому заявлению</b></mark> мы ожидаем от вас <mark>положительного результата</mark> в виде привлечения нарушителя к административной ответственности и направления ответа в наш адрес.
      Мы понимаем, что на начальных этапах количество заявлений может быть достаточно велико, в связи с чем мы готовы оказать посильную помощь в автоматизации процесса на <mark><b>безвозмездной основе.</b></mark>
      Мы готовы предоставлять вам информацию в машиночитаемом виде в любом формате, а также предоставить исчерпывающие консультации по вопросу автоматического формирования необходимых документов (запросов, писем, протоколов).
      Для более подробной информации обращайтесь по <a href='mailto:admin@xxkap.app' target='_blank'>электронной почте</a>.
    </p>
    <hr class='hr'>
    <div class="d-flex justify-content-center mb-3">
      <img src="/ooc_logo_horisontal.png" class="horizontal-logo ms-1 me-1 mt-2 ms-auto me-auto" alt="логотип ХрюХрюКар"/>
    </div>
    <hr class='hr'>
    <h5 class="display-5">Полезные ссылки:</h5>
    <div class='vstack gap-3'>
      <MDBBtn color="secondary" tag='a' :href='URL_OF_FORM_TO_DELETE_VIOLATION' target='_blank'>
        <i class="fas fa-trash-alt fa-fw me-2"></i>
        Удалить фото (если оплатили штраф)
      </MDBBtn>
      <MDBBtn color="secondary" tag='a' :href='URL_OF_FORM_TO_ADD_PLACE' target='_blank'>
        <i class="fas fa-city fa-fw me-2"></i>
        Добавить город
      </MDBBtn>
      <MDBBtn color="secondary" tag='a' :href='URL_OF_FORM_TO_ASK_EVERYTHING' target='_blank'>
        <i class="fas fa-envelope fa-fw me-2"></i>
        Задать вопрос
      </MDBBtn>
      <MDBBtn color="primary" tag='a' :href='URL_OF_TELEGRAM' target='_blank'>
        <i class="fab fa-telegram-plane fa-fw me-2"></i>
        Почитать наш телеграм канал
      </MDBBtn>
      <MDBBtn color="dark" tag='a' :href='URL_OF_REPOSITORY' target='_blank'>
        <i class="fas fa-code-branch fa-fw me-2"></i>
        Посмотреть исходники
      </MDBBtn>
      <MDBBtn color="danger" tag='a' href='http://www.beznadyoga.ru/write.php' target='_blank'>
        <i class="fas fa-angry fa-fw me-2"></i>
        Пожаловаться на ХрюХрюКар
      </MDBBtn>
    </div>
    <hr class='hr'>
    <p class="lead">Этот материал и все материалы нарушений (фотография нарушения, адрес, местоположение, дата и тип нарушения), размещенные на сайте доступны по лицензии Creative Commons Attribution 4.0 International (CC BY 4.0).
      Любое копирование, распространение, отображение и воспроизводство перечисленных материалов <mark>допускается</mark> при условии указания авторства.</p>
    <br><br>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MDBBtn } from 'mdb-vue-ui-kit'
import {
  URL_OF_FORM_TO_ADD_PLACE,
  URL_OF_FORM_TO_ASK_EVERYTHING,
  URL_OF_FORM_TO_DELETE_VIOLATION, URL_OF_REPOSITORY, URL_OF_TELEGRAM
} from '@/helpers/constants/routes'

export default defineComponent({
  data() {
    return {
      URL_OF_FORM_TO_ASK_EVERYTHING,
      URL_OF_FORM_TO_ADD_PLACE,
      URL_OF_FORM_TO_DELETE_VIOLATION,
      URL_OF_REPOSITORY,
      URL_OF_TELEGRAM,
    }
  },
  name: "ManifestoContainer",
  components: { MDBBtn },
});
</script>
<style scoped lang="scss">
.manifesto-wrapper {
  max-width: 1100px !important;
}
@media (min-width: 1100px) {
  .manifesto-wrapper {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.horizontal-logo {
  max-width: 100%;
  height: auto;
  max-height: 128px;
  object-fit: contain;
}
</style>
