<template>
  <div class='violation-details-wrapper'>
    <MDBModal
      ref="modalRef"
      v-model="modalVisible"
      fullscreen="xl-down"
      size="xl"
      @hide="closeDetails"
    >
      <MDBModalHeader>
        <MDBModalTitle>
          <div class='hstack'>
            {{ $t('content.violationDetails') }}
            <a class="m-1" role="button" @click="copyLinkToClipboard" :style="currentViolation?.isNotFound ? 'display:none' : ''">
              <MDBIcon iconStyle="fas" icon="external-link-alt" class='mb-2 ms-3'></MDBIcon>
            </a>
            <MDBBadge badge='success' v-if='copyLinkBadge' class='ms-2' pill>
              {{ $t('content.linkCopied') }}
            </MDBBadge>
          </div>
        </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <MDBCardGroup :style="currentViolation?.isNotFound ? 'display:none' : ''">
          <MDBCard>
            <MDBCardHeader class='p-0'>
              <!--
                Warning about the use of a non-free component. Attention! The MDBLightbox component is provided under
                the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
                open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase
                the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or
                make changes to this source code to eliminate the use of non-free components.
              -->
              <MDBLightbox>
                <MDBLightboxItem
                  :src="currentViolation?.carPlate?.photo?.srcPhoto"
                  :fullScreenSrc="currentViolation?.carPlate?.photo?.srcPhoto"
                  :alt="currentViolation?.carPlate?.plateNumber"
                  className='object-cover w-100 violation-photo'
                  top
                />
              </MDBLightbox>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBCardTitle>
                  <h3>
                    <MDBBadge color="light">{{ currentViolation?.carPlate?.plateNumber }}</MDBBadge>
                  </h3>
              </MDBCardTitle>
              <MDBCardText>
                <p class='lead'>
                  {{$t('content.violation')}}
                  <MDBTooltip v-model="descriptionTooltip">
                    <template #reference>
                      <span class='link-primary'>"{{ currentViolation?.violationType?.name }}"</span>
                    </template>
                    <template #tip>
                      {{ currentViolation?.violationType?.description }}
                    </template>
                  </MDBTooltip>
                  {{$t('content.violationOn')}} {{createdAtFormatted}}
                </p>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
          <MDBCard>
            <MDBCardHeader class='p-0'>
              <ol-map
                :loadTilesWhileAnimating="true"
                :loadTilesWhileInteracting="true"
                ref='map'
                class="map-wrapper"
              >
                <!--
                  Warning about the use of a non-free component. Attention! The MDBLightbox component is provided under
                  the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
                  open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase
                  the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or
                  make changes to this source code to eliminate the use of non-free components.
                -->
                <MDBLightbox :style="currentViolation?.carPlate?.photo?.panoramaShot ? '' : 'display:none'">
                  <MDBLightboxItem
                    :src="currentViolation?.carPlate?.photo?.panoramaShot"
                    :fullScreenSrc="currentViolation?.carPlate?.photo?.panoramaShot"
                    :alt="$t('moderatorView.panorama')"
                    className='thumbnail-photo w-100 shadow-1-strong rounded'
                  />
                </MDBLightbox>
                <ol-view
                  ref="view"
                  :center="mapCenter"
                  :zoom="mapZoom"
                  :projection='"EPSG:3857"'
                />
                <ol-tile-layer>
                  <ol-source-xyz ref="sourceRef" :url="tileLayerUrl"/>
                </ol-tile-layer>
                <ol-vector-layer>
                  <ol-source-vector>
                    <ol-feature>
                      <ol-geom-point :coordinates="point"></ol-geom-point>
                      <ol-style>
                        <ol-style-icon src="/pig-on-map.png" :scale="0.05"></ol-style-icon>
                        <ol-style-text :text="currentViolation?.carPlate?.plateNumber" offsetY='20'>
                          <ol-style-fill :color="'#000000'"></ol-style-fill>
                          <ol-style-stroke :color="'#FFFFFF'" :width="3"></ol-style-stroke>
                        </ol-style-text>
                      </ol-style>
                    </ol-feature>
                  </ol-source-vector>
                </ol-vector-layer>
              </ol-map>
              <h5 v-if="currentViolation?.nearestParkingDistance && currentViolation?.nearestParkingDistance < 1000">
                <MDBBadge :color="currentViolation?.nearestParkingDistance < 801 ? 'danger' : 'warning'" pill class='position-absolute top-0 end-0 m-2'>
                  <MDBIcon iconStyle="fas" icon="walking" class='me-2'/>
                  {{ $t('content.toParking') }}: ~ {{currentViolation?.nearestParkingDistance}}{{ $t('content.m') }}
                </MDBBadge>
              </h5>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBCardTitle>
                <div class='hstack gap-2'>
                  <h3><MDBBadge :color="getViolationStatus?.color">{{ getViolationStatus?.badge }}</MDBBadge></h3>
                  <MDBTooltip v-model="statusTooltip">
                    <template #reference>
                      <a class="m-1" role="button">
                        <MDBIcon iconStyle="fas" icon="info-circle" class='mb-2'></MDBIcon>
                      </a>
                    </template>
                    <template #tip>
                      {{ getViolationStatus?.tooltip }}
                    </template>
                  </MDBTooltip>
                </div>
              </MDBCardTitle>
              <MDBCardText>
                <small class="lead">
                  {{ currentViolation?.carPlate?.photo?.address }}
                </small>
              </MDBCardText>
            </MDBCardBody>
        </MDBCard>
        </MDBCardGroup>
        <div :style="currentViolation?.isNotFound ? '' : 'display:none'">
          <h1>{{$t('content.oopsNotFound')}}</h1>
          <p class='lead'>
            {{$t('content.oopsNotFoundDescription')}}
          </p>
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn :style="currentViolation?.isNotFound ? 'display:none' : ''" color="link" tag="a" target="_blank" :href="URL_OF_FORM_TO_DELETE_VIOLATION" class='me-auto'>{{ $t('content.deleteViolation')}}</MDBBtn>
        <MDBBtn class='ms-auto' color="secondary" @click="modalVisible = false">
          {{ $t('content.close') }}
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { useIfaceStore } from '@/store/iface'
import { useMapStore } from '@/store/map'
import { useModerationStore } from '@/store/moderation'
import { HOME_ROUTE_NAME, URL_OF_FORM_TO_DELETE_VIOLATION } from '@/helpers/constants/routes'

import {
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBCardBody,
  MDBCardTitle,
  MDBCard,
  MDBCardText,
  MDBBadge,
  MDBLightboxItem,
  MDBLightbox,
  MDBCardGroup,
  MDBCardHeader,
  MDBTooltip,
} from 'mdb-vue-ui-kit'
import { convertCoordinates } from '@/helpers/functions'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import { ViolationDetails } from '@/store/models/violation.model'
import { Map } from 'ol'
import { DragPan } from 'ol/interaction'
import type XYZ from "ol/source/XYZ";


export default defineComponent({
  name: "ViolationsDetailsContainer",
  components: {
    MDBCardText,
    MDBCard,
    MDBCardTitle,
    MDBCardBody,
    MDBIcon,
    MDBBtn,
    MDBModal,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBBadge,
    MDBLightboxItem,
    MDBLightbox,
    MDBCardGroup,
    MDBCardHeader,
    MDBTooltip,
  },
  data() {
    return {
      URL_OF_FORM_TO_DELETE_VIOLATION,
    };
  },
  methods: {
    closeDetails() {
      this.mapStore.setViolationDetails(null);
      this.$router.push({ name: HOME_ROUTE_NAME });
    },
    copyLinkToClipboard() {
      const currentUrl = new URL(window.location.href);
      const violationId = this.currentViolation?.id;
      if (violationId) {
        currentUrl.searchParams.set('violation', violationId);
        navigator.clipboard.writeText(currentUrl.href).then(() => {
          this.copyLinkBadge = true;
          setTimeout(() => {
            this.copyLinkBadge = false;
          }, 3000);

        }).catch((err) => {
          console.error('Не удалось скопировать ссылку в буфер обмена:', err);
        });
      }
    }
  },
  computed: {
    modalVisible: {
      get(): boolean {
        return this.currentViolation !== null && !this.mapStore.violationForDetailsLoading;
      },
      set(value: boolean) {
        if (!value) {
          this.mapStore.setViolationDetails(null);
        }
      },
    },
    createdAtFormatted() {
      return this.currentViolation?.createdAt? this.$d(this.currentViolation?.createdAt) : '';
    },
    getViolationStatus() {
      let result = [
        { reason: MODERATION_STATUS.NOT_MODERATED, icon: "eye-slash", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.NOT_MODERATED}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.NOT_MODERATED}`), color: 'light' },
        { reason: MODERATION_STATUS.CONFIRMED, icon: "eye-slash", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.CONFIRMED}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.CONFIRMED}`), color: 'success' },
        { reason: MODERATION_STATUS.REJECTED_REASON_BLURRED, icon: "eye-slash", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_BLURRED}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_BLURRED}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_FACES, icon: "angry", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_FACES}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_FACES}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_NO_CAR, icon: "search", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_NO_CAR}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_NO_CAR}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_CAR_NOT_FIT, icon: "border-style", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_CAR_NOT_FIT}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_CAR_NOT_FIT}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_GEOLOCATION, icon: "map-marker-alt", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_GEOLOCATION}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_GEOLOCATION}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_CAR_PLATE_NOT_READABLE, icon: "glasses", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_CAR_PLATE_NOT_READABLE}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_CAR_PLATE_NOT_READABLE}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_INTERNATIONAL_CAR_PLATE, icon: "globe-europe", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_INTERNATIONAL_CAR_PLATE}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_INTERNATIONAL_CAR_PLATE}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED, icon: "exclamation-triangle", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_VIOLATION_ALREADY_EXISTS, icon: "exclamation-triangle", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_VIOLATION_ALREADY_EXISTS}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_VIOLATION_ALREADY_EXISTS}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_FLOOD, icon: "robot", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_FLOOD}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_FLOOD}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_ADULT, icon: "book-dead", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_ADULT}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_ADULT}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_OTHER, icon: "bug", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_OTHER}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_OTHER}`), color: 'danger' },
        { reason: MODERATION_STATUS.REJECTED_REASON_TIMEOUT, icon: "clock", badge: this.$t(`content.moderationStatusBadge_${MODERATION_STATUS.REJECTED_REASON_TIMEOUT}`), tooltip: this.$t(`content.moderationStatusTooltip_${MODERATION_STATUS.REJECTED_REASON_TIMEOUT}`), color: 'danger' },
      ];
      const resultStatus = result.filter((rr) => this.currentViolation?.moderationStatus === rr.reason);
      return resultStatus.length > 0 ? resultStatus[0] : null;
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const mapStore = useMapStore();
    const objectsStore = useModerationStore();

    const descriptionTooltip = ref(false);
    const statusTooltip = ref(false);
    const deleteInfoTooltip = ref(false);
    const copyLinkTooltip = ref(false);
    const copyLinkBadge = ref(false);

    const map = ref<Map | null>(null);
    const dragPan = ref<DragPan | null>(null);

    const point = ref([0, 0]);
    const mapZoom = ref(17);
    const mapCenter = ref(convertCoordinates(47.791846, 52.005431),);

    const currentViolation = ref<ViolationDetails | null>(mapStore.violationForDetails);

    watch(() => mapStore.violationForDetails,
      (value) => {
          currentViolation.value = value;
          if (value && value.carPlate?.photo?.geom) {
            point.value = value.carPlate?.photo.geom;
            mapCenter.value = value.carPlate?.photo.geom;
            mapZoom.value = 17;
          }
        },
      { immediate: true }
    );

    function configureDragPan() {
      if (dragPan.value) {
        dragPan.value.setActive(window.innerWidth > 575);
      }
    }

    watch(() => map.value,
      (value: Map | null) => {
        //@ts-ignore
        if (value && value.map) {
         //@ts-ignore
         value.map?.interactions.forEach((interaction) => {
           if (interaction instanceof DragPan) {
             dragPan.value = interaction;
             configureDragPan();
           }
         });
        }
      },
    );

    onMounted(() => {
      configureDragPan();
      window.addEventListener('resize', configureDragPan);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', configureDragPan);
    });

    const sourceRef = ref<{ source: XYZ }>(null);
    const tileLayerUrl = ref(mapStore.tileLayerUrl);

    watch(() => mapStore.tileLayerUrl, (newVal) => {
      sourceRef.value?.source?.setUrl(newVal);
      tileLayerUrl.value = newVal;
    });


    return {
      tileLayerUrl,
      sourceRef,
      ifaceStore,
      mapStore,
      objectsStore,
      point,
      mapZoom,
      mapCenter,
      descriptionTooltip,
      statusTooltip,
      deleteInfoTooltip,
      copyLinkTooltip,
      copyLinkBadge,
      currentViolation,
      map,
    }
  },
});
</script>
<style scoped lang="scss">

.violation-photo {
  min-height: 50vh !important;
  height: 50vh !important;
  width: 100%;
  object-fit: cover;
}

.map-wrapper {
  min-height: 50vh !important;
  height: 50vh !important;
  width: 100%;
  flex-grow: 1;
  position: relative;
}

.thumbnail-photo {
  width: 80px !important;
  height: 80px !important;
  position: absolute;
  z-index: 200;
  left: 4px;
  bottom: 4px;
  border: 2px solid white;
  object-fit: cover;
}

</style>
