// src/helpers/enums/moderation-status.ts
export enum MODERATION_STATUS {
  NOT_MODERATED = 'not_moderated',
  CONFIRMED = 'confirmed',
  REJECTED_REASON_BLURRED = 'blurred',
  REJECTED_REASON_FACES = 'faces',
  REJECTED_REASON_NO_CAR = 'no_car',
  REJECTED_REASON_CAR_NOT_FIT = 'car_not_fit',
  REJECTED_REASON_CAR_PLATE_NOT_READABLE = 'car_plate_not_readable',
  REJECTED_INTERNATIONAL_CAR_PLATE = 'international_car_plate',
  REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED = 'violation_type_not_confirmed',
  REJECTED_REASON_VIOLATION_ALREADY_EXISTS = 'violation_already_exists',
  REJECTED_REASON_GEOLOCATION = 'geolocation_error',
  REJECTED_REASON_FLOOD = 'flood',
  REJECTED_REASON_ADULT = 'adult',
  REJECTED_REASON_OTHER = 'other',
  REJECTED_REASON_TIMEOUT = 'timeout',
}
