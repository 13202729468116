// frontend/src/i18n/vue-i18n.ts
import { useCookies } from 'vue3-cookies'
import { LOCALE_RU, LOCALE_EN } from '@/helpers/constants/locale';

import en from "@/i18n/en/en";
import ru from "@/i18n/ru/ru";

import { createI18n } from "vue-i18n";

const messages = {
  en,
  ru,
};

const { cookies } = useCookies();

export const availableLanguages = [LOCALE_RU, LOCALE_EN];

const i18n = createI18n({
  locale: cookies.get("oink-locale") || LOCALE_RU,
  fallbackLocale: LOCALE_EN,
  messages,
});

export default i18n;
