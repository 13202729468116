// src/store/auth.ts
// Module for authentication and authorization.
import { defineStore } from 'pinia';
import { User } from '@/store/models/user.model';
import { mapApiUserToStoreUser } from '@/helpers/mappers/user.mapper';
import { authModule } from '@/api';
import { useCookies } from 'vue3-cookies';
import { useAppStore } from '@/store/app';
import { useModerationStore } from '@/store/moderation'
import { useMapStore } from '@/store/map'
import { useHuntingStore } from '@/store/hunting'
import { useApplicationsStore } from '@/store/applications'

const { cookies } = useCookies();


export const useAuthStore = defineStore('auth', {
  state: () => ({
    authToken: null as string | null,
    expiresAt: null as Date | null,
    refreshToken: null as string | null,
    isAuthenticated: false as boolean,
    user: null as User | null,
    refreshTokenIntervalId: undefined as number | undefined,
    userLoaded: false as boolean,
    isInitialized: false as boolean,
    initializationStarted: false as boolean,
  }),

  actions: {
    async clearAuthState() {
      const appStore = useAppStore();
      await this.stopRefreshTokenInterval();
      await appStore.waitForBackendAvailable();

      try {
        await authModule.logOut();
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при выходе из системы: ${error}`);
      }

      await this.setIsAuthenticated(false);
      this.setAuthToken(null);
      this.setExpiresAt(null);
      this.setRefreshToken(null);
      this.checkAndClearCookies();
      appStore.addDebugMessage('Состояние авторизации очищено');
    },

    async checkAndClearCookies() {
      for (const key of ['authToken', 'expiresAt', 'refreshToken', 'csrftoken']) {
        if (cookies.isKey(key)) {
           cookies.remove(key);
        }
      }
    },

    async setAuthToken(authToken: string | null) {
      this.authToken = authToken;
    },

    async setExpiresAt(expiresAt: Date | null) {
      this.expiresAt = expiresAt;
    },

    async setRefreshToken(refreshToken: string | null) {
      this.refreshToken = refreshToken;
    },

    async setIsAuthenticated(isAuthenticated: boolean) {
      const appStore = useAppStore();
      this.isAuthenticated = isAuthenticated;
      if(isAuthenticated) {
        appStore.addDebugMessage('Пользователь авторизован');
        this.startRefreshTokenInterval();
        await this.loadUser()
      } else {
        await this.setUser(null);
        appStore.addDebugMessage('Пользователь вышел из системы');
      }
    },

    async setUser(user: User | null) {
      const appStore = useAppStore();
      this.user = user;
      this.userLoaded = user != null;
      appStore.addDebugMessage('Пользователь установлен');
    },

    async setIsInitialized() {
      const appStore = useAppStore();
      appStore.rulesHaveBeenRead = cookies.get('rulesHaveBeenRead') == 'true';
      this.isInitialized = true;
      appStore.addDebugMessage('Инициализация завершена');
    },

    async waitIsAuthenticationFinished() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.isInitialized) {
            clearInterval(interval);
            resolve(this.isAuthenticated);
          }
        }, 250);
      });
    },

    async notHaveModeratorRights() {
      await this.waitIsAuthenticationFinished();
      return !this.user?.isSuperuser && (!this.isAuthenticated || this.user?.isBanned || !this.user?.isModerator)
    },

    async notAuthenticatedOrBanned() {
      await this.waitIsAuthenticationFinished();
      return !this.isAuthenticated || this.user?.isBanned;
    },

    async notAuthenticated() {
      await this.waitIsAuthenticationFinished();
      return !this.isAuthenticated;
    },

    async notHaveCredentials() {
      return !this.isAuthenticated || !this.authToken
    },

    async initStore() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      return new Promise(async (resolve) => {
        try {
          if (this.initializationStarted) {
            return resolve(true);
          }
          this.initializationStarted = true;
          appStore.addDebugMessage('Попытка загрузить учетные данные из cookie');
          if (cookies.isKey('authToken') && cookies.isKey('expiresAt') && cookies.isKey('refreshToken')) {
            appStore.addDebugMessage('Учетные данные найдены в cookie');
            const authToken = cookies.get('authToken');
            const expiresAt = new Date(cookies.get('expiresAt'));
            const validity = expiresAt.getTime() - new Date().getTime();
            const refreshToken = cookies.get('refreshToken');
            await this.setAuthToken(authToken);
            await this.setExpiresAt(expiresAt);
            await this.setRefreshToken(refreshToken);
            if (validity < 1000 * 60 * 60 * 2) {
              appStore.addDebugMessage('Токен просрочен');
              await this.tryToRefreshToken();
            } else {
              await this.setIsAuthenticated(true);
            }
          }
          await this.setIsInitialized();
          return resolve(true);
        } catch (error) {
          appStore.addErrorMessage(`Ошибка при инициализации учетных данных: ${error}`, true);
          await this.setIsInitialized();
          return resolve(false);
        }
      });
    },

    async stopRefreshTokenInterval() {
      const appStore = useAppStore();
      if (this.refreshTokenIntervalId) {
        clearInterval(this.refreshTokenIntervalId);
        this.refreshTokenIntervalId = undefined;
        appStore.addDebugMessage('Обновление токена остановлено');
      }
    },

    async startRefreshTokenInterval() {
      const self = this;
      const appStore = useAppStore();
      if (self.expiresAt == null) {
        return;
      }
      self.stopRefreshTokenInterval().then(() => {
        let timeout = self.expiresAt.getTime() - new Date().getTime();
        timeout -= 10 * 60 * 1000; // -10 minutes
        timeout = Math.max(timeout, 60 * 1000);
        self.refreshTokenIntervalId = setInterval(() => {
          self.tryToRefreshToken();
        }, timeout);
        appStore.addDebugMessage('Обновление токена запущено');
      });
    },

    async tryToRefreshToken() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        appStore.addDebugMessage('Попытка обновить токен');
        if (!this.refreshToken) return false;
        const response = await authModule.refreshToken(this.refreshToken);
        if (response.data?.access_token && response.data?.expires_in && response.data?.refresh_token) {
          appStore.addDebugMessage('Учетные данные получены от сервера');
          const expiresAt = new Date();
          expiresAt.setSeconds(expiresAt.getSeconds() + response.data.expires_in);
          await this.setAuthToken(response.data.access_token);
          await this.setExpiresAt(expiresAt);
          await this.setRefreshToken(response.data.refresh_token);
          await this.setIsAuthenticated(true);
          appStore.addDebugMessage('Токен обновлен');
          return true;
        }
        appStore.addErrorMessage(`Ошибка при обновлении токена ${response?.status} ${response.data}`);
        return false;
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при обновлении токена: ${error}`);
        await this.clearAuthState();
        return false;
      }
    },

    async loadUser() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      if (await this.notHaveCredentials()) return;
      appStore.addDebugMessage('Попытка загрузить пользователя');
      try {
        const response = await authModule.loadUser();
        if (response.data) {
          const user = mapApiUserToStoreUser(response.data);
          this.setUser(user);
          if (!user.isBanned) {
            const huntingStore = useHuntingStore();
            huntingStore.updateViolationStatsLoop();
            if (user.isModerator || user.isSuperuser) {
              const applicationsStore = useApplicationsStore();
              applicationsStore.loadApplicants();
            }
          }
          appStore.addDebugMessage('Пользователь загружен');
          appStore.loadFrontendUserSettings();
        } else {
          appStore.addErrorMessage('Ошибка при загрузке пользователя, данные не получены');
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке пользователя: ${error}`);
        if(error.response?.status >= 500 || error.response?.status === 408) {
          setTimeout(() => this.loadUser(), 3000);
          appStore.addDebugMessage('Повторная попытка загрузить пользователя через 3 секунды');
        }
      }
    },

    async logoutUser() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      appStore.addDebugMessage('Попытка выхода из системы');
      await this.clearAuthState();
      await appStore.resetFrontendUserSettings();

      const huntingStore = useHuntingStore();
      await huntingStore.resetState();

      const moderationStore = useModerationStore();
      await moderationStore.resetState();

      const mapStore = useMapStore();
      await mapStore.resetState();

      const applicationsStore = useApplicationsStore();
      await applicationsStore.resetState();

      appStore.addDebugMessage('Выход из системы завершен');
    }
  },
});