<template>
  <MDBCol>
    <!--
      Warning about the use of a non-free component. Attention! The MDBAlert component is provided under the
      "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of open-source
      software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the specified license
      from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make changes to this source code
      to eliminate the use of non-free components.
    -->
    <MDBAlert :color="notRecognized || notGeocoded ? 'info' : 'dark'" static class='p-3 pt-2 pb-2' v-if='huntingStore.currentPhoto'>
      <div class="d-flex align-items-center">
        <i v-if='!havePlates || huntingStore.carPlatesLoadingInProgress || geoPhotoUploading' :class="notRecognized || notGeocoded ? 'fas fa-chevron-circle-right me-3' : 'fas fa-check-circle me-3'"></i>
        <i v-else class="fas fa-check-double me-3"></i>
        <strong v-if='geoPhotoUploading'>{{ $t('hunting.uploading_wait') }}</strong>
        <strong v-else-if='notRecognized || notGeocoded'>
          {{ notRecognized ? $t('hunting.license_plate_recognition') : $t('hunting.license_plates_recognized') }},
          {{ notGeocoded ? $t('hunting.geocoding') : $t('hunting.geocoded') }}
        </strong>
        <strong v-else-if='geoPhotoRejectedBecauseAlreadyExists'>
          {{ $t('hunting.photoRejectedBecauseAlreadyExists') }}
        </strong>
        <strong v-else-if='huntingStore.carPlatesLoadingInProgress'>
          {{ $t('hunting.loadingResults') }}
        </strong>
        <div v-else-if='havePlates' class='hstack'>
          <!--
            Warning about the use of a non-free component. Attention! The MDBChip component is provided under the
            "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
            open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase the
             specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or make
             changes to this source code to eliminate the use of non-free components.
           -->
          <MDBChip close :id="plate.id" v-for="plate in huntingStore.carPlatesOnCurrentPhoto" :key="plate.id" @close-chip="dropCarPlate(plate)">
            {{ plate.plateNumber }}
          </MDBChip>
        </div>
        <div v-else>
          <strong>{{ $t('hunting.neuralNetworkDidNotDetectLicensePlates') }}</strong>
        </div>
        <MDBSpinner class="ms-auto" grow size="sm" v-if='notRecognized || notGeocoded' />
        <MDBSpinner class="ms-auto" size="sm" v-else-if='huntingStore.carPlatesLoadingInProgress' />
      </div>
    </MDBAlert>
  </MDBCol>
</template>
<script  lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { MDBAlert, MDBChip, MDBCol, MDBSpinner } from 'mdb-vue-ui-kit'
import { useHuntingStore } from '@/store/hunting'
import { useAppStore } from '@/store/app'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import { CarPlate } from '@/store/models/car-plate.model'


export default  defineComponent({
  name: "GeoPhotoProcessingStatusAlertContainer",
  components: { MDBAlert, MDBCol, MDBSpinner, MDBChip },
  computed: {
    notRecognized() {
      return this.huntingStore.currentPhoto != null && !this.huntingStore.currentPhoto?.isRecognized
    },
    notGeocoded() {
      return this.huntingStore.currentPhoto != null && !this.huntingStore.currentPhoto?.isGeocoded
    },
    havePlates() {
      return this.huntingStore.carPlatesOnCurrentPhoto.length > 0
    },
    geoPhotoUploading() {
      return this.huntingStore.currentPhoto && !this.huntingStore.currentPhoto?.uploaded
    },
    geoPhotoRejectedBecauseAlreadyExists() {
      return this.huntingStore.currentPhoto?.moderationStatus === MODERATION_STATUS.REJECTED_REASON_VIOLATION_ALREADY_EXISTS
    },
  },
  methods: {
    dropCarPlate(plate: CarPlate) {
      if(this.huntingStore.carPlatesOnCurrentPhoto.length < 2) {
        this.huntingStore.cancelCurrentGeoPhotoUpload();
        this.appStore.addWarningMessage(this.$t("hunting.allCarPlatesWasRemoved"), true, 5000, true);
      } else {
        this.huntingStore.deleteCarPlate(plate.id)
        this.appStore.addSuccessMessage(this.$t("hunting.carPlateRemoved", { plate: plate.plateNumber}), true, 2000, true);
      }
    },
  },
  setup() {
    const huntingStore = useHuntingStore();
    const appStore = useAppStore();

    return {
      huntingStore,
      appStore,
    }
  }
});
</script>
