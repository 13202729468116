<template>
  <MDBStepperStep :dynamic='true'>
    <MDBStepperHead>
      <template #icon>
        <MDBIcon fas icon="piggy-bank" />
      </template>
      {{ $t('moderatorView.violationType') }}
    </MDBStepperHead>
    <MDBStepperContent>
      <div class='vstack gap-1 mt-0 pt-0'>
        <MDBCard class='console-card'>
          <MDBCardHeader class='m-0 p-0 rounded-top-2 border-0'>
            <!--
              Warning about the use of a non-free component. Attention! The MDBLightbox component is provided under
              the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as part of
              open-source software. To run the "frontend" app of the OinkOinkCar system, you must either purchase
              the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing) or
              make changes to this source code to eliminate the use of non-free components.
            -->
            <MDBLightbox v-if='currentViolationSrcPhoto'>
              <MDBLightboxItem
                :src="currentViolationSrcPhoto"
                :fullScreenSrc="currentViolationSrcPhoto"
                :alt="$t('moderatorView.photoOfViolation')"
                className='rounded-top-5 object-cover w-100 console-card-img-top'
              />
            </MDBLightbox>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBCardText>
              <label for="currentViolationTypeInput" class="form-label">{{$t('moderatorView.checkAndConfirmViolationType')}}</label>
              <MDBInput
                id='currentViolationTypeInput'
                inputGroup
                :formOutline="false"
                wrapperClass="mb-3"
                v-model="currentViolationTypeName"
                readonly
              >
                <MDBBtn color="primary" @click='ifaceStore.showViolationTypeSelectorModal' class='ps-3 pe-3'><MDBIcon iconStyle="fas" icon="ellipsis-h" size="sm"></MDBIcon></MDBBtn>
              </MDBInput>
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter class='p-2'>
            <div class='hstack gap-2'>
              <MDBBtn
                color="danger"
                @click="ifaceStore.showRejectReasonSelectorModal"
                class='me-auto'
              >
                <i class="fas fa-ban me-2"/>
                {{ $t('moderatorView.reject') }}
              </MDBBtn>
              <!--
                Warning about the use of a non-free component. Attention! The MDBPopconfirm component is provided
                under the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as
                part of open-source software. To run the "frontend" app of the OinkOinkCar system, you must either
                purchase the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing)
                or make changes to this source code to eliminate the use of non-free components.
              -->
              <MDBPopconfirm
                class="btn-success ms-auto"
                position="top left"
                :message="$t('moderatorView.confirmViolationTypeConfirm')"
                :cancelText="$t('moderatorView.cancel')"
                :confirmText="$t('moderatorView.confirm')"
                @confirm='confirmViolationType'
              >
                <i class="fas fa-check-double me-2"></i>
                {{ $t('moderatorView.confirm') }}
              </MDBPopconfirm>
            </div>
          </MDBCardFooter>
        </MDBCard>
      </div>
    </MDBStepperContent>
  </MDBStepperStep>
</template>
<script  lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardText, MDBIcon, MDBInput, MDBLightbox, MDBLightboxItem, MDBPopconfirm, MDBStepperContent, MDBStepperHead, MDBStepperStep } from 'mdb-vue-ui-kit'
import { useModerationStore } from '@/store/moderation'
import { useIfaceStore } from '@/store/iface'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import i18n from '@/i18n/vue-i18n'
import { MODERATION_STEP } from '@/helpers/enums/moderation-step'
import { useAppStore } from '@/store/app'
import { useViolationTypesStore } from '@/store/violation-types'


export default  defineComponent({
  name: "ModerationStepViolationTypeCheckContainer",
  components: {
    MDBInput, MDBLightbox, MDBBtn, MDBIcon, MDBStepperContent, MDBCard, MDBCardText, MDBStepperHead, MDBCardHeader,
    MDBLightboxItem, MDBCardFooter, MDBPopconfirm, MDBStepperStep, MDBCardBody
  },
  methods: {
    confirmViolationType() {
      this.moderationStore.confirmViolation();
      this.appStore.addSuccessMessage(i18n.global.t('moderatorView.violationTypeConfirmed'), true, 2000);
    }
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const appStore = useAppStore();
    const moderationStore = useModerationStore();
    const violationTypesStore = useViolationTypesStore();
    const currentViolationTypeName = ref<string | null>(null);
    const currentViolationSrcPhoto = ref("");

    function rejectionCallback(reason: MODERATION_STATUS) {
      moderationStore.rejectViolation(reason);
      appStore.addSuccessMessage(i18n.global.t('moderatorView.violationTypeRejected'), true, 2000);
    }

    function configureStep() {
      if (moderationStore.currentModerationStep === MODERATION_STEP.VIOLATION_TYPE_CHECK) {
        ifaceStore.setupRejectReasonSelectorModal(
          [
            MODERATION_STATUS.REJECTED_REASON_VIOLATION_TYPE_NOT_CONFIRMED,
            MODERATION_STATUS.REJECTED_REASON_FLOOD,
            MODERATION_STATUS.REJECTED_REASON_ADULT,
            MODERATION_STATUS.REJECTED_REASON_OTHER,
          ],
          i18n.global.t('moderatorView.rejectViolationTypeModalTitle'),
          i18n.global.t('moderatorView.rejectViolationTypeModalText'),
          rejectionCallback,
        );
      }
    }

    function violationTypeChangeCallback(violationTypeId: string) {
      moderationStore.changeViolationTypeDuringModeration(violationTypeId);
      appStore.addSuccessMessage(i18n.global.t('moderatorView.violationTypeChanged'), true, 2000);
    }

    function configureViolationTypeSelectorModal() {
      const currentPointCoordinates = moderationStore.currentViolationForModeration?.carPlate?.photo.geom.coordinates;
      if (currentPointCoordinates) {
        violationTypesStore.getAvailableTypesOfViolationsInPoint(currentPointCoordinates[1], currentPointCoordinates[0]).then((result) => {
          ifaceStore.setupViolationTypeSelectorModal(
            result.types,
            moderationStore.currentViolationForModeration?.violationType.id,
            violationTypeChangeCallback,
          );
        });
      }
    }

    watch(() => moderationStore.currentModerationStep, (value) => {
      if (value === MODERATION_STEP.VIOLATION_TYPE_CHECK) {
        configureStep();
        configureViolationTypeSelectorModal();
      }
    });
    onMounted(() => {
      configureStep();
      configureViolationTypeSelectorModal();
      currentViolationSrcPhoto.value = moderationStore.currentViolationForModeration?.carPlate.photo.srcPhoto;
    });
    watch(() => moderationStore.currentViolationForModeration, (value, oldValue) => {
      if(value && value?.carPlate?.photo.geom.coordinates !== oldValue?.carPlate?.photo.geom.coordinates) {
        configureViolationTypeSelectorModal();
      }
      if(value && value?.id !== oldValue?.id) {
        currentViolationSrcPhoto.value = value.carPlate.photo.srcPhoto;
      }
      if(value) {
        currentViolationTypeName.value = value.violationType.name;
      }
    });

    return {
      ifaceStore,
      appStore,
      moderationStore,
      currentViolationTypeName,
      currentViolationSrcPhoto,
    }
  }
});
</script>
