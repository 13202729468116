const sitemap = {
  header: "КАРТА САЙТА | ОСНОВНЫЕ РАЗДЕЛЫ",
  violationMap: "Карта нарушений",
  projectManifesto: "Манифест проекта",
  hunt: "Охота за нарушениями (для атворизованных пользователей)",
  profile: "Профиль (для атворизованных пользователей)",
  signIn: "Вход (для неаутентифицированных пользователей)",
  feedBackForms: "Формы обратной связи",
  photoDeleteRequest: "Запрос на удаление фотографии с нарушением",
  placeOrViolationAddRequest: "Запрос на добавление территории или вида нарушения",
  generalQuestions: "Форма обратной связи (общие вопросы)",
  violation: "Нарушения",
  violationList: "Все нарушения списком",
  backToMainSections: "Назад к основным разделам",
  more: "Подробнее",
  ourTelegramChannel: "Наш канал в Telegram",
};

export default sitemap;
