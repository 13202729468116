// src/store/violation-types.ts
// Module for violation types store.
import { defineStore } from 'pinia';
import { objectsModule } from '@/api/objects';
import { PLACE_STATUS } from '@/helpers/enums/place-status'
import { TypeOfViolation } from '@/store/models/type-of-violation.model'
import { TypeOfViolationForSelect } from '@/store/models/type-of-violation.model'
import {
  convertTypeOfViolationForSelect,
  mapApiTypeOfViolationToStoreTypeOfViolation
} from '@/helpers/mappers/type-of-violation.mapper'
import { useCacheStore } from '@/store/cache'
import axios from 'axios'
import { arrayBufferToBase64 } from '@/helpers/functions'
import { useAppStore } from '@/store/app'


// TODO: all actions which use unsafe methods must use idempotency keys
export const useViolationTypesStore = defineStore('violation_types', {
  state: () => ({
    isInitialized: false as boolean,
    placeStatus: PLACE_STATUS.NOT_INIT as PLACE_STATUS,
    allTypesOfViolations: [] as TypeOfViolation[],
    allTypesOfViolationsForSelect: [] as TypeOfViolationForSelect[],
    availableTypesOfViolations: [] as TypeOfViolation[],
    availableTypesOfViolationsForSelect: [] as TypeOfViolationForSelect[],
    selectedTypeOfViolation: null as TypeOfViolation | null,
  }),

  actions: {

    async initStore() {
      if (this.isInitialized) {
        return;
      }
      this.isInitialized = true;
      const cacheStore = useCacheStore();
      cacheStore.initializeDatabase();
      await this.loadAllTypesOfViolations()
    },

    async setAllTypesOfViolations(types: TypeOfViolation[]) {
      this.allTypesOfViolations = types;
      this.allTypesOfViolationsForSelect = [];
      await this.cacheImagesAndIconsOfTypesOfViolations(this.allTypesOfViolations);
      for (let t of this.allTypesOfViolations) {
        this.allTypesOfViolationsForSelect.push(convertTypeOfViolationForSelect(t));
      }
    },

    async cacheImagesAndIconsOfTypesOfViolations(violationTypes: TypeOfViolation[]) {
      const cacheStore = useCacheStore();
      const appStore = useAppStore();
      for (const vt of violationTypes) {
        for (const field of ['image', 'icon']) {
          if(vt[field]) {
            const isLink = typeof vt[field] === 'string' && vt[field].startsWith('https://');
            if (isLink) {
              try {
                const dateTime = vt.modifiedAt ? vt.modifiedAt : vt.createdAt
                const dateStr = dateTime ? `${dateTime.getFullYear()}-${dateTime.getMonth() + 1}-${dateTime.getDate()}-${dateTime.getHours()}-${dateTime.getMinutes()}-${dateTime.getSeconds()}` : 'unknown-date';
                const cachedBase64 = await cacheStore.getCachedBase64(`TypeOfViolation_${field}_${dateStr}_${vt.id}`);
                if (cachedBase64) {
                  vt[field] = cachedBase64;
                } else {
                  const response = await axios.get(vt[field], { responseType: 'arraybuffer' });
                  const mimeType = 'image/jpeg'; // FIXME: get mimeType from response
                  const base64 = `data:${mimeType};base64,${arrayBufferToBase64(response.data)}`;
                  vt[field] = base64;
                  await cacheStore.saveToIndexedDB(`TypeOfViolation_${field}_${dateStr}_${vt.id}`, base64);
                }
              } catch (error) {
                appStore.addErrorMessage(`Ошибка при кешировании изображения типа нарушения: ${error}`);
              }
            }
          }
        }
      }
    },

    async setAvailableTypesOfViolations(types: TypeOfViolation[]) {
      this.availableTypesOfViolations = types;
      await this.cacheImagesAndIconsOfTypesOfViolations(this.availableTypesOfViolations);
      let selectedIsAvailable = false;
      for (let type of this.availableTypesOfViolations) {
        if (this.selectedTypeOfViolation?.id === type.id) {
          selectedIsAvailable = true;
        }
      }
      if (this.availableTypesOfViolations.length > 0) {
        if(!selectedIsAvailable) {
          await this.setSelectedTypeOfViolation(this.availableTypesOfViolations[0]);
        } else {
          await this.cacheImagesAndIconsOfTypesOfViolations([this.selectedTypeOfViolation]);
        }
      } else {
        await this.setSelectedTypeOfViolation(null);
      }
      this.availableTypesOfViolationsForSelect = [];
      for (let t of this.availableTypesOfViolations) {
        this.availableTypesOfViolationsForSelect.push(convertTypeOfViolationForSelect(t));
      }
    },

    async setSelectedTypeOfViolationById(id: string | null) {
      if (id == null) {
        await this.setSelectedTypeOfViolation(null);
        return;
      }
      const type = this.allTypesOfViolations.find((type: TypeOfViolation) => type.id === id);
      await this.setSelectedTypeOfViolation(type);
    },

    async setSelectedTypeOfViolation(type: TypeOfViolation | null) {
      this.selectedTypeOfViolation = type;
    },

    async loadAllTypesOfViolations() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const response = await objectsModule.getAllTypeOfViolations();
        if (response?.status === 200) {
          await this.setAllTypesOfViolations(response.data.map(mapApiTypeOfViolationToStoreTypeOfViolation));
          if (this.allTypesOfViolations.length > 0) {
            if (this.selectedTypeOfViolation == null) {
              await this.setSelectedTypeOfViolation(this.allTypesOfViolations[0]);
            }
          }
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке всех типов нарушений, неожиданный ответ сервера: ${response?.status} ${response?.statusText}`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке всех типов нарушений: ${error}`);
        if (error.response?.status >= 500 || error.response?.status == 408) {
          setTimeout(() => { this.loadAllTypesOfViolations() }, Math.max(5000 * Math.random(), 2000));
        }
      }
    },

    async updateAvailableTypesOfViolationsInLocation() {
      const appStore = useAppStore();
      const location = await appStore.getCurrentLocation(false);
      if(!location) {
        appStore.addErrorMessage("Ошибка при загрузке доступных типов нарушений (координаты не определены)");
        return;
      }
      await this.loadAvailableTypesOfViolationsInPoint(location.coords.latitude, location.coords.longitude);
    },

    async loadAvailableTypesOfViolationsInPoint(latitude: number, longitude: number) {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const { types, place_status } = await this.getAvailableTypesOfViolationsInPoint(latitude, longitude);
        this.placeStatus = place_status;
        await this.setAvailableTypesOfViolations(types);
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке доступных типов нарушений: ${error}`);
        if (error.response?.status >= 500 || error.response?.status == 408) {
          setTimeout(() => { this.loadAvailableTypesOfViolationsInPoint(latitude, longitude) }, Math.max(5000 * Math.random(), 2000));
        }
      }
    },

    async getAvailableTypesOfViolationsInPoint(latitude: number, longitude: number): Promise<{types: TypeOfViolation[], place_status:  PLACE_STATUS} | null> {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const result = {
        types: [] as TypeOfViolation[],
        place_status: PLACE_STATUS.VIOLATION_TYPES_NOT_FOUND as PLACE_STATUS
      }
      try {
        const response = await objectsModule.getTypeOfViolationsInPoint(latitude, longitude);
        if (response?.status === 200) {
          result.place_status = response.headers['ooc_x_place'];
          result.types = response.data.map(mapApiTypeOfViolationToStoreTypeOfViolation);
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке доступных типов нарушений, неожиданный ответ сервера: ${response?.status} ${response?.statusText}`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке доступных типов нарушений: ${error}`);
      }
      return result;
    },
  }
});
