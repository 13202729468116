const header = {
  brandName: "OinkOinkCar",
  brandSlogan: "Normal guys say \"Bla-Bla\" and don’t park their cars on lawns!",
  feedback: "Feedback",
  about: "About project",
  myProfile: "My profile",
  settings: "Settings",
  logout: "Logout",
};

export default header;
