const mdb = {
  datePicker: {
    startDay: "1", // 0 - Sunday, 1 - Monday
    cancelBtnLabel: 'Отменить выбор',
    cancelBtnText: 'Отмена',
    clearBtnLabel: 'Очистить выбор',
    clearBtnText: 'Очистить',
    format: 'DD.MM.YYYY',
    monthsFull: {
      0: 'Январь',
      1: 'Февраль',
      2: 'Март',
      3: 'Апрель',
      4: 'Май',
      5: 'Июнь',
      6: 'Июль',
      7: 'Август',
      8: 'Сентябрь',
      9: 'Октябрь',
      10: 'Ноябрь',
      11: 'Декабрь',
    },
    monthsShort: {
      0: 'Янв',
      1: 'Фев',
      2: 'Мар',
      3: 'Апр',
      4: 'Май',
      5: 'Июн',
      6: 'Июл',
      7: 'Авг',
      8: 'Сен',
      9: 'Окт',
      10: 'Ноя',
      11: 'Дек',
    },
    nextMonthLabel: 'Следующий месяц',
    nextMultiYearLabel: 'Следующие 24 года',
    nextYearLabel: 'Следующий год',
    okBtnLabel: 'Подтвердить выбор',
    okBtnText: 'Ок',
    prevMonthLabel: 'Предыдущий месяц',
    prevMultiYearLabel: 'Предыдущие 24 года',
    prevYearLabel: 'Предыдущий год',
    switchToDayViewLabel: 'Выбрать дату',
    switchToMultiYearViewLabel: 'Выбрать месяц и год',
    title: 'Выберите дату',
    weekdaysFull: {
      0: 'Воскресенье',
      1: 'Понедельник',
      2: 'Вторник',
      3: 'Среда ',
      4: 'Четверг',
      5: 'Пятница',
      6: 'Суббота',
    },
    weekdaysNarrow: {
      0: 'В',
      1: 'П',
      2: 'В',
      3: 'С',
      4: 'Ч',
      5: 'П',
      6: 'С',
    },
    weekdaysShort:
    {
      0: 'Вс',
      1: 'Пн',
      2: 'Вт',
      3: 'Ср',
      4: 'Чт',
      5: 'Пт',
      6: 'Сб',
    },
  }
};

export default mdb;
