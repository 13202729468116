import { createRouter, createWebHistory } from 'vue-router'
import i18n from "@/i18n/vue-i18n";
import HomeView from '@/ui/views/HomeView.vue'
import HuntView from '@/ui/views/HuntView.vue'
import ModeratorView from '@/ui/views/ModeratorView.vue'
import PrivacyView from '@/ui/views/PrivacyView.vue'
import RulesView from '@/ui/views/RulesView.vue'
import AuthErrorView from '@/ui/views/AuthErrorView.vue'
import { useMapStore } from '@/store/map'
import {
  HOME_PATH,
  HOME_ROUTE_NAME,
  HUNTING_PATH,
  HUNTING_ROUTE_NAME,
  MANIFESTO_PATH,
  MANIFESTO_ROUTE_NAME,
  SIGN_IN_PATH,
  SIGN_IN_ROUTE_NAME,
  HUNT_PATH,
  HUNT_ROUTE_NAME,
  MODERATOR_PATH,
  MODERATOR_ROUTE_NAME,
  PRIVACY_PATH,
  PRIVACY_ROUTE_NAME,
  ERROR_AUTH_PATH, ERROR_AUTH_ROUTE_NAME, RULES_PATH, RULES_ROUTE_NAME
} from '@/helpers/constants/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: HOME_PATH,
      name: HOME_ROUTE_NAME,
      component: HomeView,
      meta: { titleKey: 'pageTitles.map' },
      props: (route) => ({ violation: route.query.violation || null }),
    },
    {
      path: HUNTING_PATH,
      name: HUNTING_ROUTE_NAME,
      component: HomeView,
      meta: { titleKey: 'pageTitles.hunting' },
      },
    {
      path: MANIFESTO_PATH,
      name: MANIFESTO_ROUTE_NAME,
      component: HomeView,
      meta: { titleKey: 'pageTitles.manifesto' },
    },
    {
      path: SIGN_IN_PATH,
      name: SIGN_IN_ROUTE_NAME,
      component: HomeView,
      meta: { titleKey: 'pageTitles.sign_in' },
    },
    {
      path: HUNT_PATH,
      name: HUNT_ROUTE_NAME,
      component: HuntView,
      meta: { titleKey: 'pageTitles.hunt' },
    },
    {
      path: MODERATOR_PATH,
      name: MODERATOR_ROUTE_NAME,
      component: ModeratorView,
      meta: { titleKey: 'pageTitles.moderator' },
    },
    {
      path: PRIVACY_PATH,
      name: PRIVACY_ROUTE_NAME,
      component: PrivacyView,
      meta: { titleKey: 'pageTitles.privacy' },
    },
    {
      path: RULES_PATH,
      name: RULES_ROUTE_NAME,
      component: RulesView,
      meta: { titleKey: 'pageTitles.rules' },
    },
    {
      path: ERROR_AUTH_PATH,
      name: ERROR_AUTH_ROUTE_NAME,
      component: AuthErrorView,
      meta: { titleKey: 'pageTitles.auth_error' },
      props: (route) => ({ errorType: String(route.params.errorType) }),
    }
  ]
})

router.beforeEach((to, from, next) => {
  // FIXME: Temporary redirect for the app.xxkap.app domain
  if (window.location.hostname === 'app.xxkap.app') {
    window.location.href = 'https://xxkap.app';
  } else {
    const mapStore = useMapStore();
    let titleKey = to.meta.titleKey;
    if(titleKey === undefined) {
      titleKey = 'pageTitles.default';
    }
    document.title = i18n.global.t(String(titleKey));
    const violationId = to.query.violation as string | undefined;
    if(violationId !== undefined) {
      mapStore.loadViolationDetails(violationId);
    }
    next();
  }
})

export default router
