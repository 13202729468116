import {
  TypeOfViolation as StoreTypeOfViolation,
  TypeOfViolationForMap as StoreTypeOfViolationForMap,
} from '@/store/models/type-of-violation.model';
import {
  TypeOfViolation as ApiTypeOfViolation,
  TypeOfViolationForMap as ApiTypeOfViolationForMap,
} from '@/api/models/type-of-violation.model';
import { TypeOfViolationForSelect } from '@/store/models/type-of-violation.model';

export function convertTypeOfViolationForSelect(type: StoreTypeOfViolation): TypeOfViolationForSelect {
  return {
    text: type.name,
    value: type.id,
    icon: type.icon,
    disabled: false,
  };
}

export function mapApiTypeOfViolationToStoreTypeOfViolation(type: ApiTypeOfViolation): StoreTypeOfViolation {
  return {
    id: type.id,
    modifiedAt: type.modified_at? new Date(type.modified_at) : null,
    createdAt: new Date(type.created_at),
    name: type.name,
    description: type.description ?? null,
    image: type.image ?? null,
    icon: type.icon ?? null,
  };
}

export function mapApiTypeOfViolationForMapToStoreTypeOfViolationForMap(type: ApiTypeOfViolationForMap): StoreTypeOfViolationForMap {
  return {
    id: type.id,
    name: type.name,
    description: type.description ?? null,
    icon: type.icon ?? null,
  };
}
