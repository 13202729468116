<template>
  <MDBStepperStep v-if="haveApplicants" :dynamic="true" class='application-step'>
    <MDBStepperHead>
      <template #icon>
        <MDBIcon fas icon="file-signature" />
      </template>
      {{ $t('moderatorView.generateAndSendApplication') }}
    </MDBStepperHead>
    <MDBStepperContent>
      <div class='vstack gap-1 mt-0 pt-0'>
        <MDBCard class='console-card'>
          <MDBCardBody>
            <MDBCardText>
              <p class="lead" v-if='applicationsStore.applicationGenerationInProgress'>{{ $t('moderatorView.generatingApplication') }}</p>
              <p class="lead" v-else>{{ $t('moderatorView.applicationGeneratedAndReadyToSend') }}</p>
            </MDBCardText>
            <MDBCardText>
              <MDBBtn class='ps-3' v-if='applicationsStore.currentApplication?.pdfFile && !applicationsStore.applicationGenerationInProgress' color="secondary" tag="a" target="_blank" :href="applicationsStore.currentApplication?.pdfFile">
                <i class="fas fa-file-pdf me-2 ms-0"></i>{{ $t('moderatorView.openInNexTab')}}
              </MDBBtn>
              <h5 class="card-title placeholder-glow" v-if='applicationsStore.applicationGenerationInProgress || applicationsStore.currentApplication == null'>
                <span class="placeholder col-3"></span>
              </h5>
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter class='p-2'>
            <div class='hstack gap-2'>
              <MDBBtn
                color="danger"
                @click="ifaceStore.showRejectReasonSelectorModal"
                class='me-auto'
                :disabled='applicationsStore.applicationGenerationInProgress || applicationsStore.currentApplication == null'
              >
                <i class="fas fa-minus-circle me-2"></i>
                {{ $t('moderatorView.reject') }}
              </MDBBtn>
              <!--
                Warning about the use of a non-free component. Attention! The MDBPopconfirm component is provided
                under the "MDB Vue Essential license" (EULA), which prohibits the distribution of source code as
                part of open-source software. To run the "frontend" app of the OinkOinkCar system, you must either
                purchase the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing)
                or make changes to this source code to eliminate the use of non-free components.
              -->
              <MDBPopconfirm
                class="btn-success ms-auto"
                position="top left"
                :message="$t('moderatorView.confirmApplicationConfirm')"
                :cancelText="$t('moderatorView.cancel')"
                :confirmText="$t('moderatorView.send')"
                @confirm='confirmApplication'
                :disabled='applicationsStore.applicationGenerationInProgress || applicationsStore.currentApplication === null'
              >
                <i class="fas fa-paper-plane me-2"></i>
                {{ $t('moderatorView.send') }}
              </MDBPopconfirm>
            </div>
          </MDBCardFooter>
        </MDBCard>
      </div>
    </MDBStepperContent>
  </MDBStepperStep>
</template>
<script  lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardText, MDBIcon, MDBPopconfirm, MDBStepperContent, MDBStepperHead, MDBStepperStep } from 'mdb-vue-ui-kit'
import { useModerationStore } from '@/store/moderation';
import { useIfaceStore } from '@/store/iface';
import i18n from '@/i18n/vue-i18n';
import { MODERATION_STEP } from '@/helpers/enums/moderation-step';
import { useAppStore } from '@/store/app';
import { useApplicationsStore } from '@/store/applications';
import { APPLICATION_REJECT_REASON } from '@/helpers/enums/application-reject-reason';


export default  defineComponent({
  name: "ModerationStepApplicationReviewContainer",
  components: {MDBCardText, MDBStepperHead, MDBCard, MDBBtn, MDBIcon, MDBStepperContent, MDBCardBody, MDBCardFooter, MDBPopconfirm, MDBStepperStep },
  methods: {
    confirmApplication() {
      this.applicationsStore.confirmApplication(this.applicationsStore.currentApplication?.id).then(() => {
        this.appStore.addSuccessMessage(this.$t('moderatorView.applicationConfirmed'), true, 2000);
      });
    },
  },
  setup() {
    const ifaceStore = useIfaceStore();
    const appStore = useAppStore();
    const applicationsStore = useApplicationsStore();
    const moderationStore = useModerationStore();
    const haveApplicants = ref(applicationsStore.applicants.length > 0);

    function rejectionCallback(reason: APPLICATION_REJECT_REASON) {
      applicationsStore.rejectApplication(applicationsStore.currentApplication?.id, reason);
      appStore.addSuccessMessage(i18n.global.t('moderatorView.applicationRejected'), true, 2000);
    }

    function configureStep() {
      if (moderationStore.currentModerationStep === MODERATION_STEP.APPLICATION_REVIEW) {
        haveApplicants.value = applicationsStore.applicants.length > 0;
        ifaceStore.setupRejectReasonSelectorModal(
          [
            APPLICATION_REJECT_REASON.REJECTED_REASON_AUTHORITY_ERROR,
            APPLICATION_REJECT_REASON.REJECTED_REASON_APPLICANT_ERROR,
            APPLICATION_REJECT_REASON.REJECTED_REASON_LAYOUT_ERROR,
            APPLICATION_REJECT_REASON.REJECTED_REASON_MODERATION_ERROR,
            APPLICATION_REJECT_REASON.REJECTED_REASON_ENCODING_ERROR,
            APPLICATION_REJECT_REASON.REJECTED_REASON_TIMEOUT,
            APPLICATION_REJECT_REASON.REJECTED_REASON_FLOOD,
            APPLICATION_REJECT_REASON.REJECTED_REASON_ADULT,
            APPLICATION_REJECT_REASON.REJECTED_REASON_OTHER,
          ],
          i18n.global.t('moderatorView.rejectApplicationModalTitle'),
          i18n.global.t('moderatorView.rejectApplicationModalText'),
          rejectionCallback,
        );
      }
    }

    watch(() => moderationStore.currentModerationStep, (value) => {
      if (value === MODERATION_STEP.APPLICATION_REVIEW) {
        configureStep();
      }
    });
    onMounted(() => {
      configureStep();
    });

    watch(() => applicationsStore.applicants, (value) => {
      haveApplicants.value = value.length > 0;
    });

    return {
      ifaceStore,
      applicationsStore,
      haveApplicants,
      appStore,
    }
  }
});
</script>
<style scoped lang='scss'>
.application-step {
  min-height: 218px !important;
}
</style>
