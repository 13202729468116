import type { User as ApiUser } from "@/api/models/user.model";
import type { User as StoreUser } from "@/store/models/user.model";
import { OAUTH_PROVIDER } from "@/helpers/enums/oauth-provider";

export function mapApiUserToStoreUser(apiUser: ApiUser): StoreUser {
  const dateJoined = apiUser.date_joined ? new Date(apiUser.date_joined) : undefined;
  const modifiedAt = apiUser.modified_at ? new Date(apiUser.modified_at) : undefined;
  const createdAt = apiUser.created_at ? new Date(apiUser.created_at) : undefined;
  let oauthProvider = undefined;
  if (apiUser.oauth_provider) {
    switch (apiUser.oauth_provider) {
      case `${OAUTH_PROVIDER.GOOGLE}`:
        oauthProvider = OAUTH_PROVIDER.GOOGLE;
        break;
      case `${OAUTH_PROVIDER.VKONTAKTE}`:
        oauthProvider = OAUTH_PROVIDER.VKONTAKTE;
        break;
      default:
        oauthProvider = undefined;
        break;
    }
  }

  return {
    id: apiUser.id,
    email: apiUser.email,
    username: apiUser.username,
    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
    isStaff: apiUser.is_staff,
    isActive: apiUser.is_active,
    isSuperuser: apiUser.is_superuser,
    dateJoined: dateJoined,
    modifiedAt: modifiedAt,
    createdAt: createdAt,
    oauthProvider: oauthProvider,
    isModerator: apiUser.groups ? apiUser.groups.includes("Moderator") : false,
    isBanned: apiUser.groups ? apiUser.groups.includes("Banned") : false,
  };
}

export function mapStoreUserToApiUser(storeUser: StoreUser): ApiUser {
  return {
    id: storeUser.id,
    email: storeUser.email,
    username: storeUser.username,
    first_name: storeUser.firstName,
    last_name: storeUser.lastName,
    is_staff: storeUser.isStaff,
    is_active: storeUser.isActive,
    is_superuser: storeUser.isSuperuser,
    date_joined: storeUser.dateJoined ? storeUser.dateJoined.toISOString() : undefined,
    modified_at: storeUser.modifiedAt ? storeUser.modifiedAt.toISOString() : undefined,
    created_at: storeUser.createdAt ? storeUser.createdAt.toISOString() : undefined,
    oauth_provider: storeUser.oauthProvider ? storeUser.oauthProvider.toString() : undefined,
  };
}
