<template>
  <div class="header" v-if='isVisible'>
    <MDBNavbar container expand="lg" bg="light" class="d-flex justify-content-between">
      <MDBNavbarBrand @click="goHome" class="brand-container">
        <div class="logo">
          <img src='@/ui/assets/images/logo.png' alt="logo" loading="lazy" />
        </div>
        <div class="title">
          <h1 class="title-text">{{ $t("header.brandName") }}</h1>
        </div>
      </MDBNavbarBrand>
    </MDBNavbar>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MDBNavbar, MDBNavbarBrand } from "mdb-vue-ui-kit";
import { HOME_PATH, HUNT_PATH, MODERATOR_PATH } from '@/helpers/constants/routes'

export default defineComponent({
  name: "Header",
  components: {
    MDBNavbar,
    MDBNavbarBrand,
  },
  methods: {
    goHome() {
      return this.$router.push(HOME_PATH);
    },
  },
  computed: {
    isVisible() {
      return this.$route.path !== HUNT_PATH && this.$route.path !== MODERATOR_PATH
    },
  },
});
</script>
<style scoped lang="scss">
.header {
  z-index: 200;
  height: var(--header-height);
  @media screen and (max-width: 392px) {
    height: calc(var(--header-height) * 0.85);
  }
}
.brand-container {
  cursor: pointer;
  margin-right: auto !important;
  margin-left: 0.5em !important;
  display: flex;
  align-items: center;
  .logo {
    margin-right: 0.5em;
  }
  .title-text {
    font-family: 'Maler', sans-serif;
    color: #7e643d;
    font-size: 48px;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 392px) {
      font-size: 36px;
    }
  }
  .logo img {
    width: 54px;
    height: 54px;
  }
  @media screen and (max-width: 392px) {
    .logo img {
      width: 48px;
      height: 48px;
    }
  }
}
</style>
