import about from "./about";
import feedback from "./feedback";
import footer from "./footer";
import header from "./header";
import home from "./home";
import profile from "./profile";
import settings from "./settings";
import sitemap from "./sitemap";
import application from "@/i18n/en/application";
import auth from "@/i18n/en/auth";
import locale from "@/i18n/ru/locale";
import content from "@/i18n/en/content";
import filter from "@/i18n/en/filter";
import hunting  from "@/i18n/en/hunting";
import mdb from "@/i18n/en/mdb";
import moderatorView from "@/i18n/en/moderator-view";
import pageTitles from "@/i18n/en/page-titles";

const en = {
  about,
  feedback,
  footer,
  header,
  home,
  profile,
  settings,
  sitemap,
  application,
  auth,
  locale,
  content,
  filter,
  hunting,
  mdb,
  moderatorView,
  pageTitles,
};

export default en;
