// src/store/locale.ts
import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';
import { useAppStore } from '@/store/app';
import { LOCALE_EN, LOCALE_RU } from '@/helpers/constants/locale';
import { availableLanguages } from '@/i18n/vue-i18n';


const { cookies } = useCookies();

export const startLanguage = cookies.get("oink-locale") || LOCALE_RU

export const useLocaleStore = defineStore('locale', {
  state: () => ({
    currentLocale: startLanguage as string,
    availableLocales: [...availableLanguages] as string[],
    localesWithMachineTranslation: [LOCALE_EN] as string[],
    localeToFlagCodeMap: {
      en: 'united-kingdom',
      ru: 'russia',
    } as { [key: string]: string },
    localeToInternationalLanguageMap: {
      en: 'English',
      ru: 'Russian',
    } as { [key: string]: string },
    localeToLocalLanguageMap: {
      en: 'English',
      ru: 'Русский',
    } as { [key: string]: string },
  }),

  getters: {
    isTranslatedByMachine: (state) => (locale: string) =>
      state.localesWithMachineTranslation.includes(locale),
    getFlag: (state) => (locale) => {
      const appStore = useAppStore();
      if (state.availableLocales.includes(locale)) {
        return state.localeToFlagCodeMap[locale];
      } else {
        appStore.addDebugMessage(`Локаль ${locale} недоступна`);
        return '';
      }
    },
    getInternationalLanguage: (state) => (locale: string) => {
      if (state.availableLocales.includes(locale)) {
        return state.localeToInternationalLanguageMap[locale];
      } else {
        const appStore = useAppStore();
        appStore.addDebugMessage(`Локаль ${locale} недоступна`);
        return '';
      }
    },
    getLocalLanguage: (state) => (locale: string) => {
      if (state.availableLocales.includes(locale)) {
        return state.localeToLocalLanguageMap[locale];
      } else {
        const appStore = useAppStore();
        appStore.addErrorMessage(`Локаль ${locale} недоступна`);
        return '';
      }
    },
  },

  actions: {
    async setCurrentLocale(locale: string) {
      const appStore = useAppStore();
      appStore.addDebugMessage(`Попытка установить локаль ${locale}`);
      if (this.availableLocales.includes(locale)) {
        this.currentLocale = locale;
        cookies.set('oink-locale', locale, '1y');
        appStore.addDebugMessage(`Локаль установлена на ${locale}`);
      } else {
        appStore.addErrorMessage(`Локаль ${locale} недоступна`);
      }
    },
  },

});
