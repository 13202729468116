import { MINIMUM_WINDOW_WIDTH, MINIMUM_WINDOW_HEIGHT } from "@/helpers/constants/application";
import { APP_STAGE } from "@/helpers/enums/app-stage";

const application = {
  smallWindowWarning: `Приложение рассчитано на размер окна браузера не менее ${MINIMUM_WINDOW_WIDTH}x${MINIMUM_WINDOW_HEIGHT}px. Окно вашего браузера имеет меньшее разрешение, некоторые элементы интерфейса могут отображаться неправильно.`,
  oinkOinkMan: "ХрюХрюМэн",
  oops: "Упс...",
  maintenanceMessage: "В настоящее время инженеры ХрюХрюКара что-то чинят и этот раздел временно не доступен... Возвращайтесь чуть позже, пожалуйста!",
  goHome: "На главную",
  next: "Далее",
  back: "Назад",
  telegram: "Подробнее в ТГ",
  version: "Версия: ",
  outdated: "(устаревшая)",
  stageButtonText: {
    [APP_STAGE.DEV]: "Режим разработки",
    [APP_STAGE.STAGING]: "Тестовый режим",
    [APP_STAGE.PROD]: "Рабочий режим",
  },
  stagePopoverText: {
    [APP_STAGE.DEV]: "В этом режиме регистрация пользователей закрыта. Не забудьте заранее создать тестового пользователя в базе данных или через консоль Django.",
    [APP_STAGE.STAGING]: "Это тестовый контур приложения ХрюХрюКар. Регистрация на этом сервере закрыта, а все фотографии не обязательно являются фотографиями нарушений и могут быть внесены только для тестирования. Индексирование сайта запрещено.",
    [APP_STAGE.PROD]: "Это рабочий контур приложения ХрюХрюКар.",
  },
  auth: {
    errorHeader: "Ошибка авторизации",
    signUpClosed: "Регистрация новых пользователей на тестовом сервере закрыта. Пожалуйста, перейдите на рабочий сервер.",
    writeToAdmins: "Поддержка",
    signInError: "Ошибка авторизации. Попробуйте чуть позже или обратитесь к администраторам.",
    yourAccountIsBlocked: "Ваш аккаунт заблокирован. Обратитесь к администраторам.",
  }
};

export default application;
