<template>
  <MDBModal v-model="visible" @hide="$router.push({ name: HUNTING_ROUTE_NAME })" centered>
    <MDBModalBody>
      <MDBCard>
        <MDBCardImg :src="placeStatus === VIOLATION_TYPES_NOT_FOUND ? '/violation-types-not-found.png' : '/place-not-place.png'" alt="Place not found" top />
        <MDBCardBody v-if='placeStatus === VIOLATION_TYPES_NOT_FOUND'>
          <MDBCardTitle>
            <h3>
              {{ $t('hunting.violationTypesNotFoundHeader') }}
            </h3>
          </MDBCardTitle>
          <MDBCardText>
            <p class='lead'>
              {{ $t('hunting.violationTypesNotFoundText') }}
            </p>
          </MDBCardText>
        </MDBCardBody>
        <MDBCardBody v-else>
          <MDBCardTitle>
            <h3>
              {{ $t('hunting.placeNotFoundHeader') }}
            </h3>
          </MDBCardTitle>
          <MDBCardText>
            <p class='lead'>
              {{ $t('hunting.placeNotFoundText') }}
            </p>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="link" tag="a" target="_blank" :href="placeStatus === VIOLATION_TYPES_NOT_FOUND ? URL_OF_FORM_TO_ADD_PLACE : URL_OF_REPOSITORY" class='me-auto'>
        {{ placeStatus === VIOLATION_TYPES_NOT_FOUND ? $t('hunting.violationTypesNotFoundButton') : $t('hunting.placeNotFoundButton') }}
      </MDBBtn>
      <MDBBtn class='ms-auto' color="secondary" @click="$router.push({ name: HUNTING_ROUTE_NAME })">
        {{ $t('hunting.back') }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>
<script  lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImg, MDBCardText, MDBCardTitle, MDBModal, MDBModalBody, MDBModalFooter } from 'mdb-vue-ui-kit'
import { useViolationTypesStore } from '@/store/violation-types'
import { PLACE_STATUS } from '@/helpers/enums/place-status'
import { HUNTING_ROUTE_NAME, URL_OF_FORM_TO_ADD_PLACE, URL_OF_REPOSITORY } from '@/helpers/constants/routes'

export default  defineComponent({
  name: "PlaceInformationModalContainer",
  components: { MDBModalFooter, MDBCard, MDBModalBody, MDBCardText, MDBBtn, MDBCardBody, MDBModal, MDBCardImg, MDBCardTitle },
  data() {
    return {
      VIOLATION_TYPES_NOT_FOUND: PLACE_STATUS.VIOLATION_TYPES_NOT_FOUND,
      URL_OF_FORM_TO_ADD_PLACE,
      URL_OF_REPOSITORY,
      HUNTING_ROUTE_NAME
    }
  },
  setup() {
    const violationTypesStore = useViolationTypesStore();
    const visible = ref(false);
    const placeStatus = ref<PLACE_STATUS>(violationTypesStore.placeStatus)

    watch(() => violationTypesStore.placeStatus,
      (newValue) => {
        placeStatus.value = newValue;
        visible.value = newValue === PLACE_STATUS.VIOLATION_TYPES_NOT_FOUND || newValue === PLACE_STATUS.PLACES_NOT_FOUND;
      }
    );

    onMounted(() => {
      placeStatus.value = violationTypesStore.placeStatus;
      visible.value = violationTypesStore.placeStatus === PLACE_STATUS.VIOLATION_TYPES_NOT_FOUND || violationTypesStore.placeStatus === PLACE_STATUS.PLACES_NOT_FOUND;
    });

    return {
      violationTypesStore,
      visible,
      placeStatus
    }
  }
});
</script>
