// src/store/moderation.ts
// Module for moderation store.
import { defineStore } from 'pinia';
import { objectsModule } from '@/api/objects';
import { useAppStore } from '@/store/app'
import { GeoPhotoModerationPatch } from '@/store/models/geophoto.model'
import { mapStoreGeoPhotoModerationPatchToApiGeoPhotoModerationPatch } from '@/helpers/mappers/geophoto.mapper'
import { useAuthStore } from '@/store/auth'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import { CarPlateForModeration } from '@/store/models/car-plate.model'
import { mapStoreCarPlateForModerationToApiCarPlateForModeration } from '@/helpers/mappers/car-plate.mapper'
import {
  mapApiViolationForModerationToStoreViolationForModeration,
  mapStoreViolationForModerationToApiViolationForModeration
} from '@/helpers/mappers/violation.mapper'
import { ViolationForModeration } from '@/store/models/violation.model'
import { useApplicationsStore } from '@/store/applications'
import { MODERATION_STEP } from '@/helpers/enums/moderation-step'


// TODO: all actions which use unsafe methods must use idempotency keys
export const useModerationStore = defineStore('moderation', {
  state: () => ({
    isInitialized: false as boolean,
    currentViolationForModeration: null as ViolationForModeration | null,
    violationModerationQueue: [] as ViolationForModeration[],
    violationModerationQueueNextPage: 1 as number,
    violationModerationQueueTotalPageCount: 1 as number,
    violationModerationQueueObjectsCount: 0 as number,
    violationModerationQueueInProgress: false as boolean,
    prevGeoPhotoIds: [] as string[],
    currentModerationStep: MODERATION_STEP.OVERVIEW as MODERATION_STEP,
    isHorizontalLayout: false as boolean,
    orderByDistance: true as boolean,
  }),

  actions: {
    async resetState() {
      this.isInitialized = false;
      await this.resetQueue();
    },

    async setOrderByDistance(orderByDistance: boolean) {
      this.orderByDistance = orderByDistance;
      await this.resetQueue();
      await this.loadViolationModerationQueue();
    },

    async resetQueue() {
      this.currentViolationForModeration = null;
      this.violationModerationQueue = [];
      this.violationModerationQueueNextPage = 1;
      this.violationModerationQueueTotalPageCount = 1;
      this.violationModerationQueueObjectsCount = 0;
      this.violationModerationQueueInProgress = false;
      this.currentModerationStep = MODERATION_STEP.OVERVIEW;
    },

    async initialize() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      if (!this.isInitialized) {
        this.isInitialized = true;
        await this.loadViolationModerationQueue();
      }
    },

    async setHorizontalLayout(isHorizontal: boolean) {
      this.isHorizontalLayout = isHorizontal;
    },

    async chooseNextModerationStep() {
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      const applicationsStore = useApplicationsStore();
      await applicationsStore.waitInitialization();
      if(this.currentViolationForModeration?.typeConfirmed && applicationsStore.applicants.length > 0) {
        await applicationsStore.searchCurrentApplicationByViolationId();
        await this.setStep(MODERATION_STEP.APPLICATION_REVIEW);
      } else if (this.currentViolationForModeration?.carPlate.numberConfirmed) {
        await this.setStep(MODERATION_STEP.VIOLATION_TYPE_CHECK);
      } else if(this.currentViolationForModeration?.carPlate.photo.addressConfirmed) {
        await this.setStep(MODERATION_STEP.PLATE_NUMBER_CHECK);
      } else {
        await this.setStep(MODERATION_STEP.OVERVIEW);
      }
      await this.setInProgress(false);
    },

    async setStep(step: MODERATION_STEP) {
      this.currentModerationStep = step;
    },

    async secondStep() {
      this.currentModerationStep = MODERATION_STEP.LOCATION_CHECK;
    },

    async setInProgress(inProgress: boolean) {
      this.violationModerationQueueInProgress = inProgress;
    },

    async dropViolationsForModerationWithGeoPhotoId(id: string) {
      this.violationModerationQueue = this.violationModerationQueue.filter((violation: ViolationForModeration) => violation.carPlate?.photo?.id !== id);
      await this.selectNextViolationForModeration();
    },

    async dropCurrentViolationForModeration(id: string) {
      this.violationModerationQueue = this.violationModerationQueue.filter((violation: ViolationForModeration) => violation.id !== id);
      await this.selectNextViolationForModeration();
    },

    async selectNextViolationForModeration() {
      if(this.violationModerationQueue.length < 2 && this.violationModerationQueueNextPage-1 < this.violationModerationQueueTotalPageCount) {
        await this.loadViolationModerationQueue();
      }
      if(this.violationModerationQueue.length == 0) {
        await this.resetQueue();
      } else {
        this.prevGeoPhotoIds.push(this.currentViolationForModeration?.carPlate?.photo?.id);
        this.currentViolationForModeration = this.violationModerationQueue[0];
        if (this.prevGeoPhotoIds.includes(this.currentViolationForModeration?.carPlate?.photo?.id)) {
          await this.retrieveViolationForModerationForUpdateCurrent();
        }
        await this.chooseNextModerationStep();
      }
    },

    async rejectGeoPhoto(reason: MODERATION_STATUS) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const requestBody: GeoPhotoModerationPatch = {
        isRejected: true,
        moderationStatus: reason,
        addressConfirmed: reason == MODERATION_STATUS.REJECTED_REASON_GEOLOCATION? false : null,
      }
      try {
        const response = await objectsModule.patchGeoPhotoForModeration(this.currentViolationForModeration?.carPlate?.photo?.id, mapStoreGeoPhotoModerationPatchToApiGeoPhotoModerationPatch(requestBody));
        if(response?.status == 200) {
          await this.dropViolationsForModerationWithGeoPhotoId(this.currentViolationForModeration?.carPlate?.photo?.id);
        } else {
          appStore.addErrorMessage(`Ошибка при отклонении фотографии (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при отклонении фотографии: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async rejectCarPlate(reason: MODERATION_STATUS) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const requestBody: CarPlateForModeration = {
        numberConfirmed: false,
        moderationStatus: reason,
      }
      try {
        const response = await objectsModule.patchCarPlateDuringModeration(this.currentViolationForModeration?.carPlate?.id, mapStoreCarPlateForModerationToApiCarPlateForModeration(requestBody));
        if(response?.status == 200) {
          await this.dropCurrentViolationForModeration(this.currentViolationForModeration?.id);
        } else {
          appStore.addErrorMessage(`Ошибка при отклонении номера автомобиля (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при отклонении фотографии: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async rejectViolation(reason: MODERATION_STATUS) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const requestBody: ViolationForModeration = {
          typeConfirmed: false,
          moderationStatus: reason,
        }
        const response = await objectsModule.patchViolationForModeration(this.currentViolationForModeration?.id, mapStoreViolationForModerationToApiViolationForModeration(requestBody));
        if(response?.status == 200) {
          await this.dropCurrentViolationForModeration(this.currentViolationForModeration?.id);
        } else {
          appStore.addErrorMessage(`Ошибка при отклонении нарушения (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при отклонении нарушения: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async confirmViolation() {
      const authStore = useAuthStore();
      if (await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const requestBody: ViolationForModeration = {
          typeConfirmed: true,
          moderationStatus: MODERATION_STATUS.CONFIRMED,
        };
        const response = await objectsModule.patchViolationForModeration(this.currentViolationForModeration?.id, mapStoreViolationForModerationToApiViolationForModeration(requestBody));
        if (response?.status === 200) {
          const applicationsStore = useApplicationsStore();
          if (applicationsStore.applicants.length < 1) {
            await this.dropCurrentViolationForModeration(this.currentViolationForModeration?.id);
          } else {
            await this.retrieveViolationForModerationForUpdateCurrent();
            await this.chooseNextModerationStep();
          }
        } else {
          appStore.addErrorMessage(`Ошибка при подтверждении нарушения (ошибка ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при подтверждении нарушения: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async patchCurrentGeoPhotoDuringModeration(changes: GeoPhotoModerationPatch) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const response = await objectsModule.patchGeoPhotoForModeration(this.currentViolationForModeration?.carPlate?.photo?.id, mapStoreGeoPhotoModerationPatchToApiGeoPhotoModerationPatch(changes));
        if(response?.status == 200) {
          await this.retrieveViolationForModerationForUpdateCurrent();
        } else {
          appStore.addErrorMessage(`Ошибка при изменении фотографии (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при изменении фотографии: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async patchCarPlateEditPlateNumberDuringModeration(newPlateNumber: string) {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const data: CarPlateForModeration = {
        plateNumber: newPlateNumber,
      }
      try {
        const response = await objectsModule.patchCarPlateDuringModeration(this.currentViolationForModeration?.carPlate?.id, mapStoreCarPlateForModerationToApiCarPlateForModeration(data));
        if(response?.status == 200) {
          await this.retrieveViolationForModerationForUpdateCurrent();
        } else {
          appStore.addErrorMessage(`Ошибка при изменении номера (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при изменении номера: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async patchCarPlateConfirmPlateNumberDuringModeration() {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const data: CarPlateForModeration = {
        numberConfirmed: true,
        moderationStatus: MODERATION_STATUS.CONFIRMED,
      }
      try {
        const response = await objectsModule.patchCarPlateDuringModeration(this.currentViolationForModeration?.carPlate?.id, mapStoreCarPlateForModerationToApiCarPlateForModeration(data));
        if(response?.status == 200) {
          await this.retrieveViolationForModerationForUpdateCurrent();
          await this.chooseNextModerationStep();
        } else {
          appStore.addErrorMessage(`Ошибка при подтверждении номера (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при подтверждении номера: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async changeViolationTypeDuringModeration(typeId: string) {
      const authStore = useAuthStore();
      if (await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const data: ViolationForModeration = {
          // @ts-ignore
          violationType: typeId,
        };
        const response = await objectsModule.patchViolationForModeration(this.currentViolationForModeration?.id, mapStoreViolationForModerationToApiViolationForModeration(data));
        if (response?.status === 200) {
          await this.retrieveViolationForModerationForUpdateCurrent();
        } else {
          appStore.addErrorMessage(`Ошибка при изменении типа нарушения (сервер вернул код ${response?.status})`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при изменении типа нарушения: ${error}`);
      } finally {
        await this.setInProgress(false);
      }
    },

    async retrieveViolationForModerationForUpdateCurrent() {
      const authStore = useAuthStore();
      if (await authStore.notHaveModeratorRights()) {
        return;
      }
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      try {
        const response = await objectsModule.retrieveViolationForModeration(this.currentViolationForModeration?.id);
        if (response?.status === 200) {
          this.currentViolationForModeration = mapApiViolationForModerationToStoreViolationForModeration(response.data);
          this.violationModerationQueue.forEach((violation: ViolationForModeration, index: number) => {
            if (violation.id == this.currentViolationForModeration?.id) {
              this.violationModerationQueue[index] = this.currentViolationForModeration;
            }
          });
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке нарушения (ошибка ${response?.status})`);
        }
      } catch (error) {
        const appStore = useAppStore();
        appStore.addErrorMessage(`Ошибка при загрузке нарушения: ${error}`);
        if (error.response?.status >= 500 || error.response?.status == 408) {
          const self = this;
          setTimeout(() => { self.retrieveViolationForModerationForUpdateCurrent() }, Math.max(5000 * Math.random(), 2000));
        }
      }
    },

    async loadViolationModerationQueue() {
      const authStore = useAuthStore();
      if(await authStore.notHaveModeratorRights()) {
        return;
      }
      await this.setInProgress(true);
      const appStore = useAppStore();
      await appStore.waitForBackendAvailable();
      const location = await appStore.getCurrentLocation(false);
      if(!location) {
        appStore.addErrorMessage("Ошибка при загрузке очереди на модерацию (координаты не определены)");
        await this.setInProgress(false);
        return;
      }
      try {
        const response = await objectsModule.getViolationModerationQueue(location.coords.latitude, location.coords.longitude, this.violationModerationQueueNextPage, this.orderByDistance);
        if (response?.status == 200) {
          this.violationModerationQueue = [...this.violationModerationQueue, ...response.data.map(mapApiViolationForModerationToStoreViolationForModeration)];
          this.violationModerationQueueObjectsCount = parseInt(response.headers?.object_count);
          this.violationModerationQueueTotalPageCount = parseInt(response.headers?.page_count);
          if(this.violationModerationQueue.length > 0) {
            this.currentViolationForModeration = this.violationModerationQueue[0];
          } else {
            this.currentViolationForModeration = null;
          }
          if(this.violationModerationQueueNextPage < this.violationModerationQueueTotalPageCount) {
            this.violationModerationQueueNextPage++;
          } else if (this.violationModerationQueueNextPage >= this.violationModerationQueueTotalPageCount ) {
            this.violationModerationQueueNextPage = this.violationModerationQueueTotalPageCount;
          }
        } else {
          appStore.addErrorMessage(`Ошибка при загрузке очереди на модерацию: ${response?.status} ${response?.statusText}`);
        }
      } catch (error) {
        appStore.addErrorMessage(`Ошибка при загрузке очереди на модерацию: ${error})`);
        if( error?.response?.status >= 500 || error?.response?.status == 408) {
          const self = this;
          setTimeout(() => { self.loadViolationModerationQueue() }, Math.max(5000 * Math.random(), 2000));
        }
      } finally {
        await this.chooseNextModerationStep();
        await this.setInProgress(false);
      }
    },
  },
});
