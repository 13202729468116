<template>
  <MDBModal class="modal fade" v-model="showRulesBlock" @hidden="showRulesBlock = false; appStore.setRulesHaveBeenRead(true)">
    <MDBModalHeader>
      <MDBModalTitle>
        <MDBIcon fas icon="stopwatch" class="me-2"/>
        {{ $t('hunting.pleaseWait') }}
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <p>
        <strong>{{ $t('hunting.toAchieveTheExpectedResult') }}</strong> {{ $t('hunting.pleaseTakeAFewMinutes') }}
        <span class="text-danger"> {{ $t('hunting.blockUsers') }}</span>
      </p>
      <hr>
      <h5>
        <MDBIcon fas icon="shield-alt" class="me-2"/>
        {{ $t('hunting.preparationOfTheDeviceAndPermissions') }}
      </h5>
      <div class='vstack gap-2'>
        <div><strong>{{ $t('hunting.forHuntingOffenders') }}</strong> {{ $t('hunting.theOinkOinkCarWillRequestTheFollowingPermissions') }}</div>
        <MDBListGroup light>
          <MDBListGroupItem class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <MDBIcon fas icon="location-arrow" size='2x' />
              <div class="ms-3">
                <p class="fw-bold mb-1">{{ $t('hunting.location') }}</p>
                <p class="text-muted mb-0">{{ $t('hunting.determineTheAddressOfTheViolation') }}</p>
              </div>
            </div>
          </MDBListGroupItem>
          <MDBListGroupItem class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <MDBIcon fas icon="camera" size='2x' />
              <div class="ms-3">
                <p class="fw-bold mb-1">{{ $t('hunting.camera') }}</p>
                <p class="text-muted mb-0">{{ $t('hunting.fixTheViolation') }}</p>
              </div>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
        <mark>{{ $t('hunting.doNotForgetToEnableGeolocation') }}</mark>
      </div>
      <hr>
      <h5>
        <MDBIcon fas icon="list-ol" class="me-2"/>
        {{ $t('hunting.basicRequirements') }}
      </h5>
      <ul class="list-unstyled">
        <li class="mb-1"><i class="fas fa-angry me-2 text-success"></i>{{ $t('hunting.noFaces') }}</li>
        <li class="mb-1"><i class="fas fa-car-side me-2 text-success"></i>{{ $t('hunting.theCarMustCompletelyFit') }}</li>
        <li class="mb-1"><i class="fas fa-eye me-2 text-success"></i>{{ $t('hunting.theLicensePlateMustBeClearlyReadable') }}</li>
        <li class="mb-1"><i class="fas fa-clipboard-list me-2 text-success"></i>{{ $t('hunting.theViolationTypeMustBeUnambiguouslyConfirmed') }}</li>
        <li class="mb-1"><i class="fas fa-money-bill-alt me-2 text-success"></i>{{ $t('hunting.oneViolationCanBeFixedOnlyOnceADay') }}</li>
      </ul>
      <hr>
      <h5>
        <MDBIcon fas icon="fist-raised" class="me-2"/>
        {{ $t('hunting.aboutSafety') }}
      </h5>
      <div><strong>{{ $t('hunting.whenHuntingForOffenders') }}</strong> {{ $t('hunting.knowThatYouAreDoingTheRightThingBut') }}
        <mark> {{ $t('hunting.takeCareOfYourOwnSafety') }}</mark> {{ $t('hunting.modernSocietyInRussiaHasAKindOfMentality') }}
        <mark> {{ $t('hunting.doNotRiskYourHealthAndLife') }}</mark> {{ $t('hunting.inThisCaseItIsBetterToJustLeave') }}
        <mark> {{ $t('hunting.whatYouDefinitelyShouldNotDo') }}</mark> {{ $t('hunting.ourCustomersUnderstandOnlyTheLanguageOfFines') }}
      </div>
      <hr>
      <h5>
        <MDBIcon fas icon="user-check" class="me-2"/>
        {{ $t('hunting.selfControlAndModeration') }}
      </h5>
      <div><strong>{{ $t('hunting.allPhotosMustGoThroughTheModerationProcedure') }} </strong> {{ $t('hunting.butWeStillAskYouToApproachTheIssueOfContentQualityResponsibly') }}
        <mark> {{ $t('hunting.usersSendingPoorQualityContentMayBeBlocked') }}</mark>
      </div>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="success" @click="showRulesBlock = false; appStore.setRulesHaveBeenRead(true)">
        {{ $t("home.letsGo") }}
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
  <div class='sign-in-wrapper d-flex align-items-center justify-content-center text-center ms-3 me-3 mt-3'>
    <h2 class='brand-name'>{{ $t("content.signInPrompt") }}</h2>
    <p class='lead mt-2'  v-if='locale === LOCALE_RU'>
      При первой авторизации для вас будет создан аккаунт на основе данных, предоставленных Google или VK.
      Наш сервис хранит в базе данных только ваш email, имя и фамилию, которые вы предоставили при регистрации на этих платформах.
      Аккаунты идентифицируются по email, т.е. если вы авторизуетесь через Google и VK с одним и тем же email,
      то это будет один и тот же аккаунт.
      Создавая аккаунт, вы соглашаетесь с <router-link :to="{ name: PRIVACY_ROUTE_NAME }">политикой конфиденциальности</router-link> и <router-link :to="{ name: RULES_ROUTE_NAME }">условиями использования</router-link>.
    </p>
    <p class='lead mt-2'  v-else>
      When you first log in, an account will be created for you based on the data provided by Google or VK.
      Our service stores in the database only your email, name and surname that you provided during registration on these platforms.
      Accounts are identified by email, i.e. if you log in through Google and VK with the same email,
      then this will be the same account.
      By creating an account, you agree to the <router-link :to="{ name: PRIVACY_ROUTE_NAME }">privacy policy</router-link> and <router-link :to="{ name: RULES_ROUTE_NAME }">terms of use</router-link>.
    </p>
    <SignInGoogleButton class='mt-3 mb-2' />
    <SignInVKButton class='mt-3 mb-2' />
    <hr class="hr hr-blurry w-75" />
    <p class="brand-slogan mb-2">{{ $t("header.brandSlogan") }}</p>
    <hr class="hr hr-blurry w-75" />
    <p class='lead mt-2'>{{ $t("content.signInLead") }}</p>
  </div>
</template>
<script lang="ts">
import {defineComponent, onMounted, ref, watch} from "vue";
import SignInVKButton from '@/ui/components/SignInVKButton.vue'
import SignInGoogleButton from '@/ui/components/SignInGoogleButton.vue'
import { PRIVACY_ROUTE_NAME, RULES_ROUTE_NAME } from "@/helpers/constants/routes";
import { useLocaleStore } from "@/store/locale";
import { LOCALE_RU } from "@/helpers/constants/locale";
import {useAppStore} from "@/store/app";
import {
  MDBBtn, MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal, MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle
} from "mdb-vue-ui-kit";


export default defineComponent({
  name: "SignInContainer",
  components: {
    MDBIcon,
    MDBModalBody,
    MDBBtn,
    MDBModalHeader,
    MDBModal,
    MDBListGroupItem,
    MDBModalFooter,
    MDBModalTitle,
    MDBListGroup, SignInVKButton, SignInGoogleButton },
  data() {
    return {
      PRIVACY_ROUTE_NAME,
      RULES_ROUTE_NAME,
      LOCALE_RU
    };
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = ref(localeStore.currentLocale)
    watch(() => localeStore.currentLocale, (newLocale) => {
      locale.value = newLocale;
    });

    const appStore = useAppStore();
    const showRulesBlock = ref(!appStore.rulesHaveBeenRead);

    onMounted(() => {
      if(!appStore.rulesHaveBeenRead && showRulesBlock.value) {
        showRulesBlock.value = false;
        setTimeout(() => {
          showRulesBlock.value = true;
        }, 300);
      }
    });

    return {
      showRulesBlock,
      locale,
      appStore,
    };
  },
});
</script>
<style scoped lang="scss">
.sign-in-wrapper {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
}
.brand-name {
  font-family: 'Maler', 'Roboto', sans-serif;
  color: #727d82;
}
.brand-slogan {
  font-family: 'Veles', 'Roboto Light', sans-serif;
  color: #727d82;
  font-weight: bold;
  font-size: 16px;
}
.lead {
  font-family: 'Roboto', sans-serif;
  color: #727d82;
  font-size: 16px;
}
</style>
