const pageTitles = {
  default: "OinkOinkCar",
  map: "OinkOinkCar - violations map",
  hunting: "OinkOinkCar - hunting for violators",
  manifesto: "OinkOinkCar - manifesto",
  profile: "OinkOinkCar - profile",
  sign_in: "OinkOinkCar - sign in",
  hunt: "OinkOinkCar - hunting for violators",
  moderator: "OinkOinkCar - moderator console",
  sitemap: "OinkOinkCar - sitemap",
  privacy: "OinkOinkCar - privacy policy",
  rules: "OinkOinkCar - rules",
  auth_error: "OinkOink - authorization error",
};

export default pageTitles;
