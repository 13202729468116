const content = {
  map: "Map",
  manifesto: "Manifesto",
  hunting: "Hunting",
  stats: "Statistics",
  catalog: "Catalog",
  profile: "Profile",
  banBadge: "Ban",
  moderatorBadge: "M",
  adminBadge: "S",
  signIn: "Sign in",
  signInLead: "After authorization, you will have the ability to submit photos of offenders, and if the violation is confirmed, the offender will be subject to penalties in accordance with administrative law. Information about the violation will be published on the map and in the OinkOinkCar catalog.",
  signInPrompt: "Sign in to OinkOinkCar:",

  more: "More",
  close: "Close",
  violationDetails: "Violation details",
  violationPhoto: "Violation photo",
  violation: "Violation",
  violationOn: "on",
  status: "Status",
  deleteViolation: "Request deletion",
  deleteViolationTooltip: "To delete a photo with a violation, fill out the form. You will need to confirm the fact of paying the fine, or the fact of imposing a penalty in the form of a warning.",
  oopsNotFound: "Oops, violation not found.",
  oopsNotFoundDescription: "Either it was deleted at the request of the owner (after paying the administrative fine, of course), or you entered the wrong identifier.",
  copyLinkToViolation: "Copy link to violation",
  linkCopied: "Link copied",
  toParking: "To parking",
  m: "m",

  // Car parking details
  address: "address of the car park",
  ps: "p/s",
  space: "spaces",
  spaces: "availability of parking spaces",
  empty: "empty",
  outOf: "out of",
  monthlyRent: "monthly rent",
  rubPerMonth: "rub/month",
  carParkTitle: "Car park information",
  updated: "updated: ",
  reportInaccuracy: "Report an inaccuracy",
  phone: "Phone",

  // reject reasons
  moderationStatusBadge_not_moderated: 'Under moderation',
  moderationStatusBadge_confirmed: 'Confirmed',
  moderationStatusBadge_blurred: 'Rejected',
  moderationStatusBadge_faces: 'Rejected',
  moderationStatusBadge_no_car: 'Rejected',
  moderationStatusBadge_car_not_fit: 'Rejected',
  moderationStatusBadge_car_plate_not_readable: 'Rejected',
  moderationStatusBadge_international_car_plate: 'Rejected',
  moderationStatusBadge_violation_type_not_confirmed: 'Rejected',
  moderationStatusBadge_violation_already_exists: 'Rejected',
  moderationStatusBadge_geolocation_error: 'Rejected',
  moderationStatusBadge_flood: 'Rejected',
  moderationStatusBadge_adult: 'Rejected',
  moderationStatusBadge_other: 'Rejected',
  moderationStatusBadge_timeout: 'Rejected',

  moderationStatusTooltip_not_moderated: 'Violation is still under moderation. Typically, this process takes no more than a day.',
  moderationStatusTooltip_confirmed: 'Violation confirmed, report sent to the relevant authority.',
  moderationStatusTooltip_blurred: 'Photo is blurred. Try to avoid blurriness in photos.',
  moderationStatusTooltip_faces: 'There are faces in the photo, which contradicts the rules.',
  moderationStatusTooltip_no_car: 'No car in the photo. Multiple such violations may lead to account suspension.',
  moderationStatusTooltip_car_not_fit: 'Not the entire car is visible in the photo. Try to photograph the entire car.',
  moderationStatusTooltip_car_plate_not_readable: 'Car plate is not readable in the photo. Try to take a close-up photo of the car.',
  moderationStatusTooltip_international_car_plate: 'Car in the photo has a foreign plate. Only photos with Russian/Soviet plates are accepted.',
  moderationStatusTooltip_violation_type_not_confirmed: 'Violation is not confirmed in the photo, or it is impossible to unequivocally confirm it with one photo. Next time, try to make sure that details confirming the violation (ground, vegetation, curb, road signs) are clearly visible in the photo.',
  moderationStatusTooltip_violation_already_exists: 'Someone has already submitted this car today; you have competition in this area=) Choose another location or come back here in a day.',
  moderationStatusTooltip_geolocation_error: 'There are issues with geolocation; it was not possible to determine the location unambiguously. Next time, please make sure that geolocation is enabled, and objects allowing unambiguous location determination (such as buildings) are visible in the photo.',
  moderationStatusTooltip_flood: 'Flood is prohibited. Multiple such violations may lead to account suspension.',
  moderationStatusTooltip_adult: 'There is prohibited content in the photo. Multiple such violations may lead to account suspension.',
  moderationStatusTooltip_other: 'Reason for rejection is not specified.',
  moderationStatusTooltip_timeout: 'Time on your device is not synchronized.',
};

export default content;
