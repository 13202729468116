import { MINIMUM_WINDOW_WIDTH, MINIMUM_WINDOW_HEIGHT } from "@/helpers/constants/application";
import { APP_STAGE } from '@/helpers/enums/app-stage'

const application = {
  smallWindowWarning: `App is designed for browser window size of at least ${MINIMUM_WINDOW_WIDTH}x${MINIMUM_WINDOW_HEIGHT}px. Your browser window is smaller, some interface elements may be displayed incorrectly.`,
  oinkOinkMan: "OinkOinkMan",
  oops: "Oops...",
  maintenanceMessage: "Currently, the engineers of OinkOinkCar are fixing something and this section is temporarily unavailable... Please come back later!",
  goHome: "Go home",
  next: "Next",
  back: "Back",
  telegram: "more in Telegram",
  version: "Version: ",
  outdated: "(outdated)",
  stageButtonText: {
    [APP_STAGE.DEV]: "Development mode",
    [APP_STAGE.STAGING]: "Test mode",
    [APP_STAGE.PROD]: "Production mode",
  },
  stagePopoverText: {
    [APP_STAGE.DEV]: "In this mode, user registration is closed. Don't forget to create a test user in the database in advance or through the Django console.",
    [APP_STAGE.STAGING]: "This is the test environment of the OinkOinkCar application. Registration on this server is closed, and all photos are not necessarily photos of violations and may be entered only for testing. Site indexing is prohibited.",
    [APP_STAGE.PROD]: "This is the production environment of the OinkOinkCar application.",
  },
  auth: {
    errorHeader: "Authorization error",
    signUpClosed: "Registration of new users on the test server is closed. Please switch to the production server.",
    writeToAdmins: "Support",
    signInError: "Authorization error. Try again later or contact the administrators.",
    yourAccountIsBlocked: "Your account is blocked. Contact the administrators.",
  }
};

export default application;
