const pageTitles = {
  default: "ХрюХрюКар",
  map: "ХрюХрюКар - карта нарушений",
  hunting: "ХрюХрюКар - охота на нарушителей",
  manifesto: "ХрюХрюКар - манифест",
  profile: "ХрюХрюКар - профиль",
  sign_in: "ХрюХрюКар - вход",
  hunt: "ХрюХрюКар - охота на нарушителей",
  moderator: "ХрюХрюКар - консоль модератора",
  sitemap: "ХрюХрюКар - карта сайта",
  privacy: "ХрюХрюКар - политика конфиденциальности",
  rules: "ХрюХрюКар - правила",
  auth_error: "ХрюХрюКар - ошибка авторизации",
};

export default pageTitles;
