import type { Application as StoreApplication } from "@/store/models/application.model";
import { Application as ApiApplication } from '@/api/models/application.model'

export function mapApiApplicationToStoreApplication(apiApplication: ApiApplication): StoreApplication {
  return {
    id: apiApplication.id,
    createdAt: new Date(apiApplication.created_at),
    modifiedAt: apiApplication.modified_at ? new Date(apiApplication.modified_at) : undefined,
    applicant: apiApplication.applicant,
    violation: apiApplication.violation,
    powers: apiApplication.powers,
    isConfirmed: apiApplication.is_confirmed,
    sentAt: apiApplication.sent_at ? new Date(apiApplication.sent_at) : undefined,
    pdfFile: apiApplication.pdf_file,
    rejectedReason: apiApplication.rejected_reason,
    text: apiApplication.text,
  };
}

export function mapStoreApplicationToCreateApiApplication(storeApplication: StoreApplication): ApiApplication {
  return {
    applicant: storeApplication.applicant,
    violation: storeApplication.violation,
  };
}

export function mapStoreApplicationToUpdateApiApplicationDuringModeration(storeApplication: StoreApplication): ApiApplication {
  return {
    is_confirmed: storeApplication.isConfirmed?? undefined,
    rejected_reason: storeApplication.rejectedReason?? undefined,
    text: storeApplication.text?? undefined,
  };
}
