// src/helpers/enums/application-reject-reason.ts
export enum APPLICATION_REJECT_REASON {
  REJECTED_REASON_FLOOD = 'flood',
  REJECTED_REASON_ADULT = 'adult',
  REJECTED_REASON_OTHER = 'other',
  REJECTED_REASON_TIMEOUT = 'timeout',
  REJECTED_REASON_ENCODING_ERROR = 'encoding_error',
  REJECTED_REASON_MODERATION_ERROR = 'moderation_error',
  REJECTED_REASON_LAYOUT_ERROR = 'layout_error',
  REJECTED_REASON_APPLICANT_ERROR = 'applicant_error',
  REJECTED_REASON_AUTHORITY_ERROR = 'authority_error',
}
