const hunting = {
  pleaseWait: 'Please take a minute!',
  toAchieveTheExpectedResult: 'To achieve the expected result,',
  pleaseTakeAFewMinutes: 'please take a few minutes to familiarize yourself with the basic rules. For violating some rules, unfortunately, sometimes we have to',
  blockUsers: 'block users.',
  next: 'Next',
  back: 'Back',
  preparationOfTheDeviceAndPermissions: 'Preparation of the device and permissions',
  forHuntingOffenders: 'For hunting offenders,',
  theOinkOinkCarWillRequestTheFollowingPermissions: 'The OinkOinkCar will request the following permissions',
  location: 'Location',
  determineTheAddressOfTheViolation: 'determine the address of the violation',
  camera: 'Camera',
  fixTheViolation: 'record a violation',
  doNotForgetToEnableGeolocation: 'Please do not forget to enable geolocation!',
  basicRequirements: 'Basic requirements',
  noFaces: 'There should be no faces in the photo;',
  theCarMustCompletelyFit: 'The car must completely fit into the boundaries of the photos;',
  theLicensePlateMustBeClearlyReadable: 'The license plate must be clearly readable;',
  theViolationTypeMustBeUnambiguouslyConfirmed: 'The type of violation must be unambiguously confirmed by the photo (the necessary details should be visible - the absence of a solid coating, the presence of a pedestrian crossing, a sidewalk, etc.);',
  oneViolationCanBeFixedOnlyOnceADay: 'One violation can be fixed only once a day. But the next day you can catch it again, so it\'s better to learn the lesson =)',
  aboutSafety: 'About safety',
  whenHuntingForOffenders: 'When hunting for offenders,',
  knowThatYouAreDoingTheRightThingBut: 'know that you are doing the right thing, but',
  takeCareOfYourOwnSafety: 'take care of your own safety!',
  modernSocietyInRussiaHasAKindOfMentality: 'Modern society in Russia has a kind of mentality and is not always able to accept criticism. Based on this, if you see that the offender has noticed you and begins to behave aggressively,',
  doNotRiskYourHealthAndLife: 'do not risk your health and life.',
  inThisCaseItIsBetterToJustLeave: 'In this case, it is better to just leave and not risk it. But you can always come back and fix the violation from a different angle, if possible.',
  whatYouDefinitelyShouldNotDo: 'What you definitely should not do is try to reason with the offender.',
  ourCustomersUnderstandOnlyTheLanguageOfFines: 'Our "customers" understand only the language of fines. If you fix violations within two weeks, then you can be sure that the offender will begin to think differently, having received a series of fines and replenishing the budget.',
  selfControlAndModeration: 'Self-control and moderation',
  allPhotosMustGoThroughTheModerationProcedure: 'All photos must go through the moderation procedure,',
  butWeStillAskYouToApproachTheIssueOfContentQualityResponsibly: 'but we still ask you to approach the issue of content quality responsibly, because all the persons involved in this project spend their resources on a gratuitous basis, including our moderators. The mechanisms of the OinkOinkCar allow you to delete a photo, violation or recognized car number within a day, except in cases where the specified materials have already passed moderation.',
  usersSendingPoorQualityContentMayBeBlocked: 'Users sending poor quality content may be blocked.',
  huntingStart: 'Hunting start',
  letSStart: 'Let\'s start!',
  yourSuccessForTheMonth: 'Your success for the month:',
  violationsFixed: 'Violations recorded',
  violationsConfirmed: 'Violations confirmed',
  notPassedModeration: 'Not passed moderation',
  goHunt: 'Go hunt!',
  your_catch: 'Your catch',
  verifiedByModerator: 'verified by moderator',
  processing: 'Processing...',
  loading: 'Loading camera...',
  uploading_wait: 'Uploading to the server, please wait...',
  license_plate_recognition: 'License plate recognition',
  license_plates_recognized: 'License plates recognized',
  geocoding: 'geocoding...',
  geocoded: 'geocoded.',
  loadingResults: 'Loading results...',
  neuralNetworkDidNotDetectLicensePlates: 'The neural network did not detect license plates in the photo. Choose a different angle and try again.',
  carPlateRemoved: "License plate {plate} has been removed",
  allCarPlatesWasRemoved: "All license plates have been removed, and the photo will also be deleted. Take a new picture if necessary.",
  photoWasSentCheckItAndContinue: "The photo is being sent. Make sure the photo is not blurred. The green button confirms and proceeds to the next car, while the red button deletes and allows you to take another shot.",
  unableToFindASuitableCamera: "We were unable to find a suitable camera, have you provided permission?",
  photoHasBeenAccepted: "The photo has been accepted, after moderation it will appear on the map and the application will be sent. Thank you!",
  photoHasBeenDeleted: "The photo has been deleted, please record the violation again. If the error occurs again, clear your browser cache or contact the developer.",
  photoRejectedBecauseAlreadyExists: "The photo has been rejected because the violation has already been recorded today. It looks like someone has already been here, please change the location. To continue, delete the photo.",
  violationTypesNotFoundHeader: "Oops, you are in a place where OinkOinkCar does not work yet =(",
  violationTypesNotFoundText: "You can fix this by filling out the form to add a territory.",
  violationTypesNotFoundButton: "Fill out the form",
  placeNotFoundHeader: "Oops, you are in a place where OinkOinkCar does not work =(",
  placeNotFoundText: "You can run your OinkOinkCar where you want. Below is a link to the repository with instructions for launching.",
  placeNotFoundButton: "Run your OinkOinkCar",
  cameraError: "Camera error. Write to the developer.",
  cameraPermissionDenied: "Camera permission denied. Please allow access to the camera in the browser settings.",
  cameraNotFound: "We couldn't find the camera. Make sure you have granted permission to use the camera. The minimum camera resolution is 960×540 (qHD). If the error persists, write to us through the feedback form (in the left menu).",
  selectCamera: "Select camera (β)",
  cameraSetupProblem: "We can't turn on the camera, it looks like it's busy with another application. Wait a few seconds or clear your browser cache.",
};

export default hunting;
