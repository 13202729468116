<template>
  <div class='static-page-container'>
    <div v-if='locale === LOCALE_RU' class='w-100 h-95'>
      <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ СЕРВИСА ХРЮХРЮКАР</h1>
      <p>Пользуясь сервисом ХрюХрюКар, вы можете передавать нам свою личную информацию. Мы делаем все возможное для
        обеспечения её безопасности и предоставляем вам возможность управлять своими данными.</p>
      <p>Просим внимательно ознакомиться с настоящей политикой безопасности, прежде чем начинать пользоваться сервисом
        ХрюХрюКар.</p>
      <p><strong>Политика в текущей редакции вступает в силу с 19 марта 2024 года.</strong></p>

      <h2>Сервис ХрюХрюКар предоставляет пользователям следующие возможности:</h2>

      <h3>1) Для неавторизованных пользователей:</h3>
      <ul>
        <li>Просматривать карту нарушений и каждое нарушение по отдельности</li>
        <li>Производить поиск и фильтрацию при просмотре карты нарушений</li>
        <li>Знакомиться с текстовыми материалами сайта</li>
        <li>Заполнять формы обратной связи</li>
      </ul>

      <h3>2) Для зарегистрированных пользователей с обычными правами:</h3>
      <ul>
        <li>Все действия, доступные незарегистрированному пользователю</li>
        <li>Присылать фотографии нарушений</li>
        <li>Просматривать личную статистику в части количества направленных нарушений и статуса их модерации</li>
        <li>Обозревать на карте нарушения, присланные собственноручно, отдельно от других либо вместе</li>
      </ul>

      <h3>3) Для модераторов:</h3>
      <ul>
        <li>Все действия, доступные зарегистрированному пользователю</li>
        <li>Производить модерацию присылаемых пользователями нарушений, без права просмотра сведений об авторах и без
          права производить модерацию нарушений, зафиксированных самим модератором
        </li>
        <li>Формировать заявления от своего имени и направлять их в уполномоченные органы на рассмотрение</li>
      </ul>

      <h3>4) Для администраторов сервиса:</h3>
      <ul>
        <li>Все действия, доступные модератору</li>
        <li>Назначать и разжаловать модераторов</li>
        <li>Блокировать модераторов и пользователей</li>
      </ul>

      <h2>Какие данные мы получаем, для чего и как с ними работаем</h2>
      <p>Для предоставления возможностей, указанных в пункте 1, сервис просит предоставить доступ к местоположению
        устройства. Указанная информация используется для определения территории, на которой находится пользователь и
        формирует список доступных типов нарушения для их фиксации. Эти сведения не сохраняются на стороне сервиса ни в
        каком виде.</p>
      <p>Для предоставления возможностей, указанных в пункте 2, вам необходимо авторизоваться в сервисе ХрюХрюКар, через
        Google или Вконтакте, используя API OAuth2. При авторизации, после нажатия на кнопку Войти через Google или
        ВКОНТАКТЕ на странице <a href="/sign-in">входа</a>, указанные сервисы передают нам следующую информацию из вашего
        аккаунта:</p>
      <ul>
        <li>Имя и фамилия, указанные в сервисах Google или ВКОНТАКТЕ;</li>
        <li>Уникальный идентификатор социальной сети;</li>
        <li>Адрес электронной почты, при наличии;</li>
        <li>Публичную автарку, при наличии;</li>
      </ul>
      <p>После авторизации, сервис ХрюХрюКар создает для Вас учетную запись на основе этих данных, сохраняя в базе данных
        следующую информацию:</p>
      <ul>
        <li>Имя и фамилия, указанные в сервисах Google или ВКОНТАКТЕ;</li>
        <li>Уникальный идентификатор социальной сети;</li>
        <li>Адрес электронной почты, при наличии;</li>
      </ul>
      <p>Данная информация используется в автоматическом режиме для идентификации пользователей и борьбы со
        злоупотреблениями. Ни один из вышеуказанных пунктов не доступен другим пользователям и модераторам. Когда вы
        фотографируете нарушение, Вы должны предоставить доступ к местоположению и камере устройства. После отправки
        фотографии с нарушением на сервер, в наш адрес направляется сама фотография нарушения, местоположение устройства,
        сведения о точности определения местоположения, азимут по компасу (при наличии), точное время создания фотографии
        и уникальный идентификатор пользователя - автора. Указанные данные сохраняются в базе данных и в
        специализированных хранилищах S3 для последующей автоматизированной обработки.</p>
      <p>Фотография обрабатывается с использованием нейросетей для распознавания номеров автомобилей, весь код выполняется
        на наших серверах. Сведения о местоположении используются для геокодирования (определения адреса), для чего
        используются API OpenStreetMaps, куда направляется только широта и долгота снимка.</p>
      <p>После автоматизированной обработки данных производится процедура модерации, при этом модераторам предоставляется
        следующая информация:</p>
      <ul>
        <li>Фотография без каких-либо метаданных</li>
        <li>Широта и долгота снимка</li>
        <li>Адрес, предоставленный геокодером</li>
        <li>Автомобильный номер, распознанный нейросетью</li>
        <li>Дата выполнения снимка (без указания времени)</li>
      </ul>
      <p>После успешной модерации нарушение публикуется на карте с указанными данными:</p>
      <ul>
        <li>Фотография без каких-либо метаданных</li>
        <li>Широта и долгота снимка</li>
        <li>Адрес, предоставленный геокодером</li>
        <li>Автомобильный номер, распознанный нейросетью</li>
        <li>Дата выполнения снимка (без указания времени)</li>
      </ul>
      <p>Модератор имеет право отклонить фотографию в случае нарушения <a href="/rules">правил</a> сервиса, после чего
        указанные данные будут доступны только вам и администраторам сервиса и могут быть удалены по нашему усмотрению.
      </p>
      <p>Модераторы и администраторы сервиса предоставляют все вышеуказанные данные, а также проходят процедуру
        дополнительной проверки и верификации. Модераторы, которые являются заявителями, также предоставляют данные,
        необходимые для формирования заявлений. Указанные данные предоставляются только совместно с согласием на обработку
        данных, подписанным собственноручно и оформленным в соответствии с Федеральным законом РФ "О персональных данных"
        от 27.07.2006 N 152-ФЗ.</p>
      <p>Все данные, которые мы храним, расположены на серверах на территории Российской Федерации:</p>
      <ul>
        <li>Фотографии и заявления хранятся в S3-хранилищах, расположенных в ЦОДах на серверах Yandex Cloud.</li>
        <li>Все остальные данные из перечисленных выше, хранятся в ЦОДе в г. Санкт-Петербург на серверах TimeWeb.Cloud
          (SPB4).
        </li>
      </ul>
      <p class="lead">Указанные данные надежно защищены и доступ к ним ограничен с использованием передовых технологий в
        области безопасности и управления доступом.</p>

      <h2>Передача данных третьим лицам</h2>
      <p>Сервис ХрюХрюКар может передавать указанные выше данные только органам власти в рамках действующего
        законодательства РФ по специальному запросу (при наличии законных оснований) либо по вступившему в силу решению
        суда. Также сервис ХрюХрюКар использует средства аналитики и мониторинга "Яндекс-Метрика", который позволяет
        анализировать действия пользователей на сайте для устранения недостатков, улучшения сервиса и выявления
        инцидентов. Данные, собираемые сервисом Яндекс-Метрика, доступны только владельцам сервиса ХрюХрюКар и ООО
        "Яндекс" (<a href="https://yandex.ru/legal/confidential/">политика конфиденциальности ООО "Яндекс"</a>). Мы не
        предоставляем доступ к счетчикам Яндекс-Метрика третьим лицам.</p>

      <h2>Удаление данных</h2>
      <p>По желанию пользователя, мы можем удалить данные, для чего необходимо направить запрос через специальную форму:
        <a href="https://forms.yandex.ru/cloud/6599a5d52530c2dab34837be/">Форма удаления данных</a>.
        В случае если вы являетесь автором фотографий с нарушениями, по которым уже были направлены заявления, вы можете
        попросить удалить и их, в таком случае указанные нарушения будут сняты с публикации, но останутся в базе данных до
        завершения
        производства по административному правонарушению. При этом ваши личные данные будут удалены незамедлительно.</p>

      <h2>Выгрузка данных</h2>
      <p>В настоящее время выгрузка данных в автоматическом режиме не доступна, однако при необходимости, мы можем
        произвести выгрузку ваших данных и созданного вами контента в ручном режиме по запросу. Запрос также необходимо
        направлять через форму обратной связи: <a href="https://forms.yandex.ru/cloud/6599a5d52530c2dab34837be/">Форма
          обратной связи</a>.</p>

      <h2>Файлы Cookie</h2>
      <p>Для вашей идентификации мы используем технологии OAuth2 и JWT. Для хранения состояния авторизации на вашем
        устройстве мы храним минимально возможный набор параметров авторизации в файлах Cookie. Также для
        работоспособности сервиса Яндекс-Метрика, указанный сервис также сохраняет ваш уникальный идентификатор в файлах
        Cookie. После выхода из системы, мы удаляем все файлы Cookie, которыми управляем. Файлы
        Cookie сервиса Яндекс-Метрика вы можете удалить вручную.</p>

      <h2>Изменения Политики конфиденциальности</h2>
      <p>Мы периодически вносим в Политику конфиденциальности изменения, однако не намерены в будущем ограничивать права
        пользователей, описанные в настоящей Политике, без их явного согласия. Мы всегда указываем дату последних
        изменений, внесенных в Политику, и предоставляем доступ к ее прежним версиям.</p>

      <h2>Права на фотографии</h2>
      <p>Пользователь, направляя фотографии, признает авторство за сервисом и соглашается с их публикацией по лицензии
        Creative
        Commons Attribution 4.0 International (CC BY 4.0), если это не нарушает прав третьих лиц.</p>
    </div>
    <div v-else class='w-100 h-95'>
      <h1>OinkOinkCar SERVICE PRIVACY POLICY</h1>
      <p>By using the OinkOinkCar service, you may provide us with your personal information. We make every effort to
        ensure its security and provide you with the ability to manage your data.</p>
      <p>Please carefully review this security policy before starting to use the OinkOinkCar service.</p>
      <p><strong>This policy, in its current version, comes into effect as of March 19, 2024.</strong></p>

      <h2>The OinkOinkCar service provides users with the following capabilities:</h2>

      <h3>1) For unauthorized users:</h3>
      <ul>
        <li>View the violation map and each violation separately</li>
        <li>Search and filter when viewing the violation map</li>
        <li>Read the text materials of the site</li>
        <li>Fill out feedback forms</li>
      </ul>

      <h3>2) For registered users with regular rights:</h3>
      <ul>
        <li>All actions available to an unregistered user</li>
        <li>Submit photos of violations</li>
        <li>View personal statistics regarding the number of reported violations and their moderation status</li>
        <li>Review violations submitted manually on the map, separately from others or together</li>
      </ul>

      <h3>3) For moderators:</h3>
      <ul>
        <li>All actions available to a registered user</li>
        <li>Moderate violations submitted by users, without the right to view information about authors and without the
          right to moderate violations recorded by the moderator themselves
        </li>
        <li>Formulate statements on behalf of themselves and send them to authorized bodies for consideration</li>
      </ul>

      <h3>4) For service administrators:</h3>
      <ul>
        <li>All actions available to a moderator</li>
        <li>Appoint and dismiss moderators</li>
        <li>Block moderators and users</li>
      </ul>
      <h2>What Data We Collect, Why, and How We Use It</h2>
      <p>To provide the features mentioned in section 1, the service requests access to the device's location. This
        information is used to determine the user's territory and generate a list of available violation types for their
        recording. This data is not stored on the service side in any form.</p>
      <p>To provide the features mentioned in section 2, you need to authorize in the OinkOinkCar service via Google or
        VKontakte, using OAuth2 API. Upon authorization, after clicking the "Sign in with Google" or "Sign in with
        VKONTAKTE" button on the <a href="/sign-in">sign-in page</a>, the specified services provide us with the following
        information from your account:</p>
      <ul>
        <li>First and last name as specified in Google or VKontakte services;</li>
        <li>Unique identifier of the social network;</li>
        <li>Email address, if available;</li>
        <li>Public avatar, if available;</li>
      </ul>
      <p>After authorization, the OinkOinkCar service creates an account for you based on this data, storing the following
        information in the database:</p>
      <ul>
        <li>First and last name as specified in Google or VKontakte services;</li>
        <li>Unique identifier of the social network;</li>
        <li>Email address, if available;</li>
      </ul>
      <p>This information is used automatically to identify users and combat abuses. None of the above points are
        accessible to other users and moderators. When you photograph a violation, you must grant access to the device's
        location and camera. After sending the violation photo to the server, we receive the violation photo itself,
        device location, information on location accuracy, compass azimuth (if available), precise photo creation time,
        and the unique user identifier - the author. This data is stored in the database and specialized S3 storage for
        subsequent automated processing.</p>
      <p>The photo is processed using neural networks for license plate recognition, all code is executed on our servers.
        Location information is used for geocoding (address determination), for which OpenStreetMaps APIs are used, where
        only the latitude and longitude of the snapshot are sent.</p>
      <p>After automated data processing, the moderation procedure is performed, during which moderators are provided with
        the following information:</p>
      <ul>
        <li>Photo without any metadata</li>
        <li>Latitude and longitude of the snapshot</li>
        <li>Address provided by the geocoder</li>
        <li>License plate recognized by the neural network</li>
        <li>Date of the snapshot (without specifying the time)</li>
      </ul>
      <p>Upon successful moderation, the violation is published on the map with the specified data:</p>
      <ul>
        <li>Photo without any metadata</li>
        <li>Latitude and longitude of the snapshot</li>
        <li>Address provided by the geocoder</li>
        <li>License plate recognized by the neural network</li>
        <li>Date of the snapshot (without specifying the time)</li>
      </ul>
      <p>A moderator has the right to reject a photo in case of violating the <a href="/rules">service rules</a>, after
        which the specified data will only be accessible to you and service administrators and may be deleted at our
        discretion.</p>
      <p>Service moderators and administrators provide all the aforementioned data, as well as undergo additional
        verification and validation procedures. Moderators who are applicants also provide data necessary for formulating
        statements. The specified data is provided only with the consent to data processing, personally signed and
        executed in accordance with the Federal Law of the Russian Federation "On Personal Data" dated July 27, 2006, No.
        152-FZ.</p>
      <p>All data we store is located on servers in the territory of the Russian Federation:</p>
      <ul>
        <li>Photos and statements are stored in S3 storages located in data centers on Yandex Cloud servers.</li>
        <li>All other data from the above list is stored in a data center in St. Petersburg on TimeWeb.Cloud servers
          (SPB4).
        </li>
      </ul>
      <p class="lead">The specified data is securely protected, and access to it is restricted using advanced security
        technologies and access management.</p>
      <h2>Data Transmission to Third Parties</h2>
      <p>The OinkOinkCar service may transfer the above-mentioned data only to governmental authorities within the
        framework of the current legislation of the Russian Federation upon a special request (if there are legal grounds)
        or by a court decision that has entered into force. Additionally, the OinkOinkCar service uses analytics and
        monitoring tools called "Yandex.Metrica," which allows analyzing user actions on the website to identify issues,
        improve the service, and detect incidents. Data collected by the Yandex.Metrica service is accessible only to the
        owners of the OinkOinkCar service and Yandex LLC (<a href="https://yandex.ru/legal/confidential/">Yandex LLC
          privacy policy</a>). We do not grant access to Yandex.Metrica counters to third parties.</p>

      <h2>Data Deletion</h2>
      <p>Upon the user's request, we can delete the data. To do this, you need to submit a request through a special form:
        <a href="https://forms.yandex.ru/cloud/6599a5d52530c2dab34837be/">Data Deletion Form</a>. If you are the author of
        photos of violations for which statements have already been sent, you can request to delete them as well. In this
        case, the specified violations will be removed from publication but will remain in the database until the
        administrative offense proceedings are completed. Your personal data will be deleted immediately.</p>

      <h2>Data Export</h2>
      <p>Currently, data export in automatic mode is not available. However, if necessary, we can manually export your
        data and the content created by you upon request. Please submit your request through the feedback form: <a
            href="https://forms.yandex.ru/cloud/6599a5d52530c2dab34837be/">Feedback Form</a>.</p>
      <h2>Cookie Files</h2>
      <p>For your identification, we use OAuth2 and JWT technologies. To store the authentication state on your device, we
        store the minimum set of authentication parameters in Cookie files. Also, for the Yandex.Metrica service to
        function properly, the service also saves your unique identifier in Cookie files. After logging out, we delete all Cookie files that we manage. You can manually delete Yandex.Metrica's Cookie
        files.</p>

      <h2>Changes to the Privacy Policy</h2>
      <p>We periodically make changes to the Privacy Policy; however, we do not intend to restrict the rights of users
        described in this Policy in the future without their explicit consent. We always indicate the date of the last
        changes made to the Policy and provide access to its previous versions.</p>

      <h2>Photo Rights</h2>
      <p>By submitting photos, the user acknowledges the authorship to the service and agrees to their publication under
        the Creative Commons Attribution 4.0 International (CC BY 4.0) license, unless it violates the rights of third
        parties.</p>
    </div>
    <br><br>
  </div>
</template>
<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {useLocaleStore} from '@/store/locale'
import {LOCALE_RU} from '@/helpers/constants/locale'
import {URL_OF_FORM_TO_ASK_EVERYTHING} from '@/helpers/constants/routes'

export default defineComponent({
  name: "PrivacyView",
  data() {
    return {
      LOCALE_RU,
      URL_OF_FORM_TO_ASK_EVERYTHING
    }
  },
  setup() {
    const localeStore = useLocaleStore();
    const locale = ref(localeStore.currentLocale)
    watch(() => localeStore.currentLocale, (newLocale) => {
      locale.value = newLocale;
    });

    return {
      locale,
    };
  },
});
</script>
<style lang="scss" scoped>
.static-page-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 1100px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media (min-width: 1100px) {
  .static-page-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>
