<template>
  <div class="map-wrapper">
    <ViolationsDetailsContainer />
    <MapFilterContainer />
    <MDBModal v-model="carParkModalVisible" centered @hidden="clearCarParkState">
      <MDBCard>
        <div class='hstack gap-2 ms-0 me-0 ps-2 pe-2 top-buttons'>
          <MDBBtn class='me-auto ms-0' color="light" tag="a" target="_blank" :href="URL_OF_FORM_TO_EDIT_CAR_PARK" size="sm">
            {{ $t('content.reportInaccuracy') }}
          </MDBBtn>
          <MDBBtn class='ms-auto me-0' color="primary" @click="carParkModalVisible = false" size="sm">
            {{ $t('content.close') }}
          </MDBBtn>
        </div>
        <MDBCardImg v-if="carParkImage" top :src="carParkImage" style="min-height: 200px;"/>
        <MDBCardBody class="pt-0 pb-0">
          <MDBListGroup light>
            <MDBListGroupItem class="d-flex justify-content-between align-items-center" v-if="carParkAddress">
              <div class="d-flex align-items-center">
                <img src="/address.png" :alt="$t('content.address')" style="width: 45px; height: 45px" class="rounded-circle"/>
                <div class="ms-3">
                  <p class="fw-bold mb-1"> {{ carParkAddress }}</p>
                  <p class="text-muted mb-0">{{ $t('content.address') }}</p>
                </div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem class="d-flex justify-content-between align-items-center" v-if="carParkFreeSpaces">
              <div class="d-flex align-items-center">
                <img src="/park-spaces.png" :alt="$t('content.spaces')" style="width: 45px; height: 45px" class="rounded-circle"/>
                <div class="ms-3">
                  <p class="fw-bold mb-1">
                    {{ $t('content.empty') }}: {{ carParkTotalSpaces ? carParkFreeSpaces + ' ' + $t('content.outOf') + ' ' + carParkTotalSpaces : carParkFreeSpaces }} {{ $t('content.space') }}
                  </p>
                  <p class="text-muted mb-0">{{ $t('content.spaces') }}</p>
                </div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem class="d-flex justify-content-between align-items-center" v-if="carParkMonthlyRent">
              <div class="d-flex align-items-center">
                <img src="/rent.png" :alt="$t('content.monthlyRent')" style="width: 45px; height: 45px" class="rounded-circle"/>
                <div class="ms-3">
                  <p class="fw-bold mb-1"> {{ carParkMonthlyRent }} {{$t('content.rubPerMonth')}}</p>
                  <p class="text-muted mb-0">{{ $t('content.monthlyRent') }}</p>
                </div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem class="d-flex justify-content-between align-items-center" v-if="carParkPhone">
              <div class="d-flex align-items-center">
                <img src="/phone.png" :alt="$t('content.phone')" style="width: 45px; height: 45px" class="rounded-circle"/>
                <div class="ms-3">
                  <p class="fw-bold mb-1"> {{ carParkPhone }}</p>
                  <p class="text-muted mb-0">{{ $t('content.phone') }}</p>
                </div>
              </div>
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBCardBody>
        <MDBCardFooter class="text-muted text-center" v-if="carParkLastUpdate">
          {{ $t('content.updated') }} {{ carParkLastUpdate }}
        </MDBCardFooter>
      </MDBCard>
    </MDBModal>
    <ol-map
      :loadTilesWhileAnimating="true"
      :loadTilesWhileInteracting="true"
      class="ol-map-block"
      @moveend="handleMapMoveEnd"
      ref="mapRef"
    >
      <ol-view ref="view" :center="center" :zoom="zoom" :projection='"EPSG:3857"'/>
      <ol-tile-layer>
        <ol-source-xyz ref="sourceRef" :url="tileLayerUrl"/>
      </ol-tile-layer>

      <ol-vector-layer>
        <ol-source-vector
            :url="mapStore.carParksUrl"
            :format="geoJson"
            @featuresloadstart="mapStore.loadingStarted"
            @featuresloadend="mapStore.loadingEnded"
        />
        <ol-style :overrideStyleFunction="overrideCarParkStyleFunction" />
      </ol-vector-layer>
      <ol-interaction-select
          @select="carParkFeatureSelected"
          :filter="carParksFilter"
          ref="carParksSelectorRef"
      >
        <ol-style>
          <ol-style-stroke color="rgb(255,215,0)" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(255,215,0,0.2)"></ol-style-fill>
        </ol-style>
      </ol-interaction-select>

      <ol-animated-clusterlayer :animationDuration="500" :distance="40">
        <ol-source-vector
            :url="mapStore.mapUrl"
            :format="geoJson"
            @featuresloadstart="mapStore.loadingStarted"
            @featuresloadend="mapStore.loadingEnded"
        />
        <ol-style :overrideStyleFunction="overrideClusterStyleFunction" />
      </ol-animated-clusterlayer>
      <ol-interaction-clusterselect
        @select="featureSelected"
        :pointRadius="30"
        :animate="true"
        :maxObjects="30"
        :spiral="true"
        :featureStyle="featureStyle"
        :filter="clusterSelectFilter"
      >
      </ol-interaction-clusterselect>
    </ol-map>
    <MDBBtn :color="authStore.isAuthenticated? 'primary' : ''" :outline="authStore.isAuthenticated? '' : 'dark'" floating @click='authStore.isAuthenticated? $router.push({ name: HUNT_ROUTE_NAME }) : $router.push({ name: SIGN_IN_ROUTE_NAME })' class='shoot-btn' :disabled='!authStore.isInitialized'>
      <MDBIcon iconStyle="fas" size="2x" icon="camera"></MDBIcon>
    </MDBBtn>
  </div>
</template>
<script lang="ts">
import {defineComponent, inject, onMounted, ref, watch} from 'vue'
import { useMapStore } from '@/store/map'
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBBadge,
  MDBIcon,
  MDBCardText,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardFooter,
  MDBCardImg,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-vue-ui-kit";
import MapFilterContainer from "@/ui/containers/MapFilterContainer.vue";
import ViolationsDetailsContainer from "@/ui/containers/ViolationsDetailsContainer.vue";
import type Map from "ol/Map";
import type View from "ol/View";
import { convertExtentBack } from "@/helpers/functions";
import Feature from "ol/Feature";
import Icon from 'ol/style/Icon.js';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import { SelectEvent } from 'ol/interaction/Select'
import { MODERATION_STATUS } from '@/helpers/enums/moderation-status'
import { useAuthStore } from "@/store/auth";
import { useIfaceStore } from '@/store/iface';
import {HUNT_ROUTE_NAME, SIGN_IN_ROUTE_NAME, URL_OF_FORM_TO_EDIT_CAR_PARK} from "@/helpers/constants/routes";
import type XYZ from "ol/source/XYZ";


export default defineComponent({
  setup() {
    const mapStore = useMapStore();

    const authStore = useAuthStore();
    const ifaceStore = useIfaceStore();

    const mapRef = ref<{ map: Map }>(null);
    const view = ref<View | null>(null);
    const map = ref<Map | null>(null);
    onMounted(() => {
      map.value = mapRef.value?.map;
      view.value = map.value?.getView();
    });


    let featureStyleCache = {};
    let clusterStyleCache = {};

    const overrideCarParkStyleFunction = (feature: Feature, style) => {
      const lastState = feature.get("last_state");
      if (lastState && lastState?.free_spaces == 0) {
        style.setStroke(new Stroke({
          color: "rgba(255,0,0,1)",
          width: 4,
        }));
        style.setFill(new Fill({
          color: "rgba(255,0,0,0.2)",
        }));
      } else if (lastState && lastState?.free_spaces && lastState?.free_spaces > 1) {
        style.setStroke(new Stroke({
          color: "rgb(44,126,0)",
          width: 4,
        }));
        style.setFill(new Fill({
          color: "rgba(44,126,0,0.5)",
        }));
      } else {
        style.setStroke(new Stroke({
          color: "rgb(0,0,255)",
          width: 4,
        }));
        style.setFill(new Fill({
          color: "rgba(0,0,255,0.2)",
        }));
      }
      style.setZIndex(24000);
      return style;
    };

    const overrideClusterStyleFunction = (feature: Feature, style) => {
      const clusteredFeatures = feature.get("features");
      const size = clusteredFeatures.length;
      if(size > 1) {
        if(!clusterStyleCache[size]) {
          const color = size > 20 ? "192,0,0" : size > 8 ? "255,128,0" : "0,128,0";
          const radius = Math.max(10, Math.min(size, 20));
          const dash = (2 * Math.PI * radius) / 6;
          const calculatedDash = [0, dash, dash, dash, dash, dash, dash];
          clusterStyleCache[size] = new Style({
            image: new Circle({
              radius: radius,
              stroke: new Stroke({
                color: "rgba(" + color + ",0.5)",
                width: 15,
                lineDash: calculatedDash,
                lineCap: "butt",
              }),
              fill: new Fill({
                color: "rgba(" + color + ",1)",
              }),
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: "#fff",
              }),
            }),
          });
        }
        style.setImage(clusterStyleCache[size].getImage());
        style.setText(clusterStyleCache[size].getText());
        style.setZIndex(30000);
        return style;
      } else {
        const feature = clusteredFeatures[0];
        if(!featureStyleCache[feature.id_]) {
          const isAuthor = feature.get("is_author");
          const moderationStatus = feature.get("moderation_status");
          featureStyleCache[feature.id_] = new Style({
            image: new Icon({
              src: "/pig-on-map.png",
              scale: 0.065,
            }),
            text: new Text({
              text: feature.get("plate_number"),
              font: "bold 10px sans-serif",
              fill: new Fill({
                color: !isAuthor ? "#000000" : moderationStatus == MODERATION_STATUS.CONFIRMED ? "#18743d" : moderationStatus == MODERATION_STATUS.NOT_MODERATED ? "rgba(11,144,215,0.7)" : "#DC4C64",
              }),
              offsetY: 25,
              stroke: new Stroke({
                color: isAuthor ? "#f2fedf" : "#fff",
                width: 5,
              }),
            }),
          });
        }
        style.setImage(featureStyleCache[feature.id_].getImage());
        style.setText(featureStyleCache[feature.id_].getText());
        style.setZIndex(25000);
        return style;
      }
    };

    // Функция для отрисовки иконок на развернутых кластерах
    const featureStyle = (features: Feature) => {
      const _features = features.get("features");
      if(!_features) return [];
      const styles = []
      _features.forEach((feature) => {
        if(!featureStyleCache[feature.id_]) {
          const isAuthor = feature.get("is_author");
          const moderationStatus = feature.get("moderation_status");
          featureStyleCache[feature.id_] = new Style({
            image: new Icon({
              src: "/pig-on-map.png",
              scale: 0.065,
            }),
            text: new Text({
              text: feature.get("plate_number"),
              font: "bold 10px sans-serif",
              fill: new Fill({
                color: !isAuthor ? "#000000" : moderationStatus == MODERATION_STATUS.CONFIRMED ? "#18743d" : moderationStatus == MODERATION_STATUS.NOT_MODERATED ? "rgba(11,144,215,0.7)" : "#DC4C64",
              }),
              offsetY: 25,
              stroke: new Stroke({
                color: isAuthor ? "#f2fedf" : "#fff",
                width: 5,
              }),
            }),
          });
        }
        styles.push(featureStyleCache[feature.id_]);
      });
      return styles;
    };

    const clusterSelectFilter = (feature) => {
      return feature.get("features") != undefined;
    };

    const carParksFilter = (feature) => {
      return feature.get("features") == undefined && feature.get("type") != undefined && feature.get("address") != undefined;
    };

    const format = inject("ol-format");
    const geoJson = new format.GeoJSON();

    const carParkModalVisible = ref(false);
    const carParkImage = ref("");
    const carParkAddress = ref("");
    const carParkFreeSpaces = ref<null | number>(null);
    const carParkTotalSpaces = ref<null | number>(null);
    const carParkLastUpdate = ref("");
    const carParkMonthlyRent = ref<null | number>(null);
    const carParkPhone = ref<string | null>(null);

    const sourceRef = ref<{ source: XYZ }>(null);
    const tileLayerUrl = ref(mapStore.tileLayerUrl);

    watch(() => mapStore.tileLayerUrl, (newVal) => {
      sourceRef.value?.source?.setUrl(newVal);
      tileLayerUrl.value = newVal;
    });

    return {
      mapStore,
      ifaceStore,
      mapRef,
      view,
      map,
      overrideClusterStyleFunction,
      overrideCarParkStyleFunction,
      clusterSelectFilter,
      carParksFilter,
      geoJson,
      featureStyle,
      authStore,
      carParkModalVisible,
      carParkImage,
      carParkAddress,
      carParkFreeSpaces,
      carParkTotalSpaces,
      carParkLastUpdate,
      carParkMonthlyRent,
      carParkPhone,
      sourceRef,
      tileLayerUrl,
    };
  },
  name: "MapContainer",
  data() {
    return {
      HUNT_ROUTE_NAME,
      SIGN_IN_ROUTE_NAME,
      URL_OF_FORM_TO_EDIT_CAR_PARK,
    };
  },
  components: {
    MDBCardImg,
    MDBCardTitle,
    MDBCardBody,
    MDBCard,
    MDBCardText,
    MDBCardFooter,
    MDBIcon,
    MDBBtn,
    MapFilterContainer,
    MDBModal,
    MDBModalBody,
    MDBBadge,
    ViolationsDetailsContainer,
    MDBListGroup,
    MDBListGroupItem,
  },
  computed: {
    mapZoom(): number {
      return this.mapStore.mapZoom;
    },
    mapCenter(): [number, number] {
      return this.mapStore.mapCenter;
    },
    zoom: {
      get(): number {
        return this.mapZoom;
      },
      set(value: number) {
        this.setMapZoom(value);
      },
    },
    center: {
      get(): [number, number] {
        return this.mapCenter;
      },
      set(value: [number, number]) {
        this.setMapCenter(value);
      },
    },
  },
  methods: {
    showCarParkDetails(feature: Feature) {
      const lastState = feature.get("last_state");
      this.carParkImage = feature.get("photo");
      if(!this.carParkImage) {
        this.carParkImage = "/no-photo.png";
      }
      this.carParkAddress = feature.get("address") ?? "";
      this.carParkFreeSpaces = lastState?.free_spaces ?? null;
      this.carParkTotalSpaces = lastState?.total_spaces ?? null;
      this.carParkLastUpdate = lastState?.created_at ?? "";
      if(this.carParkLastUpdate) {
        this.carParkLastUpdate = new Date(this.carParkLastUpdate).toLocaleString();
      }
      this.carParkMonthlyRent = lastState?.monthly_rent ?? null;
      const publicInfo = feature.get("public_info");
      if(publicInfo?.phone_number) {
        this.carParkPhone = publicInfo?.phone_number;
      } else {
        this.carParkPhone = null;
      }
      this.carParkModalVisible = true;
    },
    clearCarParkState() {
      this.carParkImage = "";
      this.carParkAddress = "";
      this.carParkFreeSpaces = null;
      this.carParkTotalSpaces = null;
      this.carParkLastUpdate = "";
      this.carParkMonthlyRent = null;
    },
    setMapZoom(zoom: number) {
      this.mapStore.setMapZoom(zoom);
    },
    setMapCenter(center: [number, number]) {
      this.mapStore.setMapCenter(center);
    },
    handleMapMoveEnd() {
      const extent = this.view.calculateExtent(this.map.getSize());
      const extent4326 = convertExtentBack(extent);
      this.mapStore.setMapExtent4326(extent4326);
    },
    featureSelected(event: SelectEvent) {
      if (event?.selected?.length && event.selected[0].get("features").length === 1) {
        this.mapStore.loadViolationDetails(event.selected[0].get("features")[0].id_);
      }
    },
    carParkFeatureSelected(event: SelectEvent) {
      if (event.selected?.length === 1) {
        this.showCarParkDetails(event.selected[0]);
      }
    },
  }
});
</script>
<style scoped lang="scss">
.map-wrapper {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ol-map-block {
  flex-grow: 1;
}
.shoot-btn {
  position: fixed;
  z-index: 250;
  width: 55px;
  height: 55px;
  bottom: 10px;
  left: calc(50% - 27.5px);
  right: calc(50% - 27.5px);
}
.top-buttons {
  position: absolute;
  top: 10px;
  z-index: 2500;
  width: 100%;
}
</style>
