const sitemap = {
  header: "SITEMAP | MAIN SECTIONS",
  violationMap: "Violation map",
  projectManifesto: "Project manifesto",
  hunt: "Violation hunt (for authorized users)",
  profile: "Profile (for authorized users)",
  signIn: "Sign-In (for unauthorized users)",
  feedBackForms: "Feedback forms",
  photoDeleteRequest: "Photo deletion request with violation",
  placeOrViolationAddRequest: "Territory or violation addition request",
  generalQuestions: "Feedback form (general questions)",
  violation: "Violations",
  violationList: "All violations in a List",
  backToMainSections: "Back to main sections",
  more: "More",
  ourTelegramChannel: "Our Telegram channel",
};

export default sitemap;
