<template>
  <!--
        Warning about the use of a non-free component.
        Attention! The MDBToast component is provided under the "MDB Vue Essential license" (EULA), which prohibits the
        distribution of source code as part of open-source software. To run the "frontend" app of the OinkOinkCar system,
        you must either purchase the specified license from the vendor (https://mdbootstrap.com/docs/vue/pro/#section-pricing)
        or make changes to this source code to eliminate the use of non-free components.
      -->
  <MDBToast
    v-model="isVisible"
    :position="currentToast?.showOnTop ? 'top-center' : 'bottom-center'"
    :offset="currentToast?.showOnTop ? '100' : '20'"
    heightAnimation
    :delay="delay"
    :toast="currentToast?.type"
    :icon="toastIcon"
    @hidden="appStore.closeToast"
  >
    <template #title>
      <span class='ms-2'>{{ $t("header.brandName") }}</span>
    </template>
    {{ currentToast?.message }}
  </MDBToast>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { SYSTEM_MESSAGE_TYPE } from '@/helpers/enums/system-message-type'
import { useAppStore } from '@/store/app'
import { MDBToast } from 'mdb-vue-ui-kit'
import type { SystemMessage } from '@/store/models/system-message.model'

export default defineComponent({
  name: "ToastContainer",
  setup() {
    const appStore = useAppStore();
    const isVisible = ref(appStore.openedToast !== null);
    const currentToast = ref<SystemMessage | null>(null);
    const delay = ref(5000);
    watch(() => appStore.openedToast, (newValue, oldValue) => {
      currentToast.value = newValue;
      isVisible.value = newValue !== null;
      delay.value = newValue?.toastDelay || 5000;
    });
    return {
      currentToast,
      isVisible,
      appStore,
      delay,
    };
  },
  components: { MDBToast },
  computed: {
    toastIcon() {
      switch (this.currentToast?.type) {
        case SYSTEM_MESSAGE_TYPE.INFO:
          return "fas fa-info-circle";
        case SYSTEM_MESSAGE_TYPE.SUCCESS:
          return "fas fa-check-circle";
        case SYSTEM_MESSAGE_TYPE.WARNING:
          return "fas fa-exclamation-triangle";
        case SYSTEM_MESSAGE_TYPE.ERROR:
          return "fas fa-exclamation-circle";
        case SYSTEM_MESSAGE_TYPE.DEBUG:
          return "fas fa-bug";
        default:
          return "fas fa-comment-alt";
      }
    },
  },
});
</script>
