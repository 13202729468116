<template>
  <div class='error-page-container'>
    <MDBCard class='ms-3 me-3 mt-3'>
      <MDBCardImg
        src="/manifesto-7.png"
        top
        :alt="$t('application.oinkOinkMan')"
      />
      <MDBCardBody class='pt-0 pb-3'>
        <MDBCardTitle>{{$t('application.auth.errorHeader')}}</MDBCardTitle>
        <MDBCardText>
          <p class="note note-danger">
            {{ message }}
          </p>
        </MDBCardText>
      </MDBCardBody>
      <MDBCardFooter class='hstack p-2'>
        <MDBBtn class='me-auto ms-0 ps-3 pe-3' color="secondary" @click='goHome' v-if='$route.name !== HOME_ROUTE_NAME'>
          <MDBIcon iconStyle="fas" icon="home" class="me-2" />
          {{ $t("application.goHome") }}
        </MDBBtn>
        <MDBBtn class='ms-auto me-0 ps-3 pe-3' color="primary" tag='a' :href='URL_OF_FORM_TO_ASK_EVERYTHING' target='_blank'>
          <MDBIcon iconStyle="far" icon="question-circle" class="me-2" />
          {{ $t("application.auth.writeToAdmins") }}
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useAppStore } from '@/store/app'
import { HOME_ROUTE_NAME, URL_OF_FORM_TO_ASK_EVERYTHING } from '@/helpers/constants/routes'
import { APP_STAGE } from '@/helpers/enums/app-stage'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardImg, MDBCardText, MDBCardTitle, MDBIcon } from 'mdb-vue-ui-kit'


export default defineComponent({
  name: "AuthErrorView",
  components: { MDBCardImg, MDBCardTitle, MDBCardText, MDBCard, MDBBtn, MDBCardFooter, MDBIcon, MDBCardBody },
  methods: {
    goHome() {
      return this.$router.push({ name: HOME_ROUTE_NAME });
    },
  },
  data() {
    return {
      URL_OF_FORM_TO_ASK_EVERYTHING,
      HOME_ROUTE_NAME,
    };
  },
  props: {
    errorType: {
      type: String,
      default: ''
    }
  },
  computed: {
    message() {
      if(this.errorType === 'login-error' && (this.stage === APP_STAGE.STAGING || this.stage === APP_STAGE.DEV)) {
        return this.$t('application.auth.signUpClosed')
      }
      if(this.errorType === 'inactive-user') {
        return this.$t('application.auth.yourAccountIsBlocked')
      }
      return this.$t('application.auth.signInError')
    },
  },
  setup() {
    const appStore = useAppStore();
    const stage = ref(appStore.siteState?.stage);
    watch(() => appStore.siteState, (newVal) => {
      stage.value = newVal?.stage;
    });
    return {
      stage
    }
  }
});
</script>
<style scoped lang="scss">
.error-page-container {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin: 0 auto;
}
</style>
