const content = {
  map: "Карта",
  manifesto: "Манифест",
  hunting: "Охота",
  stats: "Статистика",
  profile: "Профиль",
  banBadge: "Бан",
  moderatorBadge: "М",
  adminBadge: "А",
  signIn: "Вход",
  signInLead: "После авторизации вы получите возможность отправлять фотографии нарушителей и, если нарушение подтвердится, то нарушитель понесет наказание в соответствии с законодательством об административных правонарушениях, а информация о нарушении будет опубликована на карте и в каталоге ХрюХрюКара.",
  signInPrompt: "Войти в ХрюХрюКар:",

  // Violation details
  more: "Подробнее",
  close: "Закрыть",
  violationDetails: "Детали нарушения",
  violationPhoto: "Фото нарушения",
  violation: "Нарушение",
  violationOn: "от",
  status: "Статус",
  deleteViolation: "Запросить удаление",
  deleteViolationTooltip: "Для удаления фотографии с нарушением заполните форму. Вам необходимо будет подтвердить факт оплаты штрафа, либо факт вынесения решения о наказании в виде предупреждения.",
  oopsNotFound: "Упс, нарушение не найдено.",
  oopsNotFoundDescription: "Либо оно было удалено по требованию собственника (после оплаты административного штрафа, конечно), либо вы ввели неверный идентификатор.",
  copyLinkToViolation: "Скопировать ссылку на нарушение",
  linkCopied: "Ссылка скопирована",
  toParking: "До стоянки",
  m: "м",

  // Car parking details
  address: "адрес стоянки",
  space: "мест",
  spaces: "наличие мест",
  empty: "свободно",
  outOf: "из",
  monthlyRent: "стоимость аренды",
  rubPerMonth: "руб/мес",
  carParkTitle: "Информация о стоянке",
  updated: "обновлено: ",
  reportInaccuracy: "Сообщить о неточности",
  phone: "Телефон",

  // reject reasons
  moderationStatusBadge_not_moderated: 'На модерации',
  moderationStatusBadge_confirmed: 'Подтверждено',
  moderationStatusBadge_blurred: 'Отклонено',
  moderationStatusBadge_faces: 'Отклонено',
  moderationStatusBadge_no_car: 'Отклонено',
  moderationStatusBadge_car_not_fit: 'Отклонено',
  moderationStatusBadge_car_plate_not_readable: 'Отклонено',
  moderationStatusBadge_international_car_plate: 'Отклонено',
  moderationStatusBadge_violation_type_not_confirmed: 'Отклонено',
  moderationStatusBadge_violation_already_exists: 'Отклонено',
  moderationStatusBadge_geolocation_error: 'Отклонено',
  moderationStatusBadge_flood: 'Отклонено',
  moderationStatusBadge_adult: 'Отклонено',
  moderationStatusBadge_other: 'Отклонено',
  moderationStatusBadge_timeout: 'Отклонено',

  moderationStatusTooltip_not_moderated: 'Нарушение все еще находится на модерации. Как правило, этот процесс занимает не более суток.',
  moderationStatusTooltip_confirmed: 'Нарушение подтверждено, заявление отправлено в уполномоченный орган.',
  moderationStatusTooltip_blurred: 'Фото размытое. Постарайтесь не допускать размытости фотографий.',
  moderationStatusTooltip_faces: 'На фото есть лица, что противоречит правилам.',
  moderationStatusTooltip_no_car: 'На фото нет автомобиля. Несколько таких нарушений могут привести к блокировке аккаунта.',
  moderationStatusTooltip_car_not_fit: 'На фото не весь автомобиль. Постарайтесь фотографировать автомобиль полностью.',
  moderationStatusTooltip_car_plate_not_readable: 'На фото не читается номер автомобиля. Постарайтесь фотографировать автомобиль с близкого расстояния.',
  moderationStatusTooltip_international_car_plate: 'На фото автомобиль с иностранным номером. Принимаются фотографии только с российскими/советскими номерами.',
  moderationStatusTooltip_violation_type_not_confirmed: 'На фото не подтверждается нарушение, либо его невозможно однозначно подтвердить одним фото. В следующий раз постарайтесь чтобы на фотографии были видны детали, однозначно подтверждающие факт нарушения (земля, растительность, бордюр, дорожные знаки).',
  moderationStatusTooltip_violation_already_exists: 'Этот автомобиль уже кто-то сегодня присылал, у вас есть конкурент на этой территории=) Выберите другое место или приходите сюда через сутки.',
  moderationStatusTooltip_geolocation_error: 'Есть проблемы с геолокацией, не удалось однозначно определить местоположение. В следующий раз убедитесь пожалуйста что геолокация включена и на фото видны объекты, позволяющие однозначно определить местоположение (например здания).',
  moderationStatusTooltip_flood: 'Флуд запрещен. Несколько таких нарушений могут привести к блокировке аккаунта.',
  moderationStatusTooltip_adult: 'На фотографии присутствует запрещенный контент. Несколько таких нарушений могут привести к блокировке аккаунта.',
  moderationStatusTooltip_other: 'Причина отклонения не указана.',
  moderationStatusTooltip_timeout: 'Время на вашем устройстве не синхронизировано',
};

export default content;
