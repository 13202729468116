import api from './api'
import type { AxiosResponse } from 'axios'
import { TypeOfViolation } from '@/api/models/type-of-violation.model'
import {
  APPLICANTS_ENDPOINT,
  APPLICATIONS_ENDPOINT,
  APPLICATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT,
  CAR_PLATES_ENDPOINT,
  CAR_PLATES_MODERATION_RETRIEVE_DELETE_ENDPOINT,
  CAR_PLATES_RETRIEVE_DELETE_ENDPOINT,
  GEO_PHOTO_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT,
  GEO_PHOTO_RETRIEVE_DELETE_ENDPOINT,
  GEO_PHOTOS_ENDPOINT,
  TYPE_OF_VIOLATIONS_ENDPOINT,
  VIOLATION_STATS_ENDPOINT,
  VIOLATIONS_MODERATION_ENDPOINT,
  VIOLATIONS_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT
} from '@/helpers/constants/api'
import { ViolationStats } from '@/api/models/stats.model'
import { ViolationForModeration } from '@/api/models/violation.model'
import { GeoPhoto, GeoPhotoModerationPatch } from '@/api/models/geophoto.model'
import { CarPlate, CarPlateForModeration } from '@/api/models/car-plate.model'
import { Application } from '@/api/models/application.model'

export const objectsModule = {
  // Violation types
  async getAllTypeOfViolations(): Promise<AxiosResponse<TypeOfViolation[]>> {
    return await api.get(TYPE_OF_VIOLATIONS_ENDPOINT, {headers: {"Content-Type": "application/json",},});
  },
  async getTypeOfViolationsInPoint(latitude: number, longitude: number): Promise<AxiosResponse<TypeOfViolation[]>> {
    return await api.get(`${TYPE_OF_VIOLATIONS_ENDPOINT}?point=${longitude},${latitude}`, {headers: {"Content-Type": "application/json",},});
  },
  // Violations and stats
  async getViolationStats(): Promise<AxiosResponse<ViolationStats>> {
    return await api.get(VIOLATION_STATS_ENDPOINT, {headers: {"Content-Type": "application/json",},});
  },
  // Violations moderation
  async getViolationModerationQueue(latitude: number, longitude: number, page: number, orderByDistance: boolean): Promise<AxiosResponse<ViolationForModeration[]>> {
    return await api.get(orderByDistance ? `${VIOLATIONS_MODERATION_ENDPOINT}?point=${longitude},${latitude}&order=-desc&page=${page}` : `${VIOLATIONS_MODERATION_ENDPOINT}?page=${page}`, {headers: {"Content-Type": "application/json",},});
  },
  async retrieveViolationForModeration(uuid: string): Promise<AxiosResponse<ViolationForModeration>> {
    return await api.get(VIOLATIONS_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT(uuid), {headers: {"Content-Type": "application/json",},});
  },
  async patchViolationForModeration(uuid: string, violation: ViolationForModeration): Promise<AxiosResponse<ViolationForModeration>> {
    return await api.patch(VIOLATIONS_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT(uuid), violation, {headers: {"Content-Type": "application/json",},});
  },

  // GeoPhoto
  async postGeoPhoto(photo: GeoPhoto): Promise<AxiosResponse<GeoPhoto>> {
    return await api.post(GEO_PHOTOS_ENDPOINT, photo, { headers: { "Content-Type": "application/json", }, });
  },
  async retrieveGeoPhoto(uuid: string): Promise<AxiosResponse<GeoPhoto>> {
    return await api.get(GEO_PHOTO_RETRIEVE_DELETE_ENDPOINT(uuid), {headers: {"Content-Type": "application/json",},});
  },
  async deleteGeoPhoto(uuid: string): Promise<AxiosResponse<GeoPhoto>> {
    return await api.delete(GEO_PHOTO_RETRIEVE_DELETE_ENDPOINT(uuid), {headers: {"Content-Type": "application/json",},});
  },
  // GeoPhoto moderation
  async patchGeoPhotoForModeration(uuid: string, photo: GeoPhotoModerationPatch): Promise<AxiosResponse<GeoPhotoModerationPatch>> {
    return await api.patch(GEO_PHOTO_MODERATION_RETRIEVE_PATCH_DELETE_ENDPOINT(uuid), photo, {headers: {"Content-Type": "application/json",},});
  },

  // CarPlates
  async getCarPlatesOnPhoto(photo_uid: string): Promise<AxiosResponse<CarPlate[]>> {
    return await api.get(`${CAR_PLATES_ENDPOINT}?photo=${photo_uid}`, {headers: {"Content-Type": "application/json",},});
  },
  async deleteCarPlate(uuid: string): Promise<AxiosResponse> {
    return await api.delete(CAR_PLATES_RETRIEVE_DELETE_ENDPOINT(uuid), {headers: {"Content-Type": "application/json",},});
  },
  async patchCarPlateDuringModeration(uuid: string, carPlate: CarPlateForModeration): Promise<AxiosResponse<CarPlate>> {
    return await api.patch(CAR_PLATES_MODERATION_RETRIEVE_DELETE_ENDPOINT(uuid), carPlate, {headers: {"Content-Type": "application/json",},});
  },

  // Applicants and applications
  async getApplicants(): Promise<AxiosResponse> {
    return await api.get(APPLICANTS_ENDPOINT, {headers: {"Content-Type": "application/json",},});
  },
  async createApplication(application: Application): Promise<AxiosResponse<Application>> {
    return await api.post(APPLICATIONS_ENDPOINT, application, {headers: {"Content-Type": "application/json",},});
  },
  async patchApplication(uuid: string, application: Application): Promise<AxiosResponse<Application>> {
    return await api.patch(APPLICATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT(uuid), application, {headers: {"Content-Type": "application/json",},});
  },
  async retrieveApplication(uuid: string): Promise<AxiosResponse<Application>> {
    return await api.get(APPLICATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT(uuid), {headers: {"Content-Type": "application/json",},});
  },
  async getApplicationByViolationId(violationId: string): Promise<AxiosResponse<Application[]>> {
    return await api.get(`${APPLICATIONS_ENDPOINT}?violation=${violationId}`, {headers: {"Content-Type": "application/json",},});
  }

}