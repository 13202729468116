const filter = {
  violationFilter: "Фильтр нарушений",
  startDate: "Начальная дата",
  endDate: "Конечная дата",
  violationTypes: "Типы нарушений",
  selectViolationTypes: "Выберите типы нарушений",
  reset: "Сбросить",
  close: "Закрыть",
  objectsOnTheMap: "На карте: {count} из {total}",
  selectAllLabel: "Выбрать все",
  searchPlaceholder: "Поиск...",
  optionsSelectedLabel: "шт. выбрано",
  noResultsText: "Нет результатов",
  searchByCarPlate: "Поиск по номеру",
  onlyMyCatch: "Только те, где я автор",
};

export default filter;
