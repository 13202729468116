// Route names and paths for the application.
export const HOME_PATH = "/";
export const HOME_ROUTE_NAME = "map";
export const HUNTING_PATH = "/hunting";
export const HUNTING_ROUTE_NAME = "hunting";
export const MANIFESTO_PATH = "/manifesto";
export const MANIFESTO_ROUTE_NAME = "manifesto";
export const SIGN_IN_PATH = "/sign-in";
export const SIGN_IN_ROUTE_NAME = "sign-in";
export const HUNT_PATH = "/hunt";
export const HUNT_ROUTE_NAME = "hunt";
export const MODERATOR_PATH = "/moderator";
export const MODERATOR_ROUTE_NAME = "moderator";
export const PRIVACY_PATH = "/privacy";
export const PRIVACY_ROUTE_NAME = "privacy";
export const RULES_PATH = "/rules";
export const RULES_ROUTE_NAME = "rules";
export const ERROR_AUTH_PATH = "/error-auth/:errorType";
export const ERROR_AUTH_ROUTE_NAME = "error-auth";

// OAuth2 routes
export const OAUTH2_GOOGLE_LOGIN_PATH = `/api/auth/login/?provider=google-oauth2`;
export const OAUTH2_VK_LOGIN_PATH = `/api/auth/login/?provider=vk-oauth2`;

// Links to external resources.
// TODO: move to env variables
export const URL_OF_FORM_TO_DELETE_VIOLATION = "https://forms.yandex.ru/cloud/6582856090fa7b1ce19f80d9/";
export const URL_OF_FORM_TO_ADD_PLACE = "https://forms.yandex.ru/cloud/6596572cc417f36bf369aad8/";
export const URL_OF_FORM_TO_ASK_EVERYTHING = "https://forms.yandex.ru/cloud/6599a5d52530c2dab34837be/";
export const URL_OF_FORM_TO_EDIT_CAR_PARK = "https://forms.yandex.ru/cloud/666e92102530c23909de54c5/";
export const URL_OF_REPOSITORY = "https://gitlab.com/theansweris42/oinkoinkcar";
export const URL_OF_TELEGRAM = "https://t.me/oinkoinkcar";
export const URL_OF_VK = "https://vk.com/xxkap_blk";
