const filter = {
  violationFilter: "Violation filter",
  startDate: "Start date",
  endDate: "End date",
  violationTypes: "Violation types",
  selectViolationTypes: "Select violation types",
  resetFilter: "Reset filter",
  close: "Close",
  reset: "Reset",
  objectsOnTheMap: "On the map: {count} of {total}",
  selectAllLabel: "Select all",
  searchPlaceholder: "Search...",
  optionsSelectedLabel: "options selected",
  noResultsText: "No results",
  searchByCarPlate: "Search by car plate",
  onlyMyCatch: "Only those where I am the author",
};

export default filter;
