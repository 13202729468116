// src/helpers/mappers/violation.mapper.ts
import { ViolationForModeration as StoreViolationForModeration } from '@/store/models/violation.model';
import { ViolationForModeration as ApiViolationForModeration } from '@/api/models/violation.model';
import { ViolationForMap as StoreViolationForMap } from '@/store/models/violation.model';
import { ViolationForMap as ApiViolationForMap } from '@/api/models/violation.model';
import {
  mapApiTypeOfViolationForMapToStoreTypeOfViolationForMap,
  mapApiTypeOfViolationToStoreTypeOfViolation
} from '@/helpers/mappers/type-of-violation.mapper'
import { mapApiCarPlateForModerationToStoreCarPlateForModeration } from '@/helpers/mappers/car-plate.mapper'
import { mapApiCarPlateForMapToStoreCarPlateForMap } from '@/helpers/mappers/car-plate.mapper'
import { TypeOfViolation } from '@/api/models/type-of-violation.model'

export function mapApiViolationForModerationToStoreViolationForModeration(violation: ApiViolationForModeration): StoreViolationForModeration {
  return {
    id: violation.id,
    modifiedAt: violation.modified_at? new Date(violation.modified_at) : null,
    createdAt: new Date(violation.created_at),
    typeConfirmed: violation.type_confirmed,
    isPublished: violation.is_published,
    violationType: mapApiTypeOfViolationToStoreTypeOfViolation(violation.violation_type as TypeOfViolation),
    carPlate: mapApiCarPlateForModerationToStoreCarPlateForModeration(violation.car_plate),
    isViolatorFined: violation.is_violator_fined,
    moderationStatus: violation.moderation_status,
  };
}

export function mapStoreViolationForModerationToApiViolationForModeration(violation: StoreViolationForModeration): ApiViolationForModeration {
  return {
    type_confirmed: violation.typeConfirmed != null? violation.typeConfirmed : undefined,
    moderation_status: violation.moderationStatus? violation.moderationStatus : undefined,
    violation_type: violation.violationType,
  };
}

export function mapApiViolationForMapToStoreViolationForMap(violation: ApiViolationForMap): StoreViolationForMap {
  return {
    id: violation.id,
    modifiedAt: violation.modified_at? new Date(violation.modified_at) : null,
    createdAt: new Date(violation.created_at),
    isPublished: violation.is_published,
    isViolatorFined: violation.is_violator_fined,
    moderationStatus: violation.moderation_status,
    isAuthor: violation.is_author,
    carPlate: mapApiCarPlateForMapToStoreCarPlateForMap(violation.car_plate),
    violationType: mapApiTypeOfViolationForMapToStoreTypeOfViolationForMap(violation.violation_type),
    nearestParkingDistance: violation.nearest_parking_distance,
  };
}
