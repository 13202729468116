// src/api/map.ts
import api from "./api";
import type { AxiosResponse } from "axios";
import { VIOLATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT } from '@/helpers/constants/api'
import { ViolationForMap } from '@/api/models/violation.model'


export const mapModule = {
  // Violations
  async getViolation(uuid: string): Promise<AxiosResponse<ViolationForMap>> {
    return await api.get(VIOLATIONS_RETRIEVE_PATCH_DELETE_ENDPOINT(uuid), {headers: {"Content-Type": "application/json",},});
  }
}
