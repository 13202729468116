const auth = {
  // FIXME: hardcoded email
  errorDuringTokenExchange: "Token exchange error. If the error persists, please contact admin%40xxkap.app or try clearing your browser cache and logging in again.",
  errorLoadingUserData: "Error loading user data. Try to refresh the page (press Ctrl + F5).",
  vkButtonText: "Sign in with VK",
  googleButtonText: "Sign in with Google",
  userLoggedOut: "You have logged out",
  userLoaded: "Hello %{name}! You have successfully logged in to the system.",
};

export default auth;
