// src/store/app.ts
// Module for application-wide state (excluding authentication, authorization and interface).
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from "uuid";
import type { SystemMessage } from '@/store/models/system-message.model';
import type { SiteState } from '@/store/models/site-state.model'
import { OPERATION_MODE } from "@/helpers/enums/operation-mode";
import { ANONYMOUS_USER_FRONTEND_SETTINGS_ID, SHOW_DEBUG_INFORMATION_BY_DEFAULT, MAX_ACCURACY_IN_METERS } from '@/helpers/constants/application'
import { APP_STAGE } from "@/helpers/enums/app-stage";
import type { FrontendUserSettings } from '@/store/models/frontend-user-settings.model';
import { mapApiFrontendUserSettingsToStoreFrontendUserSettings } from '@/helpers/mappers/frontend-user-settings.mapper';
import { mapApiSiteStateToStoreSiteState } from '@/helpers/mappers/site-state.mapper';
import { SYSTEM_MESSAGE_TYPE } from '@/helpers/enums/system-message-type';
import { applicationModule } from '@/api';
import { useCookies } from 'vue3-cookies';
import { useAuthStore } from '@/store/auth'
import { useMapStore } from "@/store/map";
// @ts-ignore
import { registerSW } from 'virtual:pwa-register'

// TODO: Handle all errors when using api.

const { cookies } = useCookies();

const basicFrontendUserSettings: FrontendUserSettings = {
  id: ANONYMOUS_USER_FRONTEND_SETTINGS_ID,
  modifiedAt: null,
  createdAt: new Date(),
  showInfoMessages: true,
  showWarningMessages: true,
  showErrorMessages: true,
  showDebugMessages: SHOW_DEBUG_INFORMATION_BY_DEFAULT,
};

const basicSiteState: SiteState = {
  publicMode: OPERATION_MODE.MODE_NORMAL,
  messageForPublic: null,
  moderationMode: OPERATION_MODE.MODE_NORMAL,
  messageForModerators: null,
  stage: APP_STAGE.PROD,
  frontendVer: '',
  frontendVerBuild: '',
  tileLayerUrl: '',
}

export const useAppStore = defineStore('app', {
  state: () => ({
    systemMessages: {} as Record<string, SystemMessage>,
    consoleSystemMessages: [] as SystemMessage[],
    toasts: [] as SystemMessage[],
    openedToast: null as SystemMessage | null,
    frontendUserSettings: basicFrontendUserSettings,
    rulesHaveBeenRead: cookies.get('rulesHaveBeenRead') === 'true',
    currentLocation: null as GeolocationPosition | null,
    geolocationPositionError: null as GeolocationPositionError | null,
    locationWatchId: null as number | null,
    locationAccuracyIsBad: null as boolean | null,
    siteState: basicSiteState as SiteState,
    backendAvailable: false,
    siteStateIntervalId: null as number | null,
  }),

  actions: {

    async setBackendAvailable(value: boolean) {
      this.backendAvailable = value;
    },

    // TODO: Move to iface store
    async closeToast() {
      const message = this.systemMessages[this.openedToast?.id];
      message.isClosed = true;
      message.closedAt = new Date();
      this.systemMessages[message.id] = message;
      this.toasts = this.toasts.filter((m) => m.id !== this.openedToast?.id);
      this.openedToast = null;
      if(this.toasts.length > 0) {
        this.openedToast = this.toasts[0];
      }
    },

    async setFrontendUserSettings(settings: FrontendUserSettings) {
      this.frontendUserSettings = settings;
    },

    async addSystemMessage(message: SystemMessage) {
      this.systemMessages[message.id] = message;
      this.consoleSystemMessages.push(message)
      if (message.isToast) {
        this.toasts.push(message);
        if (this.openedToast == null) {
          this.openedToast = message;
        }
      }
    },

    async addInfoMessage(message: string, isToast: boolean = false, toastDelay: number = 5000, showOnTop: boolean = false) {
      console.log(`INFO: ${message}`)
      this.addSystemMessage({
        id: uuidv4(),
        type: SYSTEM_MESSAGE_TYPE.INFO,
        message: message,
        createdAt: new Date(),
        isClosed: false,
        closedAt: null,
        isToast: isToast,
        showOnTop: showOnTop,
        toastDelay: toastDelay,
      });
    },

    async addSuccessMessage(message: string, isToast: boolean = false, toastDelay: number = 5000, showOnTop: boolean = false) {
      console.log(`SUCCESS: ${message}`)
      this.addSystemMessage({
        id: uuidv4(),
        type: SYSTEM_MESSAGE_TYPE.SUCCESS,
        message: message,
        createdAt: new Date(),
        isClosed: false,
        closedAt: null,
        isToast: isToast,
        showOnTop: showOnTop,
        toastDelay: toastDelay,
      });
    },

    async addWarningMessage(message: string, isToast: boolean = false, toastDelay: number = 5000, showOnTop: boolean = false) {
      console.warn(`WARNING: ${message}`)
      this.addSystemMessage({
        id: uuidv4(),
        type: SYSTEM_MESSAGE_TYPE.WARNING,
        message: message,
        createdAt: new Date(),
        isClosed: false,
        closedAt: null,
        isToast: isToast,
        showOnTop: showOnTop,
        toastDelay: toastDelay,
      });
    },

    async addErrorMessage(message: string, isToast: boolean = false, toastDelay: number = 5000, showOnTop: boolean = false) {
      console.error(`ERROR: ${message}`)
      this.addSystemMessage({
        id: uuidv4(),
        type: SYSTEM_MESSAGE_TYPE.ERROR,
        message: message,
        createdAt: new Date(),
        isClosed: false,
        closedAt: null,
        isToast: isToast,
        showOnTop: showOnTop,
        toastDelay: toastDelay,
      });
    },

    async addDebugMessage(message: string) {
      console.log(`DEBUG: ${message}`)
      if(this.frontendUserSettings.showDebugMessages || this.siteState.stage !== APP_STAGE.PROD) {
        this.addSystemMessage({
          id: uuidv4(),
          type: SYSTEM_MESSAGE_TYPE.DEBUG,
          message: message,
          createdAt: new Date(),
          isClosed: false,
          closedAt: null,
          isToast: false,
          toastDelay: 0,
        });
      }
    },

    async loadFrontendUserSettings() {
      await this.waitForBackendAvailable();
      const authStore = useAuthStore();
      if(await authStore.notAuthenticated()) {
        this.setFrontendUserSettings(basicFrontendUserSettings);
        return;
      }

      try {
        const response = await applicationModule.getFrontendUserSettings();
        const frontendUserSettings = mapApiFrontendUserSettingsToStoreFrontendUserSettings(response.data);
        await this.setFrontendUserSettings(frontendUserSettings);
      } catch (error) {
        if(error.response?.status >= 500 || error.response?.status == 408) {
          this.addErrorMessage(`Ошибка загрузки настроек пользователя, повторная попытка через 3 секунды: ${error}`);
          setTimeout(() => {
            this.loadFrontendUserSettings();
          }, 3000);
        } else {
          this.addErrorMessage(`Ошибка загрузки настроек пользователя: ${error}`);
        }
      }
    },

    async resetFrontendUserSettings() {
      await this.setFrontendUserSettings(basicFrontendUserSettings);
    },

    async setRulesHaveBeenRead(value: boolean) {
      this.rulesHaveBeenRead = value;
      cookies.set('rulesHaveBeenRead', String(value));
    },

    async startLocationTracking() {
      if (navigator.geolocation) {
        if(this.locationWatchId != null) {
          navigator.geolocation.clearWatch(this.locationWatchId);
        }
        this.locationWatchId = navigator.geolocation.watchPosition((position) => {
          if(position.coords?.accuracy > MAX_ACCURACY_IN_METERS) {
            console.warn(`Точность местоположения: ${position.coords.accuracy} метров`);
          }
          this.currentLocation = position;
          this.geolocationPositionError = null;
          this.addDebugMessage(`Местоположение обновлено`);
        },
          (error) => {
            // TODO: Handle errors
            switch (error.code) {
              case error.PERMISSION_DENIED:
                this.addErrorMessage(`Без разрешения на получение геолокации приложение не сможет работать корректно`, true, 5000, true);
                break;
              case error.POSITION_UNAVAILABLE:
                this.addErrorMessage(`Информация о местоположении недоступна. Проверьте чтобы была включена геолокация и передатчик WiFi`, true, 5000, true);
                break;
              case error.TIMEOUT:
                this.addDebugMessage(`Запрос на получение местоположения пользователя превысил время ожидания: ${error.message}`);
                break;
              default:
                this.addDebugMessage(`Произошла неизвестная ошибка при получении местоположения пользователя: ${error.message}`);
                break;
            }
            this.geolocationPositionError = error;
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 10000,
          });
        this.addDebugMessage('Геолокация запущена.');
      } else {
        this.addErrorMessage('Геолокация не поддерживается этим браузером.', true, 5000, true);
      }
    },

    async getCurrentLocation(onlyActual: boolean = true) {
      if (!onlyActual && this.currentLocation != null) {
        return this.currentLocation;
      }
      return new Promise<GeolocationPosition | null>((resolve) => {
        if (this.currentLocation == null || this.currentLocation.coords.accuracy > MAX_ACCURACY_IN_METERS || this.currentLocation.timestamp < new Date().getTime() - 6000) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.currentLocation = position;
                this.geolocationPositionError = null;
                this.addDebugMessage(`Местоположение получено`);
                resolve(position);
              },
              (error) => {
                // TODO: Handle errors
                switch (error.code) {
                case error.PERMISSION_DENIED:
                  this.addErrorMessage(`Без разрешения на получение геолокации приложение не сможет работать корректно`, true, 5000, true);
                  break;
                case error.POSITION_UNAVAILABLE:
                  this.addErrorMessage(`Информация о местоположении недоступна. Проверьте чтобы была включена геолокация и передатчик WiFi`, true, 5000, true);
                  break;
                case error.TIMEOUT:
                  this.addDebugMessage(`Запрос на получение местоположения пользователя превысил время ожидания: ${error.message}`);
                  break;
                default:
                  this.addDebugMessage(`Произошла неизвестная ошибка при получении местоположения пользователя: ${error.message}`);
                  break;
                }
                this.geolocationPositionError = error;
                resolve(null);
              },
              {
                enableHighAccuracy: !!this.currentLocation,
                timeout: 7500,
                maximumAge: 10000,
              }
            );
          } else {
            this.addErrorMessage('Геолокация не поддерживается этим браузером.', true, 5000, true);
            resolve(null);
          }
        } else {
          resolve(this.currentLocation);
        }
      });
    },

    async updateSiteState() {
      try {
        const response = await applicationModule.getSiteState();
        this.siteState = mapApiSiteStateToStoreSiteState(response.data);
        const mapStore = useMapStore();
        if(this.siteState.tileLayerUrl && mapStore.tileLayerUrl != this.siteState.tileLayerUrl) {
          await mapStore.setTileLayerUrl(this.siteState.tileLayerUrl);
        }
        const currentVersion = cookies.get('oink-front-version');
        if (currentVersion !== this.siteState.frontendVer && this.siteState.frontendVer !== this.siteState.frontendVerBuild) {
          cookies.set('oink-front-version', this.siteState.frontendVer);
          if(currentVersion) {
            this.addInfoMessage('Вышла новая версия приложения, страница будет перезагружена через 5 секунд для обновления. Если обновление не произвошло, пожалуйста очистите кэш браузера.', true, 5000, true);
            navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
            const reloadCallback = registerSW({
              onRegistered(r) {
                r && r.update()
              },
              immediate: true
            })
            setTimeout(() => {
              reloadCallback(true).then(() => window.location.reload())
            }, 3000);
          }
        }
        if (!this.backendAvailable) {
          this.setBackendAvailable(true);
        }
      } catch (error) {
        this.addErrorMessage(`Ошибка загрузки состояния сайта: ${error}`);
        if (this.backendAvailable) {
          this.setBackendAvailable(false);
        }
      } finally {
        setTimeout(() => {
          this.updateSiteState();
        }, 10000);
      }
    },

    async waitForSiteLoaded() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          //@ts-ignore
          if (site_loaded_global) { // global variable from index.html
            clearInterval(intervalId);
            this.setBackendAvailable(true);
            resolve(true);
          }
        }, 100);
      });
    },

    async waitForBackendAvailable() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (this.backendAvailable) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 250);
      });
    },

    async stopLocationTracking() {
      if (navigator.geolocation && this.locationWatchId != null) {
        navigator.geolocation.clearWatch(this.locationWatchId);
      }
      this.currentLocation = null;
      this.locationWatchId = null;
      this.geolocationPositionError = null;
      this.addDebugMessage('Геолокация остановлена.');
    }
  },
});
