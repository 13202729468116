<template>
  <section class="mb-10 stats-container">
    <h5 class="mb-4">{{ $t('hunting.yourSuccessForTheMonth') }}</h5>
    <MDBRow>
      <MDBCol md="4" class="mb-4 mb-md-0">
        <HuntingStatsCardComponent
          :icon="'camera'"
          :statsHeaderText="$t('hunting.violationsFixed')"
          :statsLoaded="huntingStore.statsLoaded"
          :statsValue="huntingStore.violationStats.totalViolationsLast30Days"
          :percentageChange="huntingStore.violationStats.percentageTotalViolationsChange"
          :isPositive="true" />
      </MDBCol>
      <MDBCol md="4" class="mb-4 mb-md-0">
        <HuntingStatsCardComponent
          :icon="'gavel'"
          :statsHeaderText="$t('hunting.violationsConfirmed')"
          :statsLoaded="huntingStore.statsLoaded"
          :statsValue="huntingStore.violationStats.totalTypeConfirmedLast30Days"
          :percentageChange="huntingStore.violationStats.percentageTypeConfirmedChange"
          :isPositive="true" />
      </MDBCol>
      <MDBCol md="4">
        <HuntingStatsCardComponent
          :icon="'shield-alt'"
          :statsHeaderText="$t('hunting.notPassedModeration')"
          :statsLoaded="huntingStore.statsLoaded"
          :statsValue="huntingStore.violationStats.totalNotConfirmedLast30Days"
          :percentageChange="huntingStore.violationStats.percentageNotConfirmedChange"
          :isPositive="false" />
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-4 ms-1 me-1">
      <MDBBtn color="danger" @click='startHunting'>
        <MDBIcon iconStyle="fas" icon="crosshairs" class="me-2" size="lg"></MDBIcon>
        {{ $t('hunting.goHunt') }}
      </MDBBtn>
    </MDBRow>
  </section>
</template>
<script  lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdb-vue-ui-kit'
import { useAppStore } from '@/store/app'
import { useHuntingStore } from '@/store/hunting'
import { HUNT_ROUTE_NAME } from '@/helpers/constants/routes'
import HuntingStatsCardComponent from '@/ui/components/HuntingStatsCardComponent.vue'

export default  defineComponent({
  name: "HuntingStatsContainer",
  components: { MDBRow, MDBIcon, MDBBtn, MDBCol, HuntingStatsCardComponent },
  methods: {
    startHunting() {
      this.$router.push({ name: HUNT_ROUTE_NAME });
    },
  },
  setup() {
    const appStore = useAppStore();
    const huntingStore = useHuntingStore();

    onBeforeMount(() => {
      huntingStore.updateViolationStats();
    });

    return {
      appStore,
      huntingStore,
    }
  }
});
</script>
<style scoped lang="scss">
.stats-container {
  width: 100% !important;
  max-width: 1200px !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (min-width: 1200px) {
  .stats-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
