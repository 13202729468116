const home = {
  header: "Домашняя страница",
  addCity: "Добавить город",
  askQuestion: "Задать вопрос",
  howItWorks: "Как это работает",
  tgChannel: "Канал в Telegram",
  vkGroup: "Группа в VK",
  letsGo: "Поехали",
};

export default home;
