const header = {
  brandName: "ХрюХрюКар",
  brandSlogan: "Нормальные ребята говорят \"Бла-Бла\" и не ставят машину на зеленой зоне!",
  feedback: "Обратная связь",
  about: "О проекте",
  myProfile: "Мой профиль",
  settings: "Настройки",
  logout: "Выйти",
};

export default header;
