const home = {
  header: "Homepage",
  addCity: "Add city",
  askQuestion: "Ask question",
  howItWorks: "How it works",
  tgChannel: "Telegram channel",
  vkGroup: "VK group",
  letsGo: "Let's go",
};

export default home;
