import type { Applicant as ApiApplicant } from "@/api/models/applicant.model";
import type { Applicant as StoreApplicant } from "@/store/models/applicant.model";

export function mapApiApplicantToStoreApplicant(apiApplicant: ApiApplicant): StoreApplicant {
  return {
    id: apiApplicant.id,
    createdAt: new Date(apiApplicant.created_at),
    modifiedAt: apiApplicant.modified_at ? new Date(apiApplicant.modified_at) : undefined,
    firstName: apiApplicant.first_name,
    patronymic: apiApplicant.patronymic,
    lastName: apiApplicant.last_name,
    email: apiApplicant.email,
    phoneNumber: apiApplicant.phone_number,
  };
}
